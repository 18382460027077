import { Link } from "./types";

/**
 * Class that vends static utility functions.
 */
export class Utils {
  /**
   *Takes an `Link` array and maps them to a list of `<a>` elements with the specified styling.
   * @param links the `Link` array
   * @param className the styling to apply
   * @returns the mapped elements.
   */
  static getListElementsFromLinks = (links: Link[], className: string): JSX.Element[] => {
    return links.map((props) => (
      <li className={className} key={props.url}>
        <a href={props.url} aria-label={"ariaLabel" in props ? props.ariaLabel : undefined}>
          {"label" in props ? props.label : props.icon}
        </a>
      </li>
    ));
  };

  /**
   * Capitalizes the first character in a string.
   * @param str the original string.
   * @returns the passed in string with the first letter capitalized.
   */
  static capitalize = (str: string): string => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  /**
   * Round to the nearest "n" (ex: `roundToNearestN(1921, 100)` = `1900`).
   * @param value the value to round
   * @param n the value to round against
   * @returns the rounded value.
   */
  static roundToNearestN = (value: number, n: number): number => {
    return Math.round(value / n) * n;
  };
}
