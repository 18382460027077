import React from "react";
import PearLogo from "../images/logos/pear.png";
import FiveHundredLogo from "../images/logos/500.png";
import StartXLogo from "../images/logos/startX.png";
import E2VCLogo from "../images/logos/e2vcLogo.png";

/**
 * Component for the "Backed By" section.
 * @returns a React component.
 */
export const RDCBackedBy: React.FC = () => {
  const logos = [
    { name: "Pear", src: PearLogo, alt: "Pear logo" },
    { name: "500", src: FiveHundredLogo, alt: "500 logo" },
    { name: "StartX", src: StartXLogo, alt: "StartX logo" },
    { name: "e2vc", src: E2VCLogo, alt: "e2vc Logo" },
  ];

  return (
    <div className="flex flex-col items-center">
      {/* "Backed By" Text */}
      <p className="text-neutral-content text-lg md:text-md">Backed By</p>
      {/* Logos */}
      <div className="grid grid-cols-4 justify-evenly justify-items-center items-center mt-4 gap-6 lg:gap-12">
        {logos.map((logo) => (
          <img key={logo.name} src={logo.src} alt={logo.alt} className="max-h-16 w-auto" />
        ))}
      </div>
    </div>
  );
};
