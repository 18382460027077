import { MagnifyingGlassIcon } from "@heroicons/react/24/solid";
import { RDCBlogSummaryCard, RDCFinishedScrollingForm, RDCFooter, RDCNavbar } from "../components";
import { ChangeEventHandler, KeyboardEventHandler, useEffect, useState } from "react";
import { Blog, BLOG_CATEGORIES, BlogCategory, BlogService } from "../services/BlogService";
import mixpanel from "mixpanel-browser";
import { Helmet } from 'react-helmet';

/**
 * Page that displays EcoTrove blogs.
 * @returns a React component
 */
export const RDCAllBlogsPage: React.FC = () => {
  const [blogs, setBlogs] = useState<Blog[]>(BlogService.getBlogs({}));
  const [searchString, setSearchString] = useState<string>("");
  const [categoryFilter, setCategoryFilter] = useState<BlogCategory | undefined>();

  useEffect(() => {
    mixpanel.track("allBlogs");
  }, []);

  /**
   * Handler that updates the search string based on user input.
   * @param event the change event
   */
  const handleChangeSearch: ChangeEventHandler<HTMLInputElement> = (event) => {
    setSearchString(event.target.value);
  };

  /**
   * Hanlder that submits the search string when a user presses enter.
   * @param event the key up event
   */
  const handleSearchKeyUp: KeyboardEventHandler<HTMLInputElement> = (event) => {
    if (event.key === "Enter") {
      setBlogs(BlogService.getBlogs({ category: categoryFilter, searchString }));
      mixpanel.track("Search for blogs");
    }
  };

  /**
   * Handler for category filter events.
   * @param filter the category to filter on
   */
  const handleClickCategoryFilter = (filter: BlogCategory | undefined) => {
    setCategoryFilter(filter);
    setBlogs(BlogService.getBlogs({ category: filter }));
    mixpanel.track("Filter blogs by category");
  };

  return (
    <div className="tw-preflight">
    <Helmet>
      <title>EcoTrove | Blogs</title>
      <meta name="description" content="Learn how EcoTrove helps household and small businesses lower their energy bills and how EcoTrove operates." />
      <meta name="keywords" content="clean energy blogs, navigating PG&E, accessible clean energy, unlocking energy savings, tips to lower energy bills, energy bill budgeting, help with saving on utility bills, EcoTrove blogs" />
    </Helmet> 
      <RDCNavbar />
      {/* Header and main blog */}
      <div className="flex flex-col px-content-mobile lg:px-content mt-36">
        <div className="flex-none">
          <h1>EcoTrove's perspective</h1>
          <p className="text-neutral-content mt-5">
            Learn more about clean energy policy, how EcoTrove works, and tips & tricks for a lower energy bill.
          </p>
        </div>
        <div className="grow w-full self-center lg:self-start lg:w-1/3 my-16">
          <label className="daisy-input daisy-input-bordered flex items-center gap-default">
            <MagnifyingGlassIcon className="hero-icon-sm text-neutral-500" />
            <input
              type="text"
              className="grow font-sans"
              placeholder="Search"
              value={searchString}
              onChange={handleChangeSearch}
              onKeyUp={handleSearchKeyUp}
            />
          </label>
        </div>
        {blogs.length ? (
          <div>
            <div className="hidden lg:flex">
              <RDCBlogSummaryCard blog={blogs[0]} orientation="horizontal" />
            </div>
            <div className="flex lg:hidden">
              <RDCBlogSummaryCard blog={blogs[0]} orientation="vertical" />
            </div>
          </div>
        ) : (
          <div />
        )}
      </div>

      {/* Filter buttons */}
      <div className="flex flex-wrap gap-8 px-content-mobile lg:px-content py-16">
        <button
          className={`daisy-btn daisy-btn-neutral ${!categoryFilter ? "daisy-btn-active" : ""}`}
          onClick={() => handleClickCategoryFilter(undefined)}>
          View all
        </button>
        {BLOG_CATEGORIES.map((filter) => (
          <button
            className={`daisy-btn daisy-btn-neutral ${filter === categoryFilter ? "daisy-btn-active" : ""}`}
            key={filter}
            onClick={() => handleClickCategoryFilter(filter)}>
            {filter}
          </button>
        ))}
      </div>

      {/* Blog list */}
      {blogs.length > 1 ? (
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-16 px-content-mobile lg:px-content py-8">
          {blogs.slice(1).map((blog) => (
            <RDCBlogSummaryCard key={blog.id} blog={blog} />
          ))}
        </div>
      ) : (
        <div />
      )}

      {/* View height: finished scrolling & footer */}
      <div className="flex flex-col min-h-dvh pt-16">
        <RDCFinishedScrollingForm />
        <div className="grow" />
        <RDCFooter />
      </div>
    </div>
  );
};
