/** Props to pass to a `RDCTrustPilotStars` component. */
interface RDCTrustPilotStarsProps {
  rating: number;
}

/**
 * Component that shows TrustPilot-style stars based on a passed in rating.
 * @param props the props to render the component with
 * @returns a React component.
 */
export const RDCTrustPilotStars: React.FC<RDCTrustPilotStarsProps> = (props) => {
  const { rating } = props;
  const ratingInt = Math.floor(rating);
  const hasHalf = rating % 1 > 0;

  // Create an array with booleans indicating whether the star should be a half
  const stars: boolean[] = Array(ratingInt).fill(false);
  if (hasHalf) stars.push(true);

  return (
    <ul className="flex gap-x-0.5">
      {stars.map((isHalf, i) => (
        <>
          <div key={i} className={`bg-emerald-500 ${isHalf ? "pl-1 py-1" : "p-1"}`}>
            <div
              className={`daisy-mask daisy-mask-star bg-white ${isHalf ? "daisy-mask-half-1 w-1.5 h-3" : "size-3"}`}
              aria-label={isHalf ? "first half star, filled in" : "star"}
            />
          </div>
          {isHalf && (
            <div key={i + 1} className="bg-gray-300 pr-1 py-1 -ml-0.5">
              <div
                className="bg-white daisy-mask daisy-mask-star daisy-mask-half-2 w-1.5 h-3"
                aria-label="second half star, not filled in"
              />
            </div>
          )}
        </>
      ))}
    </ul>
  );
};
