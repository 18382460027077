import * as React from "react";
import { Slider as BaseSlider, SliderProps } from "@mui/base/Slider";

/**
 * Slider component from BaseUI, with some custom styling (this is more customizable than the DaisyUI slider).
 * See https://mui.com/base-ui/react-slider/ for more info.
 */
export const RDCSlider = React.forwardRef(function Slider(
  props: SliderProps,
  ref: React.ForwardedRef<HTMLSpanElement>
) {
  return (
    <BaseSlider
      {...props}
      ref={ref}
      slotProps={{
        thumb: {
          className:
            "ring-primary ring-8 w-4 h-4 -ml-2 flex items-center justify-center bg-white rounded-full shadow absolute",
        },
        root: { className: "w-full relative inline-block h-2 cursor-pointer" },
        rail: {
          className: "bg-neutral h-4 w-full rounded-full block absolute",
        },
        track: {
          className: "bg-primary h-4 absolute rounded-full",
        },
      }}
    />
  );
});
