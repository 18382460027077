import { FieldValues, Path, RegisterOptions, UseFormRegister } from "react-hook-form";

/**
 * Props to pass to a `RDCCheckBoxInput` component.
 */
export interface RDCCheckBoxInputProps<T extends FieldValues> {
  name: Path<T>;
  register: UseFormRegister<T>;
  validations: RegisterOptions<T, Path<T>>;
  checkBoxType: "primary" | "secondary" | "accent";
  checkBoxText: string | JSX.Element;
  invalid?: boolean;
  disabled?: boolean;
}

/**
 * Input component that takes a boolean value as a check box.
 * @param props the props to render the component with
 * @returns a React component.
 */
export function RDCCheckBoxInput<T extends FieldValues>(props: RDCCheckBoxInputProps<T>): JSX.Element {
  const { name, register, validations, checkBoxType, checkBoxText, invalid, disabled } = props;
  return (
    <div className="flex items-center gap-x-default">
      <input
        id={name}
        className={`daisy-checkbox ${`daisy-checkbox-${checkBoxType}`} aria-invalid:border-error aria-invalid:disabled:bg-error`}
        type="checkbox"
        aria-invalid={invalid}
        disabled={disabled}
        {...register(name, validations)}
      />
      <label className="daisy-label-text" htmlFor={name}>
        {checkBoxText}
      </label>
    </div>
  );
}
