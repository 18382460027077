import React, { useState } from 'react';
import { supabase } from '../supabaseClient';
import Navbar from "../components/Navbar";
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import background4 from '../images/background4.jpeg';
import { Helmet } from 'react-helmet';
import UserBehavior from "../components/UserBehavior";
import GlobalStyles from '../GlobalStyles'; // Import the global styles
import { Turnstile } from '@marsidev/react-turnstile';

const RoundedRectangle = styled.div`
  border: 2px solid #253D80;
  border-radius: 15px;
  padding: 1rem;
  margin: 1rem 0;
  width: 80%;
`;

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  font-family: 'Source Sans Pro', sans-serif;

  @media (max-width: 768px) {
    background: url(${background4}) no-repeat center center fixed;
    background-size: cover;
    & > div {
      background: rgba(255, 255, 255, 0.5);
    }
  }

  @media (min-width: 769px) {
    flex-direction: row;
    &::after {
      content: '';
      background: url(${background4}) no-repeat center center fixed;
      background-size: cover;
      flex: 1;
    }
  }
`;

const ContentContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 2rem;
  padding-top: 70px;

  @media (min-width: 769px) {
    width: 50vw;
  }
`;

const ErrorMessage = styled.div`
  color: red;
  margin-top: 10px;
  font-size: 20% larger; /* Increased by 20% */
`;

const LoginPage = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [captchaToken, setCaptchaToken] = useState('')
  const [captchaKey, setCaptchaKey] = useState(0); // Used to reset captcha


  const handleLogin = async (event: React.FormEvent<HTMLFormElement>) => {
    setLoading(true);
    event.preventDefault();
    console.log("captcha token", captchaToken);
    if (captchaToken === '') {
      setErrorMessage("Please complete captcha");
      setLoading(false);
      return;
    }

    try {
      const { data, error } = await supabase.auth.signInWithPassword({
        email,
        password,
        options: { captchaToken },
      });

      if (error) {
        setErrorMessage(error.message);
        setCaptchaToken('');
        // Reset the captcha by changing the key to force re-render
        setCaptchaKey((prevKey) => prevKey + 1);
        return;
      }
      console.log("sign in successful")

      const user = data?.user;

      // Redirect to questionnaire or user dashboard after successful login
      let questionsComplete = true;
      let termsComplete = true;

      //check supabase to see if questions were complete
      const { data: userData, error: questionsError } = await supabase
      .from('user-profiles')
      .select('*')
      .eq('user_id', user?.id)
      .single();

      if (questionsError) {
        throw (questionsError)
      }

      if (!userData?.questions_complete){
        questionsComplete = false;
      }
      else {
        questionsComplete = userData?.questions_complete;
      }

      if (!userData?.terms_complete){
        termsComplete = false;
      }
      else {
        termsComplete = userData?.terms_complete;
      }

      const isBusiness = userData?.is_business;
  
      console.log("terms complete?", termsComplete)
      if (!termsComplete) {
        navigate('/terms-and-conditions');
        return;
      }

      if (isBusiness) {
        navigate('/user-dashboard')
        return;
      }

      if (!questionsComplete) {
        navigate('/user-questions');
        return;
      } 
      navigate('/user-dashboard')

    } catch (error) {
      console.error('Error signing in:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleForgotPassword = async () => {
    let lackingEmailError = false;
    try {
      if (email === '' || email === null || email === undefined) {
        alert("Please enter an email to update your password");
        lackingEmailError = true;
        return;
      }
      if (captchaToken === '') {
        setErrorMessage("Please complete captcha");
        return;
      }
      // Initiate password reset process
      const { data , error } = await supabase.auth.resetPasswordForEmail(email, 
        {
        // redirectTo: 'http://localhost:3001/update-password',
        redirectTo: 'https://ecotrove.com/update-password', 
        captchaToken
      })

      console.log(data);

      if (error) {
        throw error;
      }

      alert('Password reset email has been sent. Please check your inbox.');
    } catch (error) {
      if (lackingEmailError) {
        setErrorMessage('');
      }
      else {
        setErrorMessage('Error sending password reset. Please enter correct email address or create account. ');
      }

      console.error('Error sending password reset email:', error);
      return;
    }
  };

  const handleCreateAccount = async () => {
      navigate('/create-account')
  };

  return (
    <>
    <GlobalStyles />
    <Helmet>
      <title>EcoTrove | Login to Your Account</title>
      <meta name="description" content="Login to your EcoTrove account to manage your fixed-price clean energy payment plan. You are paying less for greener, predictable, power." />
      <meta name="keywords" content="login, account, fixed-price energy, clean energy subscription, lower energy bills, EcoTrove, green power" />
      <UserBehavior />
    </Helmet>
    <Navbar />
    <PageContainer key={captchaKey}>
      <ContentContainer>
        <h1 style={{ color: '#253D80', textAlign: 'center', fontSize: '200%' }}>Login to EcoTrove</h1>
        <RoundedRectangle>
          <form onSubmit={handleLogin}>
            <div style={{ marginBottom: '1rem' }}>
              <label style={{ fontWeight: 'bold', fontSize: '110%' }}>
                Email:
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  style={{ marginLeft: '10px', fontSize: '110%' }}
                />
              </label>
            </div>
            <div style={{ marginBottom: '1rem' }}>
              <label style={{ fontWeight: 'bold', fontSize: '110%' }}>
                Password:
                <input
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                  style={{ marginLeft: '10px', fontSize: '110%' }}
                />
              </label>
            </div>
            <Turnstile
                siteKey="0x4AAAAAAAiqGq8fYinFCIYM"
                onSuccess={(token) => {
                  setCaptchaToken(token);
                }}
              />
            <div style={{ marginTop: '1rem', display: 'flex', gap: '10px' }}>
              <button type="submit" disabled={loading} style={{ borderRadius: '10px', backgroundColor: '#80AA7B', color: 'white', padding: '0.25rem 0.5rem', border: 'none', cursor: 'pointer', fontSize: '110%' }} onMouseOver={(e) => (e.currentTarget.style.backgroundColor = '#253D80')} onMouseOut={(e) => (e.currentTarget.style.backgroundColor = '#80AA7B')}>
                {loading ? 'Logging in...' : 'Login'}
              </button>
              <button type="button" onClick={handleForgotPassword} style={{ borderRadius: '10px', backgroundColor: '#80AA7B', color: 'white', padding: '0.25rem 0.5rem', border: 'none', cursor: 'pointer', fontSize: '110%' }} onMouseOver={(e) => (e.currentTarget.style.backgroundColor = '#253D80')} onMouseOut={(e) => (e.currentTarget.style.backgroundColor = '#80AA7B')}>
                Forgot Password?
              </button>
            </div>
            <button type="button" onClick={handleCreateAccount} style={{ marginTop: '1rem', borderRadius: '10px', backgroundColor: '#80AA7B', color: 'white', padding: '0.25rem 0.5rem', border: 'none', cursor: 'pointer', fontSize: '110%' }} onMouseOver={(e) => (e.currentTarget.style.backgroundColor = '#253D80')} onMouseOut={(e) => (e.currentTarget.style.backgroundColor = '#80AA7B')}>
              Create Account
            </button>
            {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
          </form>
        </RoundedRectangle>
      </ContentContainer>
    </PageContainer>
    </>
  );
};

export default LoginPage;
