import styled from "styled-components";
import { Helmet } from 'react-helmet';
import UserBehavior from "../components/UserBehavior";
import GlobalStyles from '../GlobalStyles'; // Import the global styles

const Container = styled.section`
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center; /* Center content vertically */
  flex-direction: column; /* Stack items vertically */
  min-height: 100vh; /* Ensures that the container fills the whole screen */
  background-color: #e6f7ff; /* Specified background color */
`;

const Title = styled.p`
  font-size: 32px;
  font-weight: bold;
  color: #111d57;
  line-height: 1.5;
  margin-bottom: 5px;
  @media (max-width: 768px) {
    font-size: 28px;
  }
`;

const ConfirmEmail = () => {
 const urlParams = new URLSearchParams(window.location.search);
  const email = urlParams.get('email') || '';

  return (
    <>
    <GlobalStyles /> {/* Include global styles */}
    <Helmet>
    <UserBehavior />
   </Helmet> 

    <main>
    <Container>
    <Title>
       We have sent a confirmation email to {email}. Please confirm to log in. 
    </Title>
</Container>
</main>
</>
  );
};

export default ConfirmEmail;
