import { useEffect, useState } from "react";
import {
  RDCAlertBanner,
  RDCCarbonEquivalentSlider,
  RDCFinishedScrollingForm,
  RDCFooter,
  RDCNavbar,
  RDCPlansTable,
} from "../components";
import BeforeAndAfterGraphic from "../images/graphics/BeforeAndAfterGraphic.png";
import FamilyReadingStock from "../images/stock/FamilyReadingStock.png";
import { ECOTROVE_EMAIL_INFO, MOBILE_SNAP_SPACER } from "../../constants";
import mixpanel from "mixpanel-browser";
import { Helmet } from "react-helmet";

const PAGE_ID = "how-it-works";

const FAQS: { q: string; a: string }[] = [
  {
    q: "How does EcoTrove’s payment plan work?",
    a: "You pay EcoTrove a predictable, fixed price every month and we pay your electricity and natural gas bills with your utility on your behalf. You can still monitor your power bills and make changes to your utility account, but you stop paying your utility.",
  },
  {
    q: "How can EcoTrove guarantee a fixed price?",
    a: "Our proprietary technology forecasts how much power you will need in the following year, identifies the cheapest possible green energy options, most appropriate rate plans and government incentives applicable to you. We accept the risk of price fluctuations on your behalf so that you can use the power you need, without worrying about large bill swings.",
  },
  {
    q: "Does EcoTrove cover both electricity and natural gas?",
    a: "Yes, your EcoTrove payment plan covers both electricity and natural gas. EcoTrove will immediately switch your electricity source to a renewable energy generator. Since there is no renewable energy option to power your gas-appliances (gas stove, gas heater etc.), you will continue receiving natural gas without interruption.",
  },
  {
    q: "What is EcoTrove's relationship with PG&E?",
    a: "EcoTrove is a private company, separate from PG&E. EcoTrove is an approved PG&E third-party service provider, with third-party ID 51684. EcoTrove's subscriber agreement includes a tri-party contract between EcoTrove, the EcoTrove customer, and PG&E which allows EcoTrove to act on the customer's behalf and pay bills with PG&E. EcoTrove customers continue to own their PG&E accounts to ensure there is no disruption in service if they decide to stop using EcoTrove, but they no longer pay PG&E bills and paying for PG&E bills becomes EcoTrove's responsibility.",
  },
  {
    q: "How does EcoTrove switch me to clean energy?",
    a: "EcoTrove identifies the cheapest renewable energy generator available in your area. This typically corresponds to a utility-approved Community Choice Aggregator or a community solar provider. We contact the renewable energy generator and your utility on your behalf, and request them to immediately switch your electricity source to a cleaner option.",
  },
  {
    q: "What happens if I start using less energy?",
    a: "EcoTrove reserves the right reward you for using less energy. For every month where you use less electricity or natural gas compared to the same month last year, EcoTrove can issue a discount to your account.",
  },
  {
    q: "What is your cancellation policy?",
    a: "You can cancel at any time by emailing us at info@ecotrove.com or on your account, and paying your last due subscription price to EcoTrove. If you decide to cancel due to EcoTrove raising prices of your subscription, then your last month’s utility bill is entirely on us.",
  },
  {
    q: "This sounds too good to be true - how on earth do you make money?",
    a: "EcoTrove's purpose is to make clean energy affordable and accessible. Our first priority is to ensure you use all the power you need with guaranteed fixed prices that are lower. By automatically analyzing all available energy data and incentives, EcoTrove's algorithms can take any household or small business power bill and drastically reduce it. We pass on the majority of the savings we find to you, and any additional saving we are able to secure above and beyond becomes our profit.",
  },
];

/**
 * Page that explains how EcoTrove works.
 * @returns a React component.
 */
export const RDCHowItWorksPage: React.FC = () => {
  const [errorAlert, setErrorAlert] = useState<string>("");

  useEffect(() => {
    mixpanel.track("howItWorks");
  }, []);

  return (
    <div
      id={PAGE_ID}
      className="tw-preflight flex-col relative h-[calc(100vh-theme('spacing.navbar'))] snap-y snap-mandatory overflow-y-auto mt-navbar">
      <Helmet>
        <title>EcoTrove | How it Works</title>
        <meta
          name="description"
          content="EcoTrove saves customers up to $500 a year off their power bills and 30%+ off their carbon emissions. Try our ineractive calculator."
        />
        <meta
          name="keywords"
          content="ecotrove pricing tiers, how much is my monthly bill, ecotrove widget, green saver, max saver, free insights, how ecotrove works, how ecotrove makes money, fixed-price energy, energy concierge, clean energy subscription, lower energy bills, EcoTrove, green power, clean energy, flat rate energy bills, lower utility bills, flatten utility bills, renewable energy, pg&E, sce, sdg&E"
        />
        {/* Open Graph Meta Tags */}
        <meta property="og:title" content="How It Works - EcoTrove" />
        <meta
          property="og:description"
          content="Discover how EcoTrove helps you transition to sustainable and cost-effective energy solutions with ease."
        />
        <meta property="og:image" content="https://i.postimg.cc/gjw9qmLc/Screenshot-2024-12-02-at-8-52-27-AM.png" />
        <meta property="og:url" content="https://ecotrove.com/how-it-works" />
        <meta property="og:type" content="website" />
        {/* Twitter Card Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="How It Works - EcoTrove" />
        <meta
          name="twitter:description"
          content="Discover how EcoTrove helps you transition to sustainable and cost-effective energy solutions with ease."
        />
        <meta name="twitter:image" content="https://i.postimg.cc/gjw9qmLc/Screenshot-2024-12-02-at-8-52-27-AM.png" />
      </Helmet>

      {/* Error banner that can be set by subcomponents */}
      {errorAlert ? (
        <RDCAlertBanner type="error" message={errorAlert} onClickClose={() => setErrorAlert("")} />
      ) : undefined}

      {/* Navbar */}
      <RDCNavbar contentId={PAGE_ID} />

      {/* Slider section */}
      <div className="snap-start grid grid-cols-1 lg:grid-cols-2 gap-16 p-content-mobile lg:p-content">
        {/* Header */}
        <div className="col-span-1 lg:col-span-2">
          <h1 className="text-center mt-4">Your energy concierge</h1>
          <p className="text-center text-neutral-content mt-4">
            We're not your typical energy company. See how EcoTrove works.
          </p>
        </div>
        {/* Explanation text (this comes after the price slider visually but appears first in the DOM to have the h2 and h3 headings correctly ordered) */}
        <div className="max-md:snap-start max-md:pt-content-mobile col-span-1 order-last">
          <h2 className="leading-none">You can lower your power bills.</h2>
          <span className="text-neutral-content">
            <br />
            <p>
              Power bills have doubled in the last decade, making it harder to afford the energy you need. You don't
              need to choose between saving money and living in comfort.
            </p>
            <br />
            <p>
              Allow EcoTrove to forecast how much energy you will use in the future and optimize your power plan to save
              you up to $500/yr. without compromising on your energy needs.
            </p>
          </span>
        </div>
        {/* Price slider */}
        <RDCCarbonEquivalentSlider
          savingsRate={0.07}
          start={30}
          middle={400}
          end={3000}
          defaultValue={360}
          step1={10}
          step2={100}
        />
      </div>

      {/* Before & after EcoTrove */}
      <div className="snap-start grid grid-cols-1 lg:grid-cols-2 gap-8 lg:gap-24 items-center min-h-full bg-neutral px-content-mobile lg:px-content py-12 lg:py-content">
        {/* Text */}
        <div className="max-md:snap-start col-span-1">
          <h2 className="leading-none">EcoTrove switches you to the cheapest, greenest rates.</h2>
          <div className="text-neutral-content mt-5">
            <p>
              EcoTrove's algorithm analyzes your energy needs and registers as your energy agent with your utility to:
            </p>
            <ul className="list-check mt-5 space-y-3">
              <li key="cheapest">Find the cheapest clean energy rates available through your utility</li>
              <li key="incentives">Automatically apply for eligible government incentives</li>
              <li key="optimize">Optimize your rate plan and utility program choice for each billing period</li>
            </ul>
          </div>
        </div>
        {/* Graphic */}
        <img
          className="col-span-1 order-first lg:order-last"
          src={BeforeAndAfterGraphic}
          alt="EcoTrove chooses cheaper rates than traditional energy providers"
        />
        {MOBILE_SNAP_SPACER}
      </div>

      {/* Peace of mind */}
      <div className="snap-start grid grid-cols-1 lg:grid-cols-2 gap-8 lg:gap-16 items-center min-h-full px-content-mobile lg:px-content py-12 lg:py-content">
        {/* Graphic */}
        <img className="col-span-1" src={FamilyReadingStock} alt="family reading" />
        {/* Text */}
        <div className="col-span-1">
          <h2 className="leading-none">Enjoy the simplicity of flat, predictable monthly prices.</h2>
          <div className="text-neutral-content mt-5">
            <p>
              With EcoTrove, you pay one low, fixed monthly fee, and we handle your actual utility bills. Not satisfied?
              Cancel anytime&mdash;no hassle, no risk.
            </p>
          </div>
        </div>
        {MOBILE_SNAP_SPACER}
      </div>

      {/* Check savings */}
      <div className="snap-start bg-neutral px-content-mobile lg:px-content py-20">
        {/* Explainer text */}
        <span className="text-center">
          <h2 className="text-center leading-none">
            Cheaper, greener,
            <br />
            more predictable power bills.
          </h2>
          <p className="text-neutral-content mt-5">
            Why overpay when you can save both your wallet and the environment?
          </p>
        </span>

        {/* Three-tiered plan table */}
        <h3 className="text-center mt-16">EcoTrove Plans</h3>
        <RDCPlansTable />
        {MOBILE_SNAP_SPACER}
      </div>

      {/* Registrations & learn more header */}
      <div className="snap-start flex flex-col justify-between space-y-24 lg:min-h-full px-content-mobile lg:px-content py-16">
        {/* Registrations */}
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-16">
          <div className="row-span-1 lg:row-span-2">
            <h2>EcoTrove's registrations</h2>
            <p className="text-neutral-content mt-5">
              EcoTrove is a registered energy agent with Califoria utilities with ID 51684 with Pacific Gas & Electric
              and ID "EcoTrove - ESP" with Southern California Edison.
            </p>
          </div>
          <div>
            <h3>What your energy agent can do on your behalf:</h3>
            <ul className="list-check text-primary mt-5 space-y-3">
              <li key="pay">Pay your bills</li>
              <li key="optimize">Optimize your rate plans</li>
              <li key="switch">Switch your electricity generation to clean energy producers</li>
              <li key="apply">Apply to government incentives on your behalf</li>
            </ul>
            <hr className="my-8" />
            <h3>What your energy agent cannot do:</h3>
            <ul className="list-dash text-neutral-content mt-5 space-y-3">
              <li key="lock">Lock you out of your account or bills</li>
              <li key="debt">Cause you to incur penalties or debt to your utility</li>
              <li key="stop">Start or stop your electricity or gas service</li>
            </ul>
          </div>
        </div>
        {/* Learn more header */}
        <div className="snap-start justify-self-end pt-content-mobile">
          <div className="text-center">
            <h2 className="leading-none">
              Learn more
              <br />
              about EcoTrove
            </h2>
            <p className="text-neutral-content mt-5 -mb-20">Including how we make money.</p>
          </div>
        </div>
      </div>

      {/* FAQs & contact us */}
      <div className="snap-start flex flex-col justify-between items-center space-y-24 lg:min-h-full px-content-mobile lg:px-content py-16">
        {/* FAQs */}
        <fieldset className="space-y-5 w-full lg:w-192">
          {FAQS.map(({ q, a }, i) => (
            <div className="daisy-collapse daisy-collapse-arrow border border-neutral-200" key={q}>
              <input type="radio" name="FAQ" aria-label={`FAQ question #${i}`} />
              <p className="daisy-collapse-title font-semibold">{q}</p>
              <p className="daisy-collapse-content">{a} </p>
            </div>
          ))}
        </fieldset>
        {/* Contact us */}
        <div className="snap-start text-center pt-content-mobile lg:pt-content">
          <h3>Still have questions?</h3>
          <p className="text-neutral-content mt-5">Email our team to learn more.</p>
          <a
            className="daisy-btn daisy-btn-ghost border border-neutral-200 px-button mt-5"
            href={`mailto:${ECOTROVE_EMAIL_INFO}`}>
            Contact
          </a>
        </div>
      </div>

      {/* Finished scrolling & footer */}
      <div className="snap-start flex flex-col min-h-full">
        <RDCFinishedScrollingForm />
        <div className="grow" />
        <RDCFooter />
      </div>
    </div>
  );
};
