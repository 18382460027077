import React, { useState, useEffect } from "react";
import { RDCFooter, RDCNavbar } from "../components";

/**
 * Scrolls smoothly to the section corresponding to the given ID.
 */
const scrollToSection = (id: string) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({ behavior: "smooth" });
  }
};



/**
 * PrivacyPolicy Component
 * @description Displays the EcoTrove Terms of Service with a navigation menu.
 */
export const RDCPrivacyPolicy: React.FC = () => {

  const [isIframe, setIsIframe] = useState(false);
  const handlePrint = () => {
    window.print();
  };

  useEffect(() => {
    const handleAnchorClick = (event: MouseEvent) => {
      const target = event.target as HTMLAnchorElement;
      if (target?.hash) {
        const id = target.hash.substring(1);
        scrollToSection(id);
      }
    };

    document.addEventListener("click", handleAnchorClick);
    return () => {
      document.removeEventListener("click", handleAnchorClick);
    };
  }, []);

  useEffect(() => {
    // Check if the "iframe" query parameter is present
    const params = new URLSearchParams(window.location.search);
    if (params.get("iframe") === "true") {
      setIsIframe(true);
    }
  }, []);


  return (
    <div className="tw-preflight">
      {/* Navbar */}
      {!isIframe && <RDCNavbar />}

      {/* Header Section */}
      <div className="col-span-1 lg:col-span-2">
        <div
          className={`max-w-5xl mx-auto ${!isIframe ? 'pt-20' : ''}`}
          /* Adjust 'pt-20' based on navbar height */
        >
          <h1 className="text-4xl font-bold">EcoTrove's Privacy Policy</h1>
          <p className="text-neutral-content mt-2">
            Effective date: October 1st, 2024
          </p>
        </div>
      </div>

      {/* Main Content Section */}
      <div className="bg-base-100 text-base-content py-8 px-4">
        <div className="max-w-5xl mx-auto space-y-8">
          {/* Section: Introduction */}
          <section>
            <p className="mt-4">
              At EcoTrove, we take your privacy seriously. Please read this Privacy Policy to learn how we treat your personal data. By using or accessing our Services in any manner, you acknowledge that you accept the practices and policies outlined below, and you hereby consent that we will collect, use and disclose your information as described in this Privacy Policy.
            </p>
            <p className="mt-4">
              Remember that your use of EcoTrove's Services is at all times subject to our{" "}
              <a
                href="https://ecotrove.com/terms-of-service"
                className="text-primary underline"
              >
                Terms Of Service
              </a>
              {" "}, which incorporates this Privacy Policy. Any terms we use in this Policy without defining them have the definitions given to them in the Terms of Use.
            </p>
            <p className="mt-4">
              You may print a copy of this Privacy Policy by{" "}
              <button
                onClick={handlePrint}
                className="text-primary underline hover:text-primary-focus"
              >
                clicking here
              </button>.
            </p>
            <p className="mt-4">
              As we continually work to improve our Services, we may need to change this Privacy Policy from time to time. We will alert you of material changes by placing a notice on the EcoTrove website, by sending you an email and/or by some other means. Please note that if you’ve opted not to receive legal notice emails from us (or you haven’t provided us with your email address), those legal notices will still govern your use of the Services, and you are still responsible for reading and understanding them. If you use the Services after any changes to the Privacy Policy have been posted, that means you agree to all of the changes.
            </p>
          </section>

          <section className="bg-gray-100 border border-gray-300 rounded-lg p-6 max-w-4xl mx-auto mt-8">
            <h2 className="text-2xl font-bold mb-4">Privacy Policy Table of Contents</h2>
            <ul className="space-y-2">
              <li>
                <a href="#privacy-covers" className="text-primary hover:underline">
                  What this Privacy Policy Covers
                </a>
              </li>
              <li>
                <a href="#personal-data" className="text-primary hover:underline">
                  Personal Data
                </a>
              </li>
              <li>
                <a href="#categories-of-data" className="text-primary hover:underline">
                  Categories of Personal Data We Collect
                </a>
              </li>
              <li>
                <a href="#business-purposes" className="text-primary hover:underline">
                  Our Commercial or Business Purposes for Collecting Personal Data
                </a>
              </li>
              <li>
                <a href="#permitted-purposes" className="text-primary hover:underline">
                  Other Permitted Purposes for Processing Personal Data
                </a>
              </li>
              <li>
                <a href="#sources-of-data" className="text-primary hover:underline">
                  Categories of Sources of Personal Data
                </a>
              </li>
              <li>
                <a href="#disclose-data" className="text-primary hover:underline">
                  How We Disclose Your Personal Data
                </a>
              </li>
              <li>
                <a href="#tracking-tools" className="text-primary hover:underline">
                  Tracking Tools, Advertising, and Opt-Out
                </a>
              </li>
              <li>
                <a href="#data-security" className="text-primary hover:underline">
                  Data Security
                </a>
              </li>
              <li>
                <a href="#children-data" className="text-primary hover:underline">
                  Personal Data of Children
                </a>
              </li>
              <li>
                <a href="#state-privacy-rights" className="text-primary hover:underline">
                  Other State Law Privacy Rights
                </a>
              </li>
              <li>
                <a href="#contact-information" className="text-primary hover:underline">
                  Contact Information
                </a>
              </li>
            </ul>
          </section>

          {/* Section: What it covers*/}
          <section id="privacy-covers">
            <h2 className="text-2xl font-bold">What this Privacy Policy Covers</h2>
            <p className="mt-4">
              This Privacy Policy covers how we treat Personal Data that we gather
              when you access or use our Services. “Personal Data” means any
              information that identifies or relates to a particular individual and
              also includes information referred to as “personally identifiable
              information” or “personal information” under applicable data privacy
              laws, rules or regulations. This Privacy Policy does not cover the
              practices of companies we don’t own or control or people we don’t
              manage.
            </p>
          </section>

          {/* Section: Personal Data*/}
          <section id="personal-data">
            <h2 className="text-2xl font-bold">Personal Data</h2>
          </section>

          <section className="max-w-6xl mx-auto mt-8" id="categories-of-data">
            <h2 className="text-2xl font-bold mb-4">Categories of Personal Data We Collect</h2>
            <p className="mb-4">
              This chart details the categories of Personal Data that we collect and have collected over the past 12 months:
            </p>
            <div className="overflow-x-auto">
              <table className="table-auto border-collapse border border-gray-300 w-full text-left bg-base-100">
                <thead>
                  <tr>
                    <th className="border border-gray-300 px-4 py-2 bg-gray-100 font-bold">
                      Category of Personal Data
                    </th>
                    <th className="border border-gray-300 px-4 py-2 bg-gray-100 font-bold">
                      Business or Commercial Purpose(s) for Collection
                    </th>
                    <th className="border border-gray-300 px-4 py-2 bg-gray-100 font-bold">
                      Categories of Third Parties With Whom We Disclose this Personal Data
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="border border-gray-300 px-4 py-2">Profile or Contact Data</td>
                    <td className="border border-gray-300 px-4 py-2">
                      Providing, Customizing, and Improving the Services; Marketing the Services; Corresponding with You
                    </td>
                    <td className="border border-gray-300 px-4 py-2">
                      Service Providers, Parties You Authorize, Access, or Authenticate
                    </td>
                  </tr>
                  <tr>
                    <td className="border border-gray-300 px-4 py-2">Payment Data</td>
                    <td className="border border-gray-300 px-4 py-2">
                      Providing, Customizing, and Improving the Services; Marketing the Services; Corresponding with You
                    </td>
                    <td className="border border-gray-300 px-4 py-2">
                      Service Providers, Parties You Authorize, Access, or Authenticate
                    </td>
                  </tr>
                  <tr>
                    <td className="border border-gray-300 px-4 py-2">Commercial Data</td>
                    <td className="border border-gray-300 px-4 py-2">
                      Providing, Customizing, and Improving the Services; Marketing the Services; Corresponding with You
                    </td>
                    <td className="border border-gray-300 px-4 py-2">
                      Service Providers, Parties You Authorize, Access, or Authenticate
                    </td>
                  </tr>
                  <tr>
                    <td className="border border-gray-300 px-4 py-2">Web Analytics</td>
                    <td className="border border-gray-300 px-4 py-2">
                      Providing, Customizing, and Improving the Services; Marketing the Services; Corresponding with You
                    </td>
                    <td className="border border-gray-300 px-4 py-2">
                      Service Providers, Parties You Authorize, Access, or Authenticate
                    </td>
                  </tr>
                  <tr>
                    <td className="border border-gray-300 px-4 py-2">Consumer Demographic Data</td>
                    <td className="border border-gray-300 px-4 py-2">
                      Providing, Customizing, and Improving the Services; Marketing the Services; Corresponding with You
                    </td>
                    <td className="border border-gray-300 px-4 py-2">
                      Service Providers, Parties You Authorize, Access, or Authenticate
                    </td>
                  </tr>
                  <tr>
                    <td className="border border-gray-300 px-4 py-2">Health Data</td>
                    <td className="border border-gray-300 px-4 py-2">
                      Providing, Customizing, and Improving the Services; Marketing the Services; Corresponding with You
                    </td>
                    <td className="border border-gray-300 px-4 py-2">
                      Service Providers, Parties You Authorize, Access, or Authenticate
                    </td>
                  </tr>
                  <tr>
                    <td className="border border-gray-300 px-4 py-2">
                      Other Identifying Information that You Voluntarily Choose to Provide, including any data related to your Energy Account
                    </td>
                    <td className="border border-gray-300 px-4 py-2">
                      Providing, Customizing, and Improving the Services; Marketing the Services; Corresponding with You
                    </td>
                    <td className="border border-gray-300 px-4 py-2">
                      Service Providers, Parties You Authorize, Access, or Authenticate
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </section>

          {/* Commercial and business purposes */}
          <section id="business-purposes">
            <h2 className="text-2xl font-bold">Our Commercial or Business Purposes for Collecting Personal Data</h2>
            <ul className="list-disc pl-5 space-y-4 mt-4">
              <li>
                <strong>Providing, Customizing and Improving the Services</strong>
                <ul className="list-disc pl-5 space-y-2 mt-2">
                  <li>Creating and managing your account or other user profiles.</li>
                  <li>Processing orders or other transactions; billing.</li>
                  <li>Providing you with the products, services or information you request.</li>
                  <li>Meeting or fulfilling the reason you provided the information to us.</li>
                  <li>Providing support and assistance for the Services.</li>
                  <li>
                    Improving the Services, including testing, research, internal analytics and product
                    development.
                  </li>
                  <li>
                    Personalizing the Services, website content and communications based on your preferences.
                  </li>
                  <li>Doing fraud protection, security and debugging.</li>
                </ul>
              </li>
              <li>
                <strong>Marketing the Services</strong>
                <ul className="list-disc pl-5 space-y-2 mt-2">
                  <li>Marketing and selling the Services.</li>
                  <li>Showing users advertisements, including interest-based or online behavioral advertising.</li>
                </ul>
              </li>
              <li>
                <strong>Corresponding with You</strong>
                <ul className="list-disc pl-5 space-y-2 mt-2">
                  <li>
                    Responding to correspondence that we receive from you, contacting you when necessary or
                    requested, and sending you information about EcoTrove or the Services.
                  </li>
                  <li>Sending emails and other communications according to your preferences.</li>
                </ul>
              </li>
            </ul>
          </section>


          {/* Other purposes for collecting data */}
          <section id="permitted-purposes">
            <h2 className="text-2xl font-bold">Other Permitted Purposes for Processing Personal Data</h2>
            <p className="mt-4">
              In addition, each of the above referenced categories of Personal Data
              may be collected, used, and disclosed with the government, including
              law enforcement, or other parties to meet certain legal requirements
              and enforcing legal terms including: fulfilling our legal obligations
              under applicable law, regulation, court order or other legal process,
              such as preventing, detecting and investigating security incidents and
              potentially illegal or prohibited activities; protecting the rights,
              property or safety of you, EcoTrove or another party; enforcing any
              agreements with you; responding to claims that any posting or other
              content violates third-party rights; and resolving disputes.
            </p>
            <p className="mt-4">
              We will not collect additional categories of Personal Data or use the
              Personal Data we collected for materially different, unrelated or
              incompatible purposes without providing you notice or obtaining your
              consent.
            </p>
          </section>

          {/* Categories of Sources of Personal Data */}
          <section id="sources-of-data">
            <h2 className="text-2xl font-bold">Categories of Sources of Personal Data</h2>
            <p className="mt-4">
              We collect Personal Data from the following categories of sources:
            </p>
            <ul className="list-disc pl-5 space-y-4 mt-4">
              <li>
                <strong>Information Provided Directly to Us</strong>
                <ul className="list-disc pl-5 space-y-2 mt-2">
                  <li>When you create an account or use our interactive tools and Services.</li>
                  <li>
                    When you voluntarily provide information in free-form text boxes through the Services or through responses to surveys or questionnaires.
                  </li>
                  <li>When you send us an email or otherwise contact us.</li>
                </ul>
              </li>
              <li>
                <strong>Information Collected Automatically</strong>
                <ul className="list-disc pl-5 space-y-2 mt-2">
                  <li>
                    Through Cookies (defined in the “Tracking Tools, Advertising and Opt-Out” section below).
                  </li>
                  <li>
                    If you download and install certain applications and software we make available, we may receive and collect information transmitted from your computing device for the purpose of providing relevant Services, such as information regarding when you are logged on and available to receive updates or alert notices.
                  </li>
                </ul>
              </li>
            </ul>
          </section>


          {/* How We Disclose Your Personal Data */}
          <section id="disclose-data">
            <h2 className="text-2xl font-bold">How We Disclose Your Personal Data</h2>
            <p className="mt-4">
              We disclose your Personal Data to the categories of service providers and other parties listed in this section. Depending on state laws that may be applicable to you, some of these disclosures may constitute a “sale” of your Personal Data. For more information, please refer to the state-specific sections below.
            </p>
            <ul className="list-disc pl-5 space-y-4 mt-4">
              <li>
                <strong>Service Providers</strong>
                <p className="mt-2">
                  These parties help us provide the Services or perform business functions on our behalf. They include:
                </p>
                <ul className="list-disc pl-5 space-y-2 mt-2">
                  <li>Hosting, technology, and communication providers.</li>
                  <li>Analytics providers for web traffic or usage of the site.</li>
                  <li>Security and fraud prevention consultants.</li>
                  <li>Support and customer service vendors.</li>
                  <li>Product fulfillment and delivery providers.</li>
                  <li>
                    Payment processors.
                    <ul className="list-disc pl-5 space-y-2 mt-2">
                      <li>
                        Our payment processing partner Stripe, Inc. (“Stripe”) collects your voluntarily-provided payment card information necessary to process your payment.
                      </li>
                      <li>
                        Please see Stripe’s <a href="https://stripe.com/terms" className="text-primary underline">terms of service</a> and <a href="https://stripe.com/privacy" className="text-primary underline">privacy policy</a> for information on its use and storage of your Personal Data.
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li>
                <strong>Parties You Authorize, Access, or Authenticate</strong>
                <ul className="list-disc pl-5 space-y-2 mt-2">
                  <li>Your Energy Provider.</li>
                </ul>
              </li>
            </ul>
          </section>

          {/* Tracking Tools, Advertising and Opt-Out */}
          <section id="tracking-tools">
            <h2 className="text-2xl font-bold">Tracking Tools, Advertising and Opt-Out</h2>
            <p className="mt-4">
              The Services use cookies and similar technologies such as pixel tags, web beacons, clear GIFs, and JavaScript (collectively, “Cookies”) to enable our servers to recognize your web browser, tell us how and when you visit and use our Services, analyze trends, learn about our user base, and operate and improve our Services. Cookies are small pieces of data—usually text files—placed on your computer, tablet, phone, or similar device when you use that device to access our Services. We may also supplement the information we collect from you with information received from third parties, including third parties that have placed their own Cookies on your device(s).
            </p>
            <p className="mt-4">
              Please note that because of our use of Cookies, the Services do not support “Do Not Track” requests sent from a browser at this time.
            </p>
            <p className="mt-4">We use the following types of Cookies:</p>
            <ul className="list-disc pl-5 space-y-4 mt-4">
              <li>
                <strong>Essential Cookies</strong>
                <p className="mt-2">
                  Essential Cookies are required for providing you with features or services that you have requested. For example, certain Cookies enable you to log into secure areas of our Services. Disabling these Cookies may make certain features and services unavailable.
                </p>
              </li>
              <li>
                <strong>Functional Cookies</strong>
                <p className="mt-2">
                  Functional Cookies are used to record your choices and settings regarding our Services, maintain your preferences over time, and recognize you when you return to our Services. These Cookies help us to personalize our content for you, greet you by name, and remember your preferences (for example, your choice of language or region).
                </p>
              </li>
              <li>
                <strong>Performance/Analytical Cookies</strong>
                <p className="mt-2">
                  Performance/Analytical Cookies allow us to understand how visitors use our Services. They do this by collecting information about the number of visitors to the Services, what pages visitors view on our Services, and how long visitors are viewing pages on the Services. Performance/Analytical Cookies also help us measure the performance of our advertising campaigns in order to help us improve our campaigns and the Services’ content for those who engage with our advertising. For example, Google LLC (“Google”) uses cookies in connection with its Google Analytics services. Google’s ability to use and disclose information collected by Google Analytics about your visits to the Services is subject to the Google Analytics Terms of Use and the Google Privacy Policy. You have the option to opt-out of Google’s use of Cookies by visiting the Google advertising opt-out page at{" "}
                  <a href="https://www.google.com/privacy_ads.html" className="text-primary underline">
                    www.google.com/privacy_ads.html
                  </a>{" "}
                  or the Google Analytics Opt-out Browser Add-on at{" "}
                  <a href="https://tools.google.com/dlpage/gaoptout" className="text-primary underline">
                    https://tools.google.com/dlpage/gaoptout
                  </a>.
                </p>
              </li>
            </ul>
          </section>

          <p className="mt-2">
            You can decide whether or not to accept Cookies through your internet browser’s settings. Most browsers have an option for turning off the Cookie feature, which will prevent your browser from accepting new Cookies, as well as (depending on the sophistication of your browser software) allow you to decide on acceptance of each new Cookie in a variety of ways. You can also delete all Cookies that are already on your device. If you do this, however, you may have to manually adjust some preferences every time you visit our website and some of the Services and functionalities may not work.
          </p>
          {/* Information about Interest-Based Advertisements */}
          <section id="information-about-interest-based-advertisements">
            <h2 className="text-2xl font-bold">Information about Interest-Based Advertisements</h2>
            <p className="mt-4">
              We may serve advertisements and also allow third-party ad networks, including third-party ad servers, ad agencies, ad technology vendors, and research firms, to serve advertisements through the Services. These advertisements may be targeted to users who fit certain general profile categories or display certain preferences or behaviors (“Interest-Based Ads”). Information for Interest-Based Ads (including Personal Data) may be provided to us by you or derived from the usage patterns of particular users on the Services and/or services of third parties. Such information may be gathered through tracking users’ activities across time and unaffiliated properties, including when you leave the Services. To accomplish this, we or our service providers may deliver Cookies, including a file (known as a “web beacon”) from an ad network through the Services. Web beacons allow ad networks to provide anonymized, aggregated auditing, research, and reporting for us and for advertisers. Web beacons also enable ad networks to serve targeted advertisements to you when you visit other websites. Web beacons allow ad networks to view, edit, or set their own Cookies on your browser, just as if you had requested a web page from their site.
            </p>
          </section>

          {/* Data Security */}
          <section id="data-security">
            <h2 className="text-2xl font-bold">Data Security</h2>
            <p className="mt-4">
              We seek to protect your Personal Data from unauthorized access, use, and disclosure using appropriate physical, technical, organizational, and administrative security measures based on the type of Personal Data and how we are processing that data. You should also help protect your data by appropriately selecting and protecting your password and/or other sign-on mechanisms; limiting access to your computer or device and browser; and signing off after you have finished accessing your account. Although we work to protect the security of your account and other data that we hold in our records, please be aware that no method of transmitting data over the internet or storing data is completely secure.
            </p>
          </section>

          {/* Data Retention */}
          <section id="data-retention">
            <h2 className="text-2xl font-bold">Data Retention</h2>
            <p className="mt-4">
              We retain Personal Data about you for as long as necessary to provide you with our Services or to perform our business or commercial purposes for collecting your Personal Data. When establishing a retention period for specific categories of data, we consider who we collected the data from, our need for the Personal Data, why we collected the Personal Data, and the sensitivity of the Personal Data. In some cases, we retain Personal Data for longer, if doing so is necessary to comply with our legal obligations, resolve disputes or collect fees owed, or is otherwise permitted or required by applicable law, rule, or regulation. We may further retain information in an anonymous or aggregated form where that information would not identify you personally.
            </p>
            <p className="mt-4">For example:</p>
            <ul className="list-disc pl-5 space-y-4 mt-4">
              <li>We retain your profile information and credentials for as long as you have an account with us.</li>
              <li>We retain your payment data for as long as we need to process your purchase or subscription.</li>
            </ul>
          </section>

          {/* Personal Data of Children */}
          <section id="children-data">
            <h2 className="text-2xl font-bold">Personal Data of Children</h2>
            <p className="mt-4">
              As noted in the Terms of Use, we do not knowingly collect or solicit Personal Data from children under 13 years of age; if you are a child under the age of 13, please do not attempt to register for or otherwise use the Services or send us any Personal Data. If we learn we have collected Personal Data from a child under 13 years of age, we will delete that information as quickly as possible. If you believe that a child under 13 years of age may have provided Personal Data to us, please contact us at <a href="mailto:info@ecotrove.com">info@ecotrove.com</a>.
            </p>
          </section>

          {/* Other State Law Privacy Rights */}
          <section id="state-privacy-rights">
            <h2 className="text-2xl font-bold">Other State Law Privacy Rights</h2>
            <p className="mt-4 font-bold">California Resident Rights</p>
            <p className="mt-4">
              Under California Civil Code Sections 1798.83-1798.84, California residents are entitled to contact us to prevent disclosure of Personal Data to third parties for such third parties’ direct marketing purposes; in order to submit such a request, please contact us at <a href="mailto:info@ecotrove.com">info@ecotrove.com</a>.
            </p>
            <p className="mt-4">
              Your browser may offer you a “Do Not Track” option, which allows you to signal to operators of websites and web applications and services that you do not wish such operators to track certain of your online activities over time and across different websites. Our Services do not support Do Not Track requests at this time. To find out more about “Do Not Track,” you can visit <a href="https://www.allaboutdnt.com">www.allaboutdnt.com</a>.
            </p>
            <p className="mt-4 font-bold">Nevada Resident Rights</p>
            <p className="mt-4">
              Please note that we do not currently sell your Personal Data as sales are defined in Nevada Revised Statutes Chapter 603A.
            </p>
          </section>
          {/* Contact Us */}
          <section id="contact-information">
            <h2 className="text-2xl font-bold">Contact Information</h2>
            <ul className="list-disc pl-5 mt-4">
              <li>
                Email:{" "}
                <a
                  href="mailto:info@ecotrove.com"
                  className="text-primary underline"
                >
                  info@ecotrove.com
                </a>
              </li>
              <li>Phone: +1 (415) 237 - 1740</li>
              <li>
                Address: 2 Embarcadero Center, Floor 8, San Francisco, CA 94111,
                USA
              </li>
            </ul>
          </section>
        </div>
      </div>

      {/* Footer */}
      <RDCFooter />
    </div>
  );
};
