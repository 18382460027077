import { useEffect } from "react";

/**
 * The props to pass to a `RDCIFrameModal` component.
 */
export interface RDCIFrameModalProps {
  id: string;
  src: string;
  title: string;
  open: boolean;
  onClose?: () => void;
}

/**
 * Modal component that displays an iframe of the specified src.
 * @param props the props to render the component with
 * @returns a React component.
 */
export const RDCIFrameModal: React.FC<RDCIFrameModalProps> = (props) => {
  const { id, src, title, open, onClose } = props;

  useEffect(() => {
    if (open) (document.getElementById(id) as HTMLDialogElement).showModal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <dialog id={id} className="daisy-modal px-content-mobile lg:px-content">
      <div className="daisy-modal-box w-full max-w-full h-full">
        <iframe className="w-full h-5/6" src={src} title={title} />
        <div className="daisy-modal-action">
          <form method="dialog">
            {/* if there is a button in form, it will close the modal */}
            <button className="daisy-btn daisy-btn-primary" onClick={onClose}>
              Close
            </button>
          </form>
        </div>
      </div>
    </dialog>
  );
};
