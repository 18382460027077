import React, { useState, useEffect, ChangeEvent, FormEvent, KeyboardEvent, memo } from 'react';
import styled, { keyframes, css } from 'styled-components';
import { useNavigate } from 'react-router-dom';
import mixpanel from 'mixpanel-browser';
import { supabase } from '../supabaseClient';
import { UTILITIES_SERVED } from '../constants';
import background5 from '../images/background1.jpeg';
import background4 from '../images/background2.jpeg';
import background3 from '../images/background3.jpeg';
import background2 from '../images/background4.jpeg';
import background6 from '../images/background5.jpeg';
import background1 from '../images/background6.jpeg';
import { PlasmicRootProvider, PlasmicComponent } from '@plasmicapp/loader-react';
import { PLASMIC } from '../plasmic-init';
import GlobalStyles from '../GlobalStyles'; // Import the global styles

// Define the zoom-in keyframes for the background images
const zoomIn = keyframes`
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.1);
  }
`;

// Define a container for the background image slideshow
const BackgroundContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1; // Ensures it stays behind other content
  overflow: hidden;
`;

// Define a styled component for each background image with the zoom-in effect
const BackgroundImage = styled.div<{ image: string; isVisible: boolean }>`
  background-image: url(${props => props.image});
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: ${props => (props.isVisible ? 1 : 0)};
  transition: opacity 0.75s ease-in-out;

  ${(props) =>
    props.isVisible &&
    css`
      animation: ${zoomIn} 5.5s forwards;
      animation-fill-mode: forwards; // Ensures the image stays at scale 1.1
    `}
`;

const SectionContainer = styled.section`
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;
  align-items: center;
  justify-content: flex-start;
  width: 100vw;
  height: 112vh; 
  overflow: hidden;
  box-sizing: border-box;
  @media (max-width: 768px) {
    height: 105vh; 
  }

  // Ensure the background starts from the top
  position: relative;
  top: -90px; // Adjusted to position behind the Navbar initially
`;

const Title = styled.h1`
  font-size: 48px;
  color: #253D80;
  font-weight: bold;
  font-family: 'Source Sans Pro';
  text-align: center;
  padding-top: 130px;
  @media (max-width: 768px) {
    font-size: 32px;
    padding-top: 95px;
    padding-bottom: 5px;
    width: 95%
  }
`;

const Subtitle = styled.h2`
  color: #333333;
  font-size: 24px;
  font-family: 'Source Sans Pro';
  text-align: center;
  font-weight: normal;
  width: 70%;
  margin-top: -10px; // Adjusted to ensure the gap is fixed to 10px
  @media (max-width: 768px) {
    font-size: 18px;
    width: 99%;
  }
`;

const InputButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0px;
  margin-top: 15px;
  padding-top: 25px;
  height: 50px; // Set a fixed height
  @media (max-width: 768px) {
    margin-top: 15px;
    height: 40px; // Set a smaller mobile fixed height
  }
`;

const ZipCodeInput = styled.input`
  padding: 10px;
  border: 1px solid #111d57;
  border-radius: 5px;
  text-align: center;
  font-family: 'Source Sans Pro';
  font-size: 18px;
  background-color: #ffffff;
  width: 250px;
  height: 30px;

  /* Standard syntax for modern browsers, including Chrome, Firefox, and Safari */
  ::placeholder {
    color: #253D80;
    opacity: 1; /* Ensures consistent opacity */
  }

  /* Internet Explorer 10-11 */
  :-ms-input-placeholder {
    color: #253D80;
  }

  /* Microsoft Edge */
  ::-ms-input-placeholder {
    color: #253D80;
  }

  /* Webkit browsers like Safari and Chrome */
  ::-webkit-input-placeholder {
    color: #253D80;
  }
  @media (max-width: 768px) {
    font-size: 16px;
    width: 150px;
    height: 24px; // Set a smaller mobile fixed height
  }
`;

const StyledButton = styled.button`
  background-color: #284389;
  border: 1px solid #111d57;
  padding: 10px;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  font-family: 'Source Sans Pro';
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease;
  width: 250px; // Set a fixed width
  height: 50px; // Set a fixed height
  @media (max-width: 768px) {
    font-size: 15px; // 5 fonts smaller
    width: 190px; // Adjusted width
    height: 44px; // Set a smaller mobile fixed height
  }

  &:hover {
    background-color: #111d57;
  }
`;

const DisclaimerText = styled.h3`
  color: #253D80;
  font-size: 18px;
  width: 500px; // Matching button + zipcode form
  align-items: center;
  font-weight: bold;
  text-align: left;
  margin-top: -0.01px;
  padding-top: 4px;
  font-family: 'Source Sans Pro';
  @media (max-width: 768px) {
    font-size: 14px;
    width: 350px; // Matching button + zipcode form
  }
`;

const ErrorText = styled.p`
  color: red;
  font-size: 14px;
  position: absolute;
  bottom: 20px;
`;

const CommercialHeroSection = memo(() => {
  const navigate = useNavigate();
  const [zipcode, setZipcode] = useState('');
  const [error, setError] = useState('');
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const images = [background1, background2, background3, background4, background5, background6];

  useEffect(() => {
    const timer = setTimeout(() => {
      setError('');  // Clear any error after a delay, adjust or remove as needed
    }, 3000);
    return () => clearTimeout(timer);
  }, [error]);

  useEffect(() => {
    // Function to change the current image index
    const changeImage = () => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    };

    // Set an interval to change the image every 5 seconds
    const interval = setInterval(changeImage, 5000);

    // Clear the interval on component unmount
    return () => clearInterval(interval);
  }, [images.length]);

  // Additional useEffect to maintain the zoom effect until transition
  useEffect(() => {
    // Reset the transform scale to 1.1 for the visible image
    if (currentImageIndex >= 0) {
      const currentImage = document.querySelector(`.background-image-${currentImageIndex}`) as HTMLElement;
      if (currentImage) {
        currentImage.style.transform = 'scale(1.1)';
      }
    }
  }, [currentImageIndex]);

  const handleZipCodeChange = (event: ChangeEvent<HTMLInputElement>) => {
    setZipcode(event.target.value);
  };

  const handleZipCodeSubmit = async (event: FormEvent<HTMLButtonElement>) => {
    event.preventDefault();

    const zipCodePattern = /^\d{5}$/; // Ensure only 5 digits
    if (!zipCodePattern.test(zipcode)) {
      setError('Please enter a valid 5-digit zip code.');
      return;
    }

    const { data: interestUserData } = await supabase
      .from("interest-users")
      .insert([{ zipcode: zipcode }])
      .select();

    if (interestUserData && interestUserData.length > 0) {
      const recordId = interestUserData[0].id;
      localStorage.setItem("record_id", recordId.toString());
    }

    const { data: zipCodeData, error: zipError } = await supabase
      .from('pge_zipcodes_new')
      .select()
      .eq('zipcode', zipcode)
      .in('utility_name', UTILITIES_SERVED);

    if (zipError) {
      console.error('Database error:', zipError.message);
      setError('Failed to check zipcode.');
      return;
    }

    if (zipCodeData && zipCodeData.length > 0) {
      mixpanel.track('Check Zipcode for Savings clicked');
      navigate(`/get-quote?initialStage=${encodeURIComponent("connectPGE")}`);
      window.scrollTo(0, 0); // Scrolls to the top of the page after navigation
    } else {
      navigate(`/get-quote?initialStage=${encodeURIComponent("waitlistEmail")}`);
    }
  };

  const handleKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleZipCodeSubmit(event as FormEvent<HTMLButtonElement>);
    }
  };

  return (
    <SectionContainer>
      <GlobalStyles /> {/* Ensure this is used here */}
      <BackgroundContainer>
        {images.map((image, index) => (
          <BackgroundImage 
            key={index}
            className={`background-image-${index}`} // Add a unique class for each image
            image={image}
            isVisible={index === currentImageIndex}
          />
        ))}
      </BackgroundContainer>
      <Title>Fix Your Utility Expense at Low Prices</Title>
      <Subtitle>EcoTrove is your energy agent for cheaper, cleaner and predictable power bills. We work with your utility to replace monthly bills with flat rates, and automatically switch you to clean energy.</Subtitle>
        <InputButtonContainer>
        <ZipCodeInput
          type='text'
          placeholder='Your ZipCode...'
          value={zipcode}
          onChange={handleZipCodeChange}
          onKeyPress={handleKeyPress}
        />
        <StyledButton onClick={handleZipCodeSubmit}>Check energy savings</StyledButton>
      </InputButtonContainer>
      <DisclaimerText>No credit card, no commitment</DisclaimerText>
      {error && <ErrorText>{error}</ErrorText>}
      <PlasmicRootProvider loader={PLASMIC}>
        <PlasmicComponent component="hero_social_proof" />
      </PlasmicRootProvider>
    </SectionContainer>
  );
});

export default CommercialHeroSection;
