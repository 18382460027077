import styled from "styled-components";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import BottomLandingSectionGetQuote from "../components/BottomLandingSectionGetQuote";
import { PlasmicRootProvider, PlasmicComponent } from '@plasmicapp/loader-react';
import { PLASMIC } from '../plasmic-init';

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
`;

const AllBlogsPage = () => {
  return (
    <PageContainer>
      <Navbar />
        <PlasmicRootProvider loader={PLASMIC}>
            <PlasmicComponent component="battling_skyrocketing_utility_costs" />
        </PlasmicRootProvider>
      <BottomLandingSectionGetQuote />
      <Footer></Footer>
    </PageContainer>
  );
};

export default AllBlogsPage;
