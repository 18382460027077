export const redirectToTesting = (queryParams: URLSearchParams, currentPage: string) => {
    let tempCode = queryParams.get("code");
    const tempScope = queryParams.get("scope");
    const state = queryParams.get("state");
    const devWebsiteURL = process.env.REACT_APP_DEV_WEBSITE_URL;
    if (state) {
        // Decode and parse the state parameter
        let testing = state.charAt(0);
        let skipQuote = state.charAt(1);
        const email = state.slice(2);
        let newState = state;
        if (testing === "1") {
          testing = "0";
          newState = testing + skipQuote + email;
          if (tempCode !== null) {
            tempCode = encodeURIComponent(tempCode);
          }
          // Build the redirect URL for localhost
          const redirectUrl = `${devWebsiteURL}/${currentPage}?code=${tempCode}&state=${newState}&scope=${tempScope}`;
          window.location.href = redirectUrl;
        }
    }
}