import { useEffect, useState } from "react";
import { RDCSlider } from "./RDCSlider";
import { QuestionMarkCircleIcon } from "@heroicons/react/24/solid";

/**
 * Props to render a `RDCCarbonEquivalentSlider` with.
 */
export interface RDCCarbonEquivalentSliderProps {
  savingsRate: number;
  start: number;
  middle: number;
  end: number;
  defaultValue?: number;
  step1: number;
  step2: number;
}

/**
 * Slider that displays a seasonal carbon equivalent calculation based on a monthly utility value.
 * @param props the props to render the component with
 * @returns a React component.
 */
export const RDCCarbonEquivalentSlider: React.FC<RDCCarbonEquivalentSliderProps> = (props) => {
  const { savingsRate, start, middle, end, defaultValue, step1, step2 } = props;
  const [utilityCost, setUtilityCost] = useState<number>(defaultValue ?? middle);
  const [carbonEquivalent, setCarbonEquivalent] = useState<{ value: number; label: string; explainer: string }>();

  /*
   * Slider inputs typically have static steps between values, so the step on this slider is set to 1.
   * To achieve a "piecewise" slider, we do our own calculations below and store the value in a local state variable.
   * This local state variable, rather than the actual slider value, is what should be displayed.
   */
  const min = start / step1;
  const max = Math.ceil(min + (middle - start) / step1 + (end - middle) / step2);

  useEffect(() => {
    setCarbonEquivalent(calculateCarbonEquivalent());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [utilityCost]);

  /**
   * Handler for utility cost changes
   * @param n the number selected on the slider
   */
  const handleChangeUtilityCost = (n: number) => {
    // Calculate utilityCost if slider is in first range
    const range1Val = n * step1;
    if (range1Val <= middle) {
      setUtilityCost(range1Val);
    }
    // Calculate utilityCost if slider is in second range
    else {
      const range2Val = (n - middle / step1) * step2 + Math.floor(middle / step2) * step2;
      setUtilityCost(range2Val);
    }
  };

  /**
   * Helper method that formats a label for the slider value.
   * @returns the label element.
   */
  const getSliderValueLabel = (): JSX.Element => {
    return (
      <p id="slider-label" className="text-primary -mt-16">
        ${utilityCost.toLocaleString()}
      </p>
    );
  };

  /**
   * Helper method that calculates a carbon equivalent based on the current month.
   * @returns the value and associated label that should be displayed.
   */
  const calculateCarbonEquivalent = (): { value: number; label: string; explainer: string } => {
    const month = new Date().getMonth();

    switch (month) {
      // TODO: determine carbon equivalents for all months (note that months are 0-indexed)

      // November: Thanksgiving flights
      case 10:
        const flights = utilityCost * 0.025;
        return {
          value: flights,
          label: `domestic Thanksgiving flight${flights === 1 ? "" : "s"}`,
          explainer:
            "Per Carbon Trust, the average domestic flight in the US releases 40 kilograms in carbon dioxide equivalent. The average U.S. household emits 4,500 kg of carbon dioxide equivalent annually, or 28 Thanksgiving flights a year. On average, EcoTrove customers use 32% more clean energy leading to saving the equivalent of 9 domestic flights per customer, per year. Check back each month for new carbon comparisons :)",
        };
      // December: Christmas trees
      case 11:
        const trees = utilityCost * 1.01;
        return {
          value: trees,
          label: `Christmas trees${trees === 1 ? "" : ""}`,
          explainer:
            "Per Carbon Trust, growing, harvesting, transporting and disposing a real Christmas tree consumes 3.5 - 4 kilograms in carbon dioxide equivalent. The average U.S. household emits 4,500 kg of carbon dioxide equivalent annually, or 1,125 real Christmas trees a year. On average, EcoTrove customers use 32% more clean energy leading to saving the quivalent of 360 Christmas trees per customer, per year. Check back each month for new carbon comparisons :)",
        };
      // Default: 32% of energy carbon emissions
      default:
        return {
          value: 32,
          label: "percent of your energy carbon emissions",
          explainer:
            "The average U.S. household emits 4,500 kg of carbon dioxide equivalent annually and EcoTrove customers use 32% more clean energy.",
        };
    }
  };

  return (
    <div className="col-span-1">
      <div className="rounded-3xl border-2 border-neutral px-8 py-5">
        <h3 id="utility-label" className="text-center mb-10">
          What's your monthly utility bill?
        </h3>
        <RDCSlider
          aria-labelledby="slider-label"
          min={min}
          max={max}
          defaultValue={(middle - start) / step1}
          step={1}
          slots={{ valueLabel: getSliderValueLabel }}
          scale={(x) => (x <= middle ? start + x * step1 : x)}
          onChange={(_, val) => handleChangeUtilityCost(typeof val === "number" ? val : val[0])}
        />
        <div className="flex w-full justify-between px-default py-4 text-xs">
          <span>${start.toLocaleString()}</span>
          <span>${end.toLocaleString()}</span>
        </div>
      </div>
      <div className="px-5 py-10">
        <p className="text-lg lg:text-xl text-center items-center">
          Your utility bill can be up to{" "}
          <span className="text-primary text-lg lg:text-xl">
            ${Math.round(utilityCost * savingsRate * 12).toLocaleString()}
          </span>{" "}
          a year cheaper, and you could use enough clean energy to offset{" "}
          <span className="text-primary text-lg lg:text-xl">
            {carbonEquivalent?.value} {carbonEquivalent?.label}
          </span>
          .{" "}
          <div
            className="daisy-tooltip daisy-tooltip-secondary daisy-tooltip-left lg:daisy-tooltip-top text-secondary"
            data-tip={carbonEquivalent?.explainer}>
            <QuestionMarkCircleIcon className="hero-icon hero-icon-sm -mb-1" />
          </div>
        </p>
      </div>
    </div>
  );
};
