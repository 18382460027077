import { useNavigate } from "react-router-dom";
import { RDCCheckAvailabilityForm } from "./RDCCheckAvailabilityForm";
import { GET_QUOTE_ROUTE } from "../../constants";

/**
 * Form component that prompts a user to check their ZIP code availability at the end of scrolling.
 * @param props the props to render the component with
 * @returns a React component.
 */
export const RDCFinishedScrollingForm: React.FC = () => {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col w-full items-center bg-accent text-center px-content-mobile lg:px-content py-content-mobile lg:py-8">
      <h1 className="leading-none">
        Finished scrolling?
        <br />
        Start saving today.
      </h1>
      <p className="lg:w-1/3 mt-4">
        Allow EcoTrove to become your energy concierge to make your power bills cheaper, greener, and more predictable.
      </p>
      <div className="lg:w-1/3 mt-12">
        <RDCCheckAvailabilityForm
          data-tests={{ zipCode: "zipcode-finished-scrolling" }}
          onSubmit={(data) => navigate(`${GET_QUOTE_ROUTE}?zipCode=${encodeURIComponent(data.zipCode)}`)}
        />
      </div>
    </div>
  );
};
