import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { RDCAlertBanner, RDCPricingCard, RDCSavingsWaterfall, TitleBadge } from "../components";
import mixpanel from "mixpanel-browser";
import { supabase } from "../../supabaseClient";
import { CUSTOMIZE_QUOTE_ROUTE, CREATE_ACCOUNT_ROUTE } from "../../constants";

export const RDCShowQuotePage: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [errorAlert, setErrorAlert] = useState<string>("");
  const [pricingOptions, setPricingOptions] = useState<any[]>([]);
  const [currentMonthlyBill, setCurrentMontlyBill] = useState<number>();
  const [maxSaverMonthlyBill, setMaxSaverMonthlyBill] = useState<number>();
  const [savings, setSavings] = useState<number | null>(null);
  const [percentSavings, setPercentSavings] = useState<number | null>(null);
  const [isMUD, setIsMUD] = useState(false);
  const [insufficientData, setInsufficientData] = useState(false);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const taskId = queryParams.get("taskId");
  const email = queryParams.get("email");
  const navigate = useNavigate();

  useEffect(() => {
    mixpanel.track("showQuote");
  }, []);

  useEffect(() => {
    const fetchResults = async () => {
      if (!taskId || !email) {
        setErrorAlert("Error: Session Expired.");
        setLoading(false);
        return;
      }

      const { data, error } = await supabase.from("task-results").select("result").eq("task_id", taskId).single();

      if (error) {
        setErrorAlert("Error: Unable to fetch task results.");
        setLoading(false);
        return;
      }

      const result = data?.result || {};
      if (result.message === "Insufficient Data") {
        let pricingOptions = generatePricingOptions({}, {}, 0);
        setInsufficientData(true);
        console.log("is insufficient data: ", insufficientData);
        pricingOptions = addOnClickHandlers(pricingOptions, email, supabase, navigate, setErrorAlert);
        setPricingOptions(pricingOptions);
        setLoading(false);
      } else {
        const parsed = {
          message: result.message,
          monthlyQuote: result.monthlyQuote,
          renewablePercents: result.averageRenewablePercent,
          averageBill: result.averageBill,
          highestBill: result.highestBill,
          gasUsageByMonth: result.gasUsageByMonth,
          electricityUsageByMonth: result.electricityUsageByMonth,
          isMUD: result.isMUD,
        };
        setIsMUD(isMUD);
        console.log("is mud: ", parsed.isMUD);

        // Dynamically generate pricing options
        let pricingOptions = generatePricingOptions(parsed.monthlyQuote, parsed.renewablePercents, parsed.averageBill);
        // Add onClick handlers
        pricingOptions = addOnClickHandlers(pricingOptions, email, supabase, navigate, setErrorAlert);
        setPricingOptions(pricingOptions);

        // Calculate savings and percentSavings
        const maxSaverPrice = pricingOptions.find((option) => option.title === "Max Saver")?.price;
        const maxSaverMonthlyPrice = maxSaverPrice ? parseFloat(maxSaverPrice.replace(/[^\d.]/g, "")) : null;

        if (maxSaverMonthlyPrice && parsed.averageBill) {
          const monthlySavings = parsed.averageBill * 1.035 - maxSaverMonthlyPrice;
          const savings = Math.round(monthlySavings * 12); // Annual savings
          const percentSavings = Math.round((monthlySavings / parsed.averageBill) * 100);

          setSavings(savings);
          setPercentSavings(percentSavings);

          // Set bill states to be used in graph
          setCurrentMontlyBill(parsed.averageBill);
          setMaxSaverMonthlyBill(maxSaverMonthlyPrice);
        }

        setLoading(false);
      }
    };

    fetchResults();

    const quoteURL = window.location.href;

    //upload quote url to supabase if we want to send it to user later
    if (process.env.REACT_APP_TESTING !== "true") {
      const supabasePromise = supabase.from("user-profiles").update({ quote_url: quoteURL }).eq("email", email);
      supabasePromise.then(({ error }) => {
        if (error) {
          console.error("Error:", error.message);
        } else {
          console.log("Successfully uploaded to Supabase.");
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskId, email]);

  const generatePricingOptions = (monthlyQuote: any, renewablePercents: any, averageBill: number) => {
    const options = [];

    const titleBadges: Record<string, TitleBadge> = {
      maxSaver: { text: "Maximize savings", type: "neutral" },
      greenSaver: { text: "Use 100% clean energy", type: "secondary" },
    };

    const getPriceClarification = (title: string, price: number) => {
      switch (title) {
        case "Max Saver":
          return `Replaces ~$${(price * 1.035).toFixed(0)}/mo. average PG&E bills`;
        case "Green Saver":
          return `Replaces ~$${(price * 1.035).toFixed(0)}/mo. average PG&E bills`;
        case "Free Insights":
          if (insufficientData) {
            return `Continue paying PG&E`;
          } else {
            return `Continue paying PG&E an average ~$${(price * 1.035).toFixed(0)}/mo.`;
          }
        default:
          return "";
      }
    };

    // Static descriptions and features for each plan
    const descriptions = {
      maxSaver: "Our cheapest plan. Save while using at least 50% green energy.",
      greenSaver: "Ideal for eco-conscious individuals who want to use 100% clean energy.",
      freeInsights: "Best for those who want to learn about how much they can save each month.",
    };

    const staticFeatures = {
      maxSaver: [
        "{annualSavings}",
        "Monthly energy insights",
        "{percentRenewable}% renewable energy",
        "EcoTrove pays your utility bill",
      ],
      greenSaver: [
        "{annualSavings}",
        "Monthly energy insights",
        "{percentRenewable}% renewable energy",
        "EcoTrove pays your utility bill",
      ],
      freeInsights: [
        "Monthly energy insights",
        "No annual savings",
        "No renewable increase",
        "You pay your utility bill",
      ],
    };

    const createOption = (
      title: string,
      titleBadge: TitleBadge | undefined,
      price: string,
      percentRenewable: number,
      description: string,
      features: string[],
      isHighlighted: boolean
    ) => {
      const priceValue = parseFloat(price.replace(/[^\d.]/g, "")); // Extract numerical value from price string
      const savings = averageBill * 1.035 - priceValue; // Calculate savings
      const annualSavings = savings * 12;

      let savingsText = "";
      if (savings < 0 && title === "Green Saver") {
        savingsText = `$${Math.abs(savings).toFixed(0)} more a month than PG&E to use 100% green energy`;
      } else {
        savingsText = `Save $${annualSavings.toFixed(0)} annually or $${(annualSavings / 12).toFixed(0)} monthly`;
      }

      const updatedFeatures = features.map((feature) =>
        feature.replace("{percentRenewable}", percentRenewable.toString()).replace("{annualSavings}", savingsText)
      );

      return {
        title,
        titleBadge,
        priceClarification: getPriceClarification(title, averageBill),
        price,
        savings: `$${savings.toFixed(2)}`, // Format savings to 2 decimal places
        description,
        features: updatedFeatures,
        ctaText: "Get Started",
        isHighlighted,
      };
    };

    // Extract saver and super plans with renewable percents
    if (monthlyQuote.saver && monthlyQuote.super) {
      options.push(
        createOption(
          "Max Saver",
          titleBadges.maxSaver,
          `$${monthlyQuote.saver}/mo`,
          renewablePercents.saver || 0, // Use renewablePercents for Max Saver
          descriptions.maxSaver,
          staticFeatures.maxSaver,
          true
        )
      );
      options.push(
        createOption(
          "Green Saver",
          titleBadges.greenSaver,
          `$${monthlyQuote.super}/mo`,
          renewablePercents.super || 0, // Use renewablePercents for Green Saver
          descriptions.greenSaver,
          staticFeatures.greenSaver,
          false
        )
      );
    } else if (monthlyQuote.super) {
      options.push(
        createOption(
          "Max Saver",
          titleBadges.maxSaver,
          `$${monthlyQuote.super}/mo`,
          renewablePercents.super || 0, // Use renewablePercents for Max Saver
          descriptions.maxSaver,
          staticFeatures.maxSaver,
          true
        )
      );
    } else if (monthlyQuote.saver) {
      options.push(
        createOption(
          "Max Saver",
          titleBadges.maxSaver,
          `$${monthlyQuote.saver}/mo`,
          renewablePercents.saver || 0, // Use renewablePercents for Max Saver
          descriptions.maxSaver,
          staticFeatures.maxSaver,
          true
        )
      );
    } else if (monthlyQuote.pge) {
      options.push(
        createOption(
          "Max Saver",
          titleBadges.maxSaver,
          `$${monthlyQuote.pge}/mo`,
          renewablePercents.pge || 0, // Use renewablePercents for Max Saver
          descriptions.maxSaver,
          staticFeatures.maxSaver,
          true
        )
      );
    }

    // Add Free Tier option (always included)
    options.push(
      createOption(
        "Free Insights",
        undefined,
        "$0/mo",
        0, // No renewable percent for Free Insights
        descriptions.freeInsights,
        staticFeatures.freeInsights,
        false
      )
    );

    return options;
  };

  const addOnClickHandlers = (
    pricingOptions: any[],
    email: string | null,
    supabase: any,
    navigate: any,
    setErrorAlert: (message: string) => void
  ) => {
    return pricingOptions.map((option) => {
      if (option.title === "Max Saver" || option.title === "Green Saver") {
        return {
          ...option,
          onClick: async () => {
            mixpanel.track(`Choose plan: ${option.title}`);
            if (!email) {
              setErrorAlert("Error: Session Expired.");
              return;
            }
            console.log("CLICKED");

            const monthlyPrice = parseFloat(option.price.replace(/[^\d.]/g, ""));
            console.log(monthlyPrice);
            console.log(option.title);
            const { error } = await supabase
              .from("user-profiles")
              .update({ monthly_subscription: monthlyPrice, accepted_quote: true, subscription_type: option.title })
              .eq("email", email);

            if (error) {
              console.error("Error:", error.message);
            } else {
              console.log("Successfully uploaded to Supabase.");
            }

            const params = new URLSearchParams({ email });
            const customizeQuoteUrl = `${CUSTOMIZE_QUOTE_ROUTE}?${params.toString()}`;
            navigate(customizeQuoteUrl);
          },
        };
      }

      if (option.title === "Free Insights") {
        return {
          ...option,
          onClick: async () => {
            mixpanel.track(`Choose plan: ${option.title}`);
            if (!email) {
              setErrorAlert("Error: Session Expired.");
              return;
            }

            const { error } = await supabase.from("user-profiles").update({ free_monitoring: true }).eq("email", email);

            if (error) {
              console.error("Error:", error.message);
            } else {
              console.log("Successfully uploaded to Supabase.");
            }

            const params = new URLSearchParams({ email });
            const createAccountUrl = `${CREATE_ACCOUNT_ROUTE}?${params.toString()}`;
            navigate(createAccountUrl);
          },
        };
      }

      return option; // Return unchanged option if it doesn't match any conditions
    });
  };

  return (
    <div className="tw-preflight min-h-dvh">
      {/* Error banner */}
      {errorAlert && (
        <div className="w-full">
          <RDCAlertBanner type="error" message={errorAlert} onClickClose={() => setErrorAlert("")} />
        </div>
      )}
      {/* Main content */}
      <div className="flex flex-col justify-center items-center p-content-mobile lg:p-content">
        {loading ? (
          <div className="daisy-loading daisy-loading-spinner text-primary"></div>
        ) : (
          <>
            {savings && percentSavings ? (
              <div className="flex flex-col w-full items-center text-center gap-4">
                <h1 className="text-sm lg:text-lg text-black">Congratulations! 🎉</h1>
                <h1 className="text-xl lg:text-2xl font-bold text-black">
                  {isMUD ? (
                    <div>
                      You can save up to{" "}
                      <span className="text-2xl font-bold text-primary">
                        ${savings} ({percentSavings}%)
                      </span>{" "}
                      per year with EcoTrove's fixed plans
                    </div>
                  ) : (
                    <div>
                      You can save up to <span className="text-2xl font-bold text-primary">${savings}</span> per year
                      with EcoTrove's fixed plans
                    </div>
                  )}
                </h1>
                {isMUD ? (
                  <p className="w-2/3">
                    We see that you recently moved to a new address - congratulations! We are happy to offer you a quote
                    based on our proprietary forecasts. Please understand that our forecasts might be inaccurate. If you
                    use less energy than we predicted, you will receive a discount on your subscription. If you use more
                    energy than predicted, we will offer you a new quote.
                  </p>
                ) : (
                  <h2 className="text-lg text-black text-center">
                    Switch to our flat-rate plans and you can save up to{" "}
                    <span className="text-lg text-primary">{percentSavings}%</span> while protecting yourself from
                    rising costs.
                  </h2>
                )}
                {currentMonthlyBill && maxSaverMonthlyBill && (
                  <div className="my-4">
                    <RDCSavingsWaterfall
                      isMUD={isMUD}
                      monthlyBillBefore={currentMonthlyBill}
                      monthlyBillAfter={maxSaverMonthlyBill}
                    />
                  </div>
                )}
              </div>
            ) : insufficientData ? (
              <div>
                {" "}
                Your account is too new for us to forecast your energy demand! Start tracking your energy usage in our
                Free Insights plan and get notified as soon as you are eligible for a paid plan.{" "}
              </div>
            ) : (
              <div>Continue saving!</div>
            )}

            <div className="daisy-card daisy-shadow-lg daisy-max-w-lg daisy-p-6">
              <div className="daisy-card-body">
                <div className="flex flex-col md:flex-row flex-wrap gap-6 justify-center mt-2">
                  {pricingOptions.map((option, index) => (
                    <RDCPricingCard
                      key={index}
                      title={option.title}
                      titleBadge={option.titleBadge}
                      price={option.price}
                      priceClarification={option.priceClarification}
                      description={option.description}
                      features={option.features}
                      ctaText={option.ctaText}
                      isHighlighted={option.isHighlighted}
                      onClick={option.onClick}
                    />
                  ))}
                </div>
              </div>
            </div>
            {/* Footer Message */}
            <div className="mt-8 px-6 w-full md:w-[1200px] mx-auto">
              {pricingOptions.length > 0 && (
                <p className="text-left text-sm text-gray-600 leading-relaxed whitespace-normal">
                  If you select a paid plan, you will stop paying PG&E and authorize EcoTrove to pay your PG&E
                  electricity and gas (if applicable) bills, secure discounts, and request shifts to renewable energy on
                  your behalf.
                  <br />
                  <br />
                  EcoTrove will bill you in{" "}
                  <span className="daisy-text-primary">
                    {pricingOptions.find((option) => option.title === "Max Saver")?.price || "$0/mo"}
                  </span>{" "}
                  (incl. taxes) monthly installments for the next 12 months and cover your next 12 PG&E bills. Unless
                  you select a later start date on the next step, your subscription will begin tomorrow. You might be
                  rewarded discounts for decreases in energy usage and offered a higher subscription price to accept or
                  reject if your usage increases substantially.
                </p>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};
