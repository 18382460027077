import { useState, useEffect, useRef } from "react";
import { RDCAlertBanner } from "../components";
import { handleStartStripe } from "../../startStripe"; //TODO remove one layer of this when we move the file 

/**
 * Page for selecting the subscription start date. The start date can be up to a month from now,
 * and redirects to DocuSign if the user hasn't chosen a date within 30 seconds. If a user doesn't choose a start date, 
 * the default date is today. 
 * @returns A React `FunctionComponent`.
 */
export const RDCCustomizeQuote: React.FC = () => {
  const today = new Date();
  const maxDate = new Date(today.getFullYear(), today.getMonth() + 1, today.getDate());
  const [selectedDate, setSelectedDate] = useState<string>(today.toISOString().split("T")[0]);
  const [errorAlert, setErrorAlert] = useState<string>("");

  const timeoutIdRef = useRef<NodeJS.Timeout | undefined>(undefined);
  const urlParams = new URLSearchParams(window.location.search);
  const email = urlParams.get("email");

  const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedDate(event.target.value);
  };

  const handleContinue = () => {
    if (!email) {
        setErrorAlert("Error: Session Expired.");
        return;
      }
    clearTimeout(timeoutIdRef.current);
    const startDate = new Date(selectedDate);
    handleStartStripe(email, startDate);
  };

  useEffect(() => {
    const resetTimer = () => {
      if (timeoutIdRef.current) {
        clearTimeout(timeoutIdRef.current); // Clear the previous timeout if any
      }
      timeoutIdRef.current = setTimeout(() => {
        console.log('User has been inactive for 30 seconds.');
        handleContinue();
      }, 30000); // 30 seconds in milliseconds
    };
    resetTimer();

    // Cleanup function to clear timeout on component unmount or before next effect run
    return () => {
      if (timeoutIdRef.current) {
        clearTimeout(timeoutIdRef.current);
      }
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); 

  return (
    <div className="tw-preflight bg-base-100 min-h-screen relative">
      {/* Error banner placed at the top */}
      {errorAlert && (
        <div className="absolute top-0 w-full">
          <RDCAlertBanner
            type="error"
            message={errorAlert}
            onClickClose={() => setErrorAlert("")}
          />
        </div>
      )}

      {/* Main content */}
      <div className="flex justify-center items-center min-h-screen">
        <div className="daisy-card w-full max-w-lg bg-white rounded-lg shadow-lg p-6">
          <h1 className="text-2xl font-bold text-primary mb-4">Your Subscription Start Date</h1>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleContinue();
            }}
            className="flex flex-col gap-4"
          >
            {/* Date Picker */}
            <label htmlFor="startDate" className="text-sm text-dark">
              Select your desired subscription start date:
            </label>
            <input
              type="date"
              id="startDate"
              name="startDate"
              value={selectedDate}
              onChange={handleDateChange}
              min={today.toISOString().split("T")[0]}
              max={maxDate.toISOString().split("T")[0]}
              className="input input-bordered w-full"
              aria-label="Start Date"
            />
            <button className="daisy-btn daisy-btn-primary" type="submit">
              Continue
            </button>
          </form>
          <p className="text-sm text-neutral-content mt-6">
            Begin saving today! Your 12-month EcoTrove subscription will start on the selected date,
            allowing EcoTrove to pay your next 12 PG&E bills. Cancel anytime.
          </p>
          <p className="text-sm text-neutral-content mt-2">
            We selected the earliest possible start date. You can choose another if desired.
          </p>
        </div>
      </div>
    </div>
  );
};
