import { CustomerReview } from "../services/CustomerReviewService";
import { RDCTrustPilotStars } from "./RDCTrustPilotStars";

/**
 * Props to pass to a `RDCCustomerReviewCard` component.
 */
export interface RDCCustomerReviewCardProps {
  review: CustomerReview;
}

/**
 * Card component that displays a customer review.
 * @param props the props to render the component with.
 * @returns a React component.
 */
export const RDCCustomerReviewCard: React.FC<RDCCustomerReviewCardProps> = (props) => {
  const { link, name, location, imgUrl, numStars, textContent, dateEpochMS, savings = "$300" } = props.review; // Default savings to "$300" if not provided
  return (
    <a
      href={link}
      target="_blank"
      rel="noopener noreferrer"
      className="flex flex-col gap-5 w-80 lg:w-128 rounded-3xl bg-base-100 p-5">
      {/* Person & rating */}
      <div className="flex items-start gap-4">
        <div className="flex justify-center items-center daisy-mask daisy-mask-circle size-12 bg-secondary">
          {imgUrl ? <img src={imgUrl} alt="profile" /> : <p className="align-bottom">{name[0]?.toUpperCase()}</p>}
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-3 items-center w-full">
          <p className="col-span-1 lg:col-span-2 font-semibold">{[name, location].join(", ")}</p>
          <p className="flex lg:hidden col-span-1">{savings}</p>
          <p className="col-span-1 lg:justify-self-end">{new Date(dateEpochMS).toLocaleDateString()}</p>
          <p className="hidden lg:flex col-span-3">{savings}</p>
          <span className="col-span-1 lg:col-span-2 mt-2">
            <RDCTrustPilotStars rating={numStars} />
          </span>
        </div>
      </div>
      {/* Review text */}
      <span className="grow text-neutral-content">{textContent}</span>
    </a>
  );
};
