import { RDCEcoTroveLogoLink } from "./RDCEcoTroveLogoLink";
import {
  ACTION_LINKS,
  ECOTROVE_EMAIL_INFO,
  MAIN_MENU_LINKS,
  PRIVACY_POLICY_ROUTE,
  TERMS_OF_SERVICE_ROUTE,
} from "../../constants";
import { Utils } from "../../utils";
import { Link } from "../../types";
import FacebookLogo from "../images/logos/FacebookLogo.svg";
import InstagramLogo from "../images/logos/InstagramLogo.svg";
import TwitterLogo from "../images/logos/TwitterLogo.svg";
import LinkedInLogo from "../images/logos/LinkedInLogo.svg";
import TikTokLogo from "../images/logos/TikTokLogo.svg";
import { EnvelopeIcon } from "@heroicons/react/24/solid";

const FOOTER_NAV_LINKS = [...MAIN_MENU_LINKS, ACTION_LINKS[0]];

const FOOTER_LINKS: Link[] = [
  { label: "Privacy Policy", url: PRIVACY_POLICY_ROUTE },
  { label: "Terms of Service", url: TERMS_OF_SERVICE_ROUTE },
  // TODO: cookies settings
];

const SOCIAL_MEDIA_LINKS: Link[] = [
  {
    icon: <EnvelopeIcon className="hero-icon hero-icon-sm" />,
    ariaLabel: "email",
    url: `mailto:${ECOTROVE_EMAIL_INFO}`,
  },
  { icon: <img src={FacebookLogo} alt="Facebook" />, url: "https://www.facebook.com/people/EcoTrove/61553413146538/" },
  { icon: <img src={InstagramLogo} alt="Instagram" />, url: "https://www.instagram.com/ecotrove_energy/" },
  { icon: <img src={TwitterLogo} alt="Twitter/X" />, url: "https://x.com/ecotrove_energy" },
  { icon: <img src={LinkedInLogo} alt="LinkedIn" />, url: "https://www.linkedin.com/company/ecotrove" },
  { icon: <img src={TikTokLogo} alt="TikTok" />, url: "https://www.tiktok.com/@ecotrove_energy" },
];

/**
 * Footer component that should be rendered on every page.
 * @returns a React component.
 */
export const RDCFooter: React.FC = () => {
  return (
    <footer className="px-content-mobile lg:px-content">
      <div className="flex flex-col lg:flex-row items-center py-12">
        {/* Logo */}
        <span className="-ml-2">
          <RDCEcoTroveLogoLink />
        </span>
        {/* Center menu */}
        <div className="grow flex justify-center my-8 lg:my-0">
          <ul className="daisy-menu daisy-menu-md lg:daisy-menu-horizontal">
            {Utils.getListElementsFromLinks(FOOTER_NAV_LINKS, "")}
          </ul>
        </div>
        {/* Social media */}
        <div className="flex-none flex justify-center -mr-2">
          <ul className="flex items-center">
            {Utils.getListElementsFromLinks(SOCIAL_MEDIA_LINKS, "daisy-btn daisy-btn-ghost")}
          </ul>
        </div>
      </div>
      <hr />
      <div className="flex flex-col lg:flex-row py-8 text-3xl lg:text-sm">
        {/* Left corner (top item on mobile screens) */}
        <div className="flex flex-col items-center lg:items-start">
          <a className="flex items-center gap-x-default" href={`mailto:${ECOTROVE_EMAIL_INFO}`}>
            <EnvelopeIcon className="hero-icon hero-icon-xs" />
            <p className="daisy-link daisy-link-hover text-sm">{ECOTROVE_EMAIL_INFO}</p>
          </a>
          <div className="flex items-center gap-x-default">
            <p className="text-sm">&copy;</p>
            <p className="text-sm">{new Date().getFullYear()} EcoTrove. All rights reserved.</p>
          </div>
        </div>
        <div className="grow" />
        {/* Right corner (bottom item on mobile screens) */}
        <ul className="flex flex-col lg:flex-row mt-4 lg:mt-0 items-center gap-x-4 -mr-2 lg:-mb-5">
          {Utils.getListElementsFromLinks(FOOTER_LINKS, "daisy-btn daisy-btn-sm daisy-btn-ghost")}
        </ul>
      </div>
    </footer>
  );
};
