import { useSearchParams } from "react-router-dom";
import { SubmitHandler, useForm } from "react-hook-form";
import { SavingsType, UtilityProvider } from "../../types";
import { Utils } from "../../utils";
import {
  CheckAvailabilityHeroFieldValues,
  RDCAlertBanner,
  RDCCheckAvailabilityForm,
  RDCCheckBoxInput,
  RDCFooter,
  RDCIFrameModal,
  RDCNavbar,
  RDCTextWithButtonInput,
} from "../components";
import {
  SAVINGS_TYPE_SCHEMA,
  EMAIL_REGEX,
  ZIP_CODE_REGEX,
  UTILITIES_SERVED,
  PRIVACY_POLICY_ROUTE,
  TERMS_OF_SERVICE_ROUTE,
  PGE_OAUTH_REDIRECT_ROUTE,
} from "../../constants";
import PGELogo from "../images/logos/PGELogo.svg";
import { MouseEvent, useEffect, useState } from "react";
import { supabase } from "../../supabaseClient";
import { z } from "zod";
import mixpanel from "mixpanel-browser";
import { Helmet } from "react-helmet";

// TODO: compare w/ both mocks and Ali's design

interface GetQuoteFormValues {
  savingsType: SavingsType;
  email: string;
  privacyPolicy: boolean;
  termsOfService: boolean;
}

interface JoinEmailListValues {
  email: string;
}

const STAGE_SCHEMA = z.enum(["zipCode", "provider", "waitList", "emailList"]);

const GET_QUOTE_VALIDATIONS: Record<keyof GetQuoteFormValues, Object> = {
  savingsType: { required: true },
  email: { required: true, pattern: EMAIL_REGEX },
  privacyPolicy: { required: true },
  termsOfService: { required: true },
};

const JOIN_EMAIL_LIST_VALIDATIONS: Record<keyof JoinEmailListValues, Object> = {
  email: { required: true, pattern: EMAIL_REGEX },
};

const PROVIDER_CONFIG: Record<
  UtilityProvider,
  { displayName: string; displayUrl: string; url: { location: string; params: Record<string, string> }; logo: string }
> = {
  pge: {
    displayName: "PG&E",
    displayUrl: "pge.com",
    url: {
      location: "https://sharemydata.pge.com/myAuthorization",
      params: {
        client_id: "8fc92047bb9b446d9ed5de3de21e8600",
        response_type: "code",
      },
    },
    logo: PGELogo,
  },
};

/**
 * Page that prompts a user to enter their utility email to link to their account and retrieve a quote.
 * @returns a React component.
 */
export const RDCGetQuotePage: React.FC = () => {
  const [errorAlert, setErrorAlert] = useState<string>("");

  // Query param state
  const [searchParams, setSearchParams] = useSearchParams();
  const [provider, setProvider] = useState<UtilityProvider | undefined>();
  const [stage, setStage] = useState<z.infer<typeof STAGE_SCHEMA>>("zipCode");

  // Check boxes state
  const [privacyModalOpen, setPrivacyModalOpen] = useState<boolean>(false);
  const [termsModalOpen, setTermsModalOpen] = useState<boolean>(false);

  useEffect(() => {
    mixpanel.track("getQuote");
  }, []);

  /* ---- FORMS ---- */

  const getQuote = useForm<GetQuoteFormValues>({
    defaultValues: {
      savingsType: SAVINGS_TYPE_SCHEMA.safeParse(searchParams.get("savingsType")).data ?? "household",
    },
  });

  const joinEmailList = useForm<JoinEmailListValues>();

  /* ---- HANDLERS ---- */

  /**
   * Submit handler for the check ZIP code form (to check if user's ZIP code is supported).
   * @param param0 the form data
   */
  const handleSubmitCheckZipCode: SubmitHandler<CheckAvailabilityHeroFieldValues> = async ({ zipCode }) => {
    mixpanel.track("Check ZIP code for availability");
    try {
      const { data: interestUserData } = await supabase
        .from("interest-users")
        .insert([{ zipcode: zipCode }])
        .select();
      // Set record_id in local storage
      if (interestUserData && interestUserData.length > 0) {
        const recordId = interestUserData[0].id;
        localStorage.setItem("record_id", recordId.toString());
      }
      // Check if ZIP code is currently served
      const { data: zipCodeData, error: zipError } = await supabase
        .from("pge_zipcodes_new")
        .select()
        .eq("zipcode", zipCode)
        .in("utility_name", UTILITIES_SERVED);
      // If an error was returned, throw it. This will be handled and displayed as an error message below
      if (zipError) {
        throw new Error(zipError?.message);
      }
      // Navigate to get quote page
      if (zipCodeData && zipCodeData.length > 0) {
        setProvider("pge");
        searchParams.set("zipCode", zipCode);
        searchParams.set("stage", "provider");
      } else {
        searchParams.delete("zipCode");
        searchParams.set("stage", "waitList");
      }
      setSearchParams(searchParams);
    } catch (error) {
      // Catch errors and display an error alert
      setErrorAlert("An error occurred while checking ZIP code. Please try again later.");
      window.scrollTo(0, 0);
      return;
    }
  };

  /**
   * Submit handler for the get quote form (if user's ZIP code is supported).
   * @param param0 the form data
   */
  const handleSubmitGetQuote: SubmitHandler<GetQuoteFormValues> = async ({ savingsType, email }) => {
    mixpanel.track("Log in to utility provider to get quote");
    const { error } = await supabase
      .from("terms-and-conditions")
      .insert([{ email, privacy_checked: true, terms_checked: true }])
      .select();

    const { error: errorTerms } = await supabase
      .from("user-profiles")
      .update({ terms_complete: true })
      .eq("email", email);

    if (error || errorTerms) {
      console.error("error uploading row for terms and conditions completion for: ", email);
    }

    const { location, params } = PROVIDER_CONFIG[provider!].url;
    const url = new URL(location);
    const testing = process.env.REACT_APP_WEBSITE_URL === "https://ecotrove.com" ? "0" : "1";
    const userType = savingsType === "household" ? "0" : "1";
    const state = testing + userType + email;
    url.search = new URLSearchParams({
      ...params,
      state,
      redirect_uri: `https://ecotrove.com${PGE_OAUTH_REDIRECT_ROUTE}`,
    }).toString();
    window.location.href = url.href;
  };

  /**
   * Submit handler for the join email list form (if user's ZIP code is not supported).
   * @param param0 the form data
   */
  const handleSubmitJoinEmailList: SubmitHandler<JoinEmailListValues> = async ({ email }) => {
    mixpanel.track("Join waitlist to be notified when EcoTrove is available");
    try {
      const recordId = localStorage.getItem("record_id");
      if (recordId) {
        const { error } = await supabase.from("interest-users").update({ email }).eq("id", parseInt(recordId)).select();
        if (error) throw new Error(`Error updating data: ${JSON.stringify(error)}`);
        searchParams.set("stage", "emailList");
        setSearchParams(searchParams);
      } else throw new Error("No zipcode found for user");
    } catch (e) {
      setErrorAlert("An error occurred while joining email list.");
    }
  };

  /**
   * Side effect that sets state variables based on query params.
   */
  useEffect(() => {
    const zipCodeParam = searchParams.get("zipCode") ?? "";
    const stageParam = STAGE_SCHEMA.safeParse(searchParams.get("stage"));
    // If ZIP code param is  set, submit the check ZIP code form
    // This case is typically a param set by another page
    if (ZIP_CODE_REGEX.test(zipCodeParam)) {
      handleSubmitCheckZipCode({ zipCode: zipCodeParam });
    }
    // If stage param is set, update the stage state
    if (stageParam.data) {
      setStage(stageParam.data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  return (
    <div className="tw-preflight flex flex-col min-h-dvh">
      <Helmet>
        <title>EcoTrove | Get Quote</title>
        <meta
          name="description"
          content="Get a personalized quote to replace your utility bills with cheaper, predictable, clean energy."
        />
        <meta
          name="keywords"
          content="utility account, EcoTrove quote, PG&E account, replace your utility bills, save on your utility bills, fixed-price energy, clean energy subscription, lower energy bills, EcoTrove, green power"
        />
        {/* Open Graph Meta Tags */}
        <meta property="og:title" content="Get a Quote - EcoTrove" />
        <meta
          property="og:description"
          content="Get a personalized quote to replace your utility bills with cheaper, predictable, clean energy. Start saving today!"
        />
        <meta property="og:image" content="https://i.postimg.cc/FKQJMKhq/Screenshot-2024-12-02-at-8-52-36-AM.png" />
        <meta property="og:url" content="https://ecotrove.com/get-quote" />
        <meta property="og:type" content="website" />

        {/* Twitter Card Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Get a Quote - EcoTrove" />
        <meta
          name="twitter:description"
          content="Get a personalized quote to replace your utility bills with cheaper, predictable, clean energy. Start saving today!"
        />
        <meta name="twitter:image" content="https://i.postimg.cc/FKQJMKhq/Screenshot-2024-12-02-at-8-52-36-AM.png" />
      </Helmet>
      {/* Error banner that can be set by subcomponents */}
      {errorAlert ? (
        <RDCAlertBanner type="error" message={errorAlert} onClickClose={() => setErrorAlert("")} />
      ) : undefined}
      <RDCNavbar />

      <div className="grow grid grid-cols-1 lg:grid-cols-2 gap-8 place-items-center bg-secondary lg:px-content pt-24 pb-8">
        {/* Left column */}
        <div className="col-span-1 flex flex-col place-items-center w-full rounded-3xl bg-base-100 py-12 px-content-mobile lg:px-content">
          {/* Check availability */}
          {stage === "zipCode" && [
            <h1 key="title" className="text-xl lg:text-2xl mb-10">
              Check your ZIP code's availability
            </h1>,
            <RDCCheckAvailabilityForm
              data-tests={{ zipCode: "zipcode-quote" }}
              key="form"
              onSubmit={handleSubmitCheckZipCode}
            />,
          ]}

          {/* Waitlist */}
          {stage === "waitList" && [
            <h1 key="title" className="text-xl lg:text-2xl mb-10">
              We will start serving your area soon
            </h1>,
            <form key="form" className="space-y-8" onSubmit={joinEmailList.handleSubmit(handleSubmitJoinEmailList)}>
              <p>Please enter your email and we will notify you as soon as service is available.</p>
              {/* Email */}
              <div className="flex flex-col space-y-4">
                <label htmlFor="email">Email</label>
                <div className="flex w-full justify-between items-center gap-x-4">
                  <RDCTextWithButtonInput
                    data-test="email-waitlist"
                    name="email"
                    placeholder="Enter your email"
                    invalid={Boolean(joinEmailList.formState.errors.email)}
                    register={joinEmailList.register}
                    validations={JOIN_EMAIL_LIST_VALIDATIONS.email}
                    buttonText={`Join email list`}
                    buttonType="primary"
                  />
                </div>
                <p className="daisy-label daisy-label-text text-error" role="alert">
                  {joinEmailList.formState.errors.email ? "Please enter a valid email" : ""}
                </p>
              </div>
            </form>,
          ]}

          {/* Email list */}
          {stage === "emailList" && [
            <h1 key="title" className="text-xl lg:text-2xl mb-10">
              Thank you!
            </h1>,
            <a key="home" className="flex-none flex daisy-btn daisy-btn-primary" href={"/"}>
              Return to home page
            </a>,
          ]}

          {/* Get quote form */}
          {stage === "provider" &&
            provider && [
              <div key="title" className="flex gap-2 items-center mb-10">
                <img className="w-12" src={PROVIDER_CONFIG[provider].logo} alt="utility company branding" />
                <h1 className="text-xl lg:text-2xl">Let's connect to your utility account</h1>
              </div>,
              <form key="form" className="w-full space-y-6" onSubmit={getQuote.handleSubmit(handleSubmitGetQuote)}>
                {/* savingsType input */}
                <fieldset className="flex justify-between space-y-4">
                  <legend>Are you a household or business?</legend>
                  {["household", "business"].map((savingsType) => (
                    <div className="flex items-center gap-x-default px-4" key={savingsType}>
                      <input
                        className="daisy-radio daisy-radio-primary"
                        type="radio"
                        id={savingsType}
                        value={savingsType}
                        {...getQuote.register("savingsType", GET_QUOTE_VALIDATIONS.savingsType)}
                      />
                      <label htmlFor={savingsType}>{Utils.capitalize(savingsType)}</label>
                    </div>
                  ))}
                </fieldset>
                {/* Email */}
                <div className="flex flex-col space-y-4">
                  <label htmlFor="email">Email</label>
                  <div className="flex w-full justify-between items-center gap-x-4">
                    <RDCTextWithButtonInput
                      data-test="email-quote"
                      name="email"
                      placeholder="Enter your email"
                      invalid={Boolean(getQuote.formState.errors.email)}
                      register={getQuote.register}
                      validations={GET_QUOTE_VALIDATIONS.email}
                      buttonText={`Go to ${PROVIDER_CONFIG[provider].displayUrl}`}
                      buttonType="primary"
                    />
                  </div>
                  {getQuote.formState.errors.email ? (
                    <p className="daisy-label daisy-label-text text-error mt-2" role="alert">
                      Please enter a valid email
                    </p>
                  ) : (
                    <div className="h-9" />
                  )}
                </div>
                <div>
                  {/* Privacy policy */}
                  <RDCCheckBoxInput
                    name="privacyPolicy"
                    invalid={Boolean(getQuote.formState.errors.privacyPolicy)}
                    register={getQuote.register}
                    validations={GET_QUOTE_VALIDATIONS.privacyPolicy}
                    checkBoxType="primary"
                    checkBoxText={
                      <p className="text-xs lg:text-sm">
                        I have reviewed and I agree to EcoTrove's{" "}
                        <button
                          className="daisy-btn daisy-btn-link px-0 text-xs lg:text-sm"
                          onClick={(e: MouseEvent) => {
                            e.preventDefault();
                            setPrivacyModalOpen(true);
                          }}>
                          privacy policy
                        </button>
                        .
                      </p>
                    }
                  />
                  <RDCIFrameModal
                    id={"privacy-modal"}
                    open={privacyModalOpen}
                    src={`${PRIVACY_POLICY_ROUTE}?iframe=true`} // Pass query param here
                    title="Privacy Policy"
                    onClose={() => setPrivacyModalOpen(false)}
                  />
                  {/* Terms of service */}
                  <RDCCheckBoxInput
                    name="termsOfService"
                    invalid={Boolean(getQuote.formState.errors.termsOfService)}
                    register={getQuote.register}
                    validations={GET_QUOTE_VALIDATIONS.termsOfService}
                    checkBoxType="primary"
                    checkBoxText={
                      <p className="text-xs lg:text-sm">
                        I have reviewed and I agree to EcoTrove's{" "}
                        <button
                          className="daisy-btn daisy-btn-link px-0 text-xs lg:text-sm"
                          onClick={(e: MouseEvent) => {
                            e.preventDefault();
                            setTermsModalOpen(true);
                          }}>
                          terms of service
                        </button>
                        .
                      </p>
                    }
                  />
                  <RDCIFrameModal
                    id={"terms-modal"}
                    open={termsModalOpen}
                    src={`${TERMS_OF_SERVICE_ROUTE}?iframe=true`}
                    title="Terms of Service"
                    onClose={() => setTermsModalOpen(false)}
                  />
                </div>
                {/* Provider blurb */}
                <p className="text-neutral-content">
                  Enter your email to connect your {PROVIDER_CONFIG[provider].displayName} account with EcoTrove and
                  receive a personalized quote to replace your {PROVIDER_CONFIG[provider].displayName} bills with
                  guaranteed fixed prices. We will route you to {PROVIDER_CONFIG[provider].displayUrl} to securely
                  estimate your savings.
                </p>
              </form>,
            ]}
        </div>

        {/* Right column */}
        <div className="flex flex-col items-center text-center gap-y-8">
          <p className="text-xl leading-none">
            Customers like you
            <br />
            save up to $500/yr with EcoTrove
          </p>
          <div className="rounded-3xl w-3/4 border border-primary border-opacity-10 p-8 space-y-5">
            <p className="font-semibold">Paul (saving $250+)</p>
            <p className="text-primary text-center lg:text-start">
              "Since using ecotrove they switched me over to a fully electric home rate plan and started getting my
              power from a community solar farm in my area. The quote they gave me was about 12% cheaper than what my
              pg&e bills would have been. I'll sing their praises to anyone who will listen."
            </p>
          </div>
        </div>
      </div>
      <RDCFooter />
    </div>
  );
};
