import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { RDCAlertBanner } from "../components"; // Assuming this is your error banner component
import { handleStartDocusign } from "../../startDocusign"; //TODO: remove extra layer

/**
 * Page for routing the user to DocuSign.
 * Displays a message while loading DocuSign.
 */
export const RDCLoadDocusign: React.FC = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const email = queryParams.get("email");
  const [errorAlert, setErrorAlert] = useState<string>("");

  useEffect(() => {
    if (!email) {
      setErrorAlert("Error: Session Expired.");
      return;
    }
    handleStartDocusign(email);
  }, [email]);

  return (
    <div className="tw-preflight bg-base-100 min-h-screen flex flex-col">
      {/* Error Banner */}
      {errorAlert && (
        <div className="w-full">
          <RDCAlertBanner
            type="error"
            message={errorAlert}
            onClickClose={() => setErrorAlert("")}
          />
        </div>
      )}
  
      {/* Main Content */}
      <div className="flex flex-1 items-center justify-center text-center">
        <div className="daisy-card daisy-shadow-lg daisy-max-w-lg daisy-p-6">
          <div className="daisy-card-body">
            <h1 className="text-2xl font-bold text-black mb-4">
              Payment Successful. We are routing you to DocuSign...
            </h1>
            <p className="daisy-text-lg daisy-text-neutral-content daisy-mt-4">
              Please sign our subscriber agreement to authorize EcoTrove to pay your bills with PG&E.
            </p>
            <p className="daisy-text-lg daisy-text-neutral-content daisy-mt-2">
              Please do not exit this screen.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
  
};