import styled, { keyframes } from "styled-components";
// import Navbar from "../components/AccountNavbar";
import { useNavigate } from 'react-router-dom';
import { supabase } from "../supabaseClient";
import React, { useEffect, useState, useRef } from 'react';
import GlobalStyles from '../GlobalStyles'; // Import the global styles
import background3 from '../images/background3.jpeg'; // Import the background image

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  font-family: 'Source Sans Pro', sans-serif;
  
  @media (max-width: 768px) {
    background: url(${background3}) no-repeat center center fixed;
    background-size: cover;
    & > div {
      background: rgba(255, 255, 255, 0.5);
    }
  }

  @media (min-width: 769px) {
    flex-direction: row;
  }
`;

const ErrorMessage = styled.div`
  color: red;
  margin-top: 10px;
  font-size: 20% larger; /* Increased by 20% */
`;

const Button = styled.button`
  font-size: 1.2rem;
  color: white;
  background-color: #80AA7B;
  border: none;
  border-radius: 15px;
  padding: 0.75rem 1.5rem;
  cursor: pointer;
  margin-top: 1rem;

  &:hover {
    background-color: #253D80;
  }
`;

const ContentContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 2rem;
  padding-top: 30px;

  @media (min-width: 769px) {
    width: 70%;
  }
`;

const BackgroundImage = styled.div`
  flex: 1;
  background: url(${background3}) no-repeat center center fixed;
  background-size: cover;
  position: sticky;
  top: 0;
  height: 100vh;

  @media (min-width: 769px) {
    width: 30%;
  }
`;

const Heading = styled.h1`
  text-align: center;
  color: #253D80;
`;

const slideIn = keyframes`
  from {
    opacity: 0;
    transform: translateX(-100%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

const QuestionContainer = styled.div`
  animation: ${slideIn} 0.5s ease-in-out;
  width: 100%; /* Ensure full width for consistent alignment */
  max-width: 600px; /* Limit maximum width for better readability */
  margin: 0 auto; /* Center the container */
  padding-left: 5px; /* Consistent left padding */
  padding-top: 15px;
  margin-bottom: 15px; /* Additional space between questions */
`;

const Tooltip = styled.span`
  position: relative;
  display: inline-block;
  cursor: pointer;
  color: gray;
  text-decoration: underline;

  &::after {
    content: '?';
    display: inline-block;
    width: 1.2em;
    height: 1.2em;
    border-radius: 50%;
    text-align: center;
    line-height: 1em;
    background: gray;
    color: white;
    margin-left: 0.5em;
    font-size: 1em;
  }

  &:hover div, &:active div {
    visibility: visible;
    opacity: 1;
  }
`;

const TooltipText = styled.div`
  visibility: hidden;
  width: 450px; 
  @media (max-width: 769px) {
    width: 350px; 
  }
  background-color: gray;
  font-weight: normal;
  color: #fff;
  text-align: left;
  font-size: 13px;
  border-radius: 6px;
  padding: 10px;
  position: fixed;
  z-index: 1;
  top: 200px; /* Position the tooltip at the top of the screen */
  left: 15px; /* Start the tooltip 50px from the left-hand side of the page */
  opacity: 0;
  transition: opacity 0.3s;
`;

const CertificationLabel = styled.label`
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  color: darkred;
  margin-top: 1rem;
  cursor: pointer;

  input {
    margin-right: 0.5rem;
    width: 20px;
    height: 20px;
  }
`;

const UserQuestions = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [userProfile, setUserProfile] = useState<any>(null); 
  const [ownOrRent, setOwnOrRent] = useState<string>('');
  const [adultsInHousehold, setadultsInHousehold] = useState<string>('');
  const [childrenInHousehold, setchildrenInHousehold] = useState<string>('');
  const [annualIncome, setannualIncome] = useState<string>('');
  const [medicalDevice, setMedicalDevice] = useState<string>('');
  const [publicAssistance, setPublicAssistance] = useState<string>('');
  const [medicalCondition, setMedicalCondition] = useState<string>('');
  const [referrerEmail, setReferrerEmail] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [questionIndex, setQuestionIndex] = useState<number>(0);
  const [certify, setCertify] = useState<boolean>(false);
  const bottomRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        setLoading(true);

        const { data: { user } } = await supabase.auth.getUser()
        
        if (user === null || user === undefined || !user) {
          alert("Please log in to view this screen!")
          navigate('/login-page');
        }

        const { data, error } = await supabase
          .from('user-profiles')
          .select('*')
          .eq('user_id', user?.id)
          .single();

        if (error) {
          throw error;
        }
        setUserProfile(data);
      } catch (error) {
        console.error('Error fetching user profile:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchUserProfile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (bottomRef.current) {
      setTimeout(() => {
        bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
      }, 300); // Wait 0.3 seconds before scrolling
    }
  }, [questionIndex]);

  const addEmail = async (referrerEmail: string, referredEmail: string) => {
    const { data, error } = await supabase
      .from("referral-campaign")
      .insert({ referring_email: referrerEmail, referred_email: referredEmail })
      .select();

    console.log(data)
    
    if (error) {
      console.error("Error updating referral data: ", error);
    }
  };

  const validateEmail = async (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;
    if (!emailRegex.test(email)) {
      return false;
    }
    const domain = email.split('@')[1];
    try {
      const lookup = await fetch(`https://dns.google/resolve?name=${domain}&type=MX`);
      const result = await lookup.json();
      return result.Answer !== undefined;
    } catch (e) {
      console.error("DNS lookup failed:", e);
      return false;
    }
  };

  const handleSaveUserResponses= async () => { 
    setErrorMessage("")
    //require that users actually fill out the questionnaire 
    if (ownOrRent === ""){
        setErrorMessage("Please complete questionnaire to allow EcoTrove to identify further savings on your behalf")
        return;
    }

    if (adultsInHousehold === ""){
      setErrorMessage("Please complete questionnaire to allow EcoTrove to identify further savings on your behalf")
      return;
  }

    if (childrenInHousehold === ""){
      setErrorMessage("Please complete questionnaire to allow EcoTrove to identify further savings on your behalf")
      return;
  }

    if (annualIncome === ""){
      setErrorMessage("Please complete questionnaire to allow EcoTrove to identify further savings on your behalf")
      return;
    }

    if (publicAssistance === ""){
      setErrorMessage("Please complete questionnaire to allow EcoTrove to identify further savings on your behalf")
      return;
    }
    
    if (medicalCondition === ""){
      setErrorMessage("Please complete questionnaire to allow EcoTrove to identify further savings on your behalf")
      return;
    }

    if (medicalDevice === ""){
      setErrorMessage("Please complete questionnaire to allow EcoTrove to identify further savings on your behalf")
      return;
    }

    if (!certify) {
      setErrorMessage("Please certify that your responses are accurate before proceeding.");
      return;
    }
    
    //save questionnaire responses to supabase
    const { data, error } = await supabase
    .from('user-profiles')
    .update({
      own_or_rent: ownOrRent,
      adults_in_household: adultsInHousehold,
      children_in_household: childrenInHousehold,
      annual_income: annualIncome,
      medical_device: medicalDevice,
      public_assistance: publicAssistance,
      medical_condition: medicalCondition,
      questions_complete: true
    })
    .eq('user_id', userProfile?.user_id);

    console.log(data)

    if (error) {
    alert("Failed to save responses, please try again later.");
    }

    if (referrerEmail !== "" && referrerEmail !== null && referrerEmail !== undefined) {
        if (!await validateEmail(referrerEmail)){
            setErrorMessage("Please input valid referrer email");
            return;
        }
    //save referral info to supabase
    addEmail(referrerEmail, userProfile?.email);
    }
    navigate('/user-dashboard')
  }

  const handleNextQuestion = () => {
    setQuestionIndex(prev => prev + 1);
  };

  //show loading screen until user data is pulled from supabase-this should be instant
  if (loading) {
    return (
      <div>Loading...</div>
    );
  }

  return (
    <>
      <GlobalStyles /> {/* Include global styles */}
      <PageContainer>
        <ContentContainer>
          <Heading>Questions about your primary residence to finalize your account: </Heading>
          <QuestionContainer>
            <h3>1) Do you own or rent your primary residence?</h3>
            <label>
              <select
                value={ownOrRent}
                onChange={(e) => { setOwnOrRent(e.target.value); handleNextQuestion(); }}
                required
                style={{ fontSize: '1.2rem', padding: '0.5rem 1rem', display: 'block', width: '100%' }}
              >
                <option value="">Select an option</option>
                <option value="own">Own</option>
                <option value="rent">Rent</option>
              </select>
            </label>
          </QuestionContainer>

          {questionIndex >= 1 && (
            <QuestionContainer>
              <h3>2) How many adults (18+) live in your primary residence?</h3>
              <label>
                <select
                  value={adultsInHousehold}
                  onChange={(e) => { setadultsInHousehold(e.target.value); handleNextQuestion(); }}
                  required
                  style={{ fontSize: '1.2rem', padding: '0.5rem 1rem', display: 'block', width: '100%' }}
                >
                  <option value="">Select a number</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                  <option value="7">7</option>
                  <option value="8">8</option>
                  <option value="9">9</option>
                  <option value="10+">10+</option>
                </select>
              </label>
            </QuestionContainer>
          )}

          {questionIndex >= 2 && (
            <QuestionContainer>
              <h3>3) How many children (under 18) live in your primary residence?</h3>
              <label>
                <select
                  value={childrenInHousehold}
                  onChange={(e) => { setchildrenInHousehold(e.target.value); handleNextQuestion(); }}
                  required
                  style={{ fontSize: '1.2rem', padding: '0.5rem 1rem', display: 'block', width: '100%' }}
                >
                  <option value="">Select a number</option>
                  <option value="0">0</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                  <option value="7">7</option>
                  <option value="8">8</option>
                  <option value="9">9</option>
                  <option value="10+">10+</option>
                </select>
              </label>
            </QuestionContainer>
          )}

          {questionIndex >= 3 && (
            <QuestionContainer>
              <h3>
                4) Does a full-time resident in your household use a <Tooltip><TooltipText>
                    Qualifying medical devices include, but are not limited to:
                    <ul style={{ fontWeight: 'normal' }}>
                      <li>Aerosol tent</li>
                      <li>Air mattress/hospital bed</li>
                      <li>Apnea monitor</li>
                      <li>Breather machine (IPPB)</li>
                      <li>Compressor/concentrator</li>
                      <li>Dialysis machine</li>
                      <li>Electronic nerve stimulator</li>
                      <li>Electrostatic nebulizer</li>
                      <li>Hemodialysis machine</li>
                      <li>Infusion pump</li>
                      <li>Inhalation pulmonary pressure</li>
                      <li>Iron lung</li>
                      <li>Left ventricular assist device (LVAD)</li>
                      <li>Motorized wheelchair/scooter</li>
                      <li>Oxygen generator</li>
                      <li>Pressure pad</li>
                      <li>Pressure pump</li>
                      <li>Pulse oximeter/monitor</li>
                      <li>Respirator (all types)</li>
                      <li>Suction machine</li>
                      <li>Total artificial heart (TAH-t)</li>
                      <li>Ultrasonic nebulizer</li>
                      <li>Vest/airway clearance system</li>
                    </ul>
                  </TooltipText> qualifying</Tooltip> electric-powered medical device at home? If yes, you might be eligible for additional savings.
              </h3>
              <label>
                <select
                  value={medicalDevice}
                  onChange={(e) => { setMedicalDevice(e.target.value); handleNextQuestion(); }}
                  required
                  style={{ fontSize: '1.2rem', padding: '0.5rem 1rem', display: 'block', width: '100%' }}
                >
                  <option value="">Select an option</option>
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </select>
              </label>
            </QuestionContainer>
          )}

          {questionIndex >= 4 && (
            <QuestionContainer>
              <h3>
                5) Does a full-time resident in your household participate in a <Tooltip><TooltipText>
                    Qualifying public assistance programs include, but are not limited to:
                    <ul style={{ fontWeight: 'normal'}}>
                      <li>Low Income Home Energy Assistance Program (LIHEAP)</li>
                      <li>Women, Infants and Children (WIC)</li>
                      <li>CalFresh/SNAP (Food Stamps)</li>
                      <li>CalWORKs(TANF) or Tribal TANF</li>
                      <li>Head Start Income Eligible (Tribal Only)</li>
                      <li>Supplemental Security Income (SSI)</li>
                      <li>Medi-Cal for Families (Healthy Families A & B)</li>
                      <li>National School Lunch Program (NSLP)</li>
                      <li>Bureau of Indian Affairs General Assistance</li>
                      <li>Medicaid/Medi-Cal</li>              
                    </ul>
                  </TooltipText> qualifying</Tooltip> public assistance program? If yes, you might be eligible for additional savings.
              </h3>
              <label>
                <select
                  value={publicAssistance}
                  onChange={(e) => { setPublicAssistance(e.target.value); handleNextQuestion(); }}
                  required
                  style={{ fontSize: '1.2rem', padding: '0.5rem 1rem', display: 'block', width: '100%' }}
                >
                  <option value="">Select an option</option>
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </select>
              </label>
            </QuestionContainer>
          )}

          {questionIndex >= 5 && (
            <QuestionContainer>
              <h3>
                6) Does a full-time resident in your household have a <Tooltip><TooltipText>
                    Qualifying medical conditions include, but are not limited to:
                    <ul style={{ fontWeight: 'normal'}}>
                      <li>Paraplegic, hemiplegic or quadriplegic condition</li>
                      <li>Multiple sclerosis with heating and/or cooling needs</li>
                      <li>Scleroderma with heating needs</li>
                      <li>Life-threatening illness or compromised immune system</li>
                      <li>Asthma and/or sleep apnea</li>                
                    </ul>
                  </TooltipText> qualifying</Tooltip> medical condition? If yes, you might be eligible for additional savings.
              </h3>
              <label>
                <select
                  value={medicalCondition}
                  onChange={(e) => { setMedicalCondition(e.target.value); handleNextQuestion(); }}
                  required
                  style={{ fontSize: '1.2rem', padding: '0.5rem 1rem', display: 'block', width: '100%' }}
                >
                  <option value="">Select an option</option>
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </select>
              </label>
            </QuestionContainer>
          )}


          {questionIndex >= 6 && (
            <QuestionContainer>
              <h3>7) What is your annual household income <Tooltip><TooltipText>
                    Qualifying public assistance programs include, but are not limited to:
                    <ul style={{ fontWeight: 'normal'}}>
                      <li>Ranges are indicated based on government incentive eligibility limits</li>        
                    </ul>
                  </TooltipText> range? </Tooltip> Certain ranges might qualify you for additional savings.</h3>
              <label>
                <select
                  value={annualIncome}
                  onChange={(e) => { setannualIncome(e.target.value); handleNextQuestion(); }}
                  required
                  style={{ fontSize: '1.2rem', padding: '0.5rem 1rem', display: 'block', width: '100%' }}
                >
                  <option value="">Select an income range</option>
                  <option value="0 - $40,800">0 - $40,800</option>
                  <option value="$40,801 - $51,640">$40,801 - $51,640</option> 
                  <option value="$51,641 - $62,400">$51,641 - $62,400</option> 
                  <option value="$62,401 - $73,161">$62,401 - $73,161</option> 
                  <option value="$73,161 - $83,920">$73,161 - $83,920</option> 
                  <option value="$83,921 - $94,680">$83,921 - $94,680</option> 
                  <option value="$94,681 - $105,440">$94,681 - $105,440</option> 
                  <option value="$105,441 - $116,200">$105,441 - $116,200</option> 
                  <option value="$116,201 - $126,960">$116,201 - $126,960</option> 
                  <option value="$126,961 - $145,250">$126,961 - $145,250</option> 
                  <option value="$145,251 - $158,700">$145,251 - $158,700</option> 
                  <option value="$158,701 - $200,000">$158,701 - $200,000</option> 
                  <option value="$200,001+">$200,001+</option> 
                  </select>
              </label>
            </QuestionContainer>
          )}

          {questionIndex >= 7 && (
            <>
              <QuestionContainer>
                <h3>8) If someone referred you to EcoTrove, please enter their email.</h3>
                <label>
                  We will give both you and your referrer a $30 subscription discount.<br />
                  Enter the email of your EcoTrove referrer to claim the discount:
                  <input
                    type="text"
                    placeholder="leave blank if not applicable"
                    value={referrerEmail}
                    onChange={(e) => setReferrerEmail(e.target.value)}
                    style={{ fontSize: '1.2rem', padding: '0.5rem 1rem', display: 'block', width: '90%' }}
                  />
                </label>
                <h3>
                  You can also earn $30 for each friend you refer going forward! <br />
                  Just tell them to add your email after signing up for EcoTrove
                </h3>
              </QuestionContainer>
              <QuestionContainer>
                <CertificationLabel>
                  <input
                    type="checkbox"
                    checked={certify}
                    onChange={() => setCertify(!certify)}
                    required
                  />
                  I certify that all of my responses are accurate.
                </CertificationLabel>
              </QuestionContainer>
            </>
          )}

          <Button onClick={handleSaveUserResponses}>Save Profile</Button>
          {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
          <div ref={bottomRef} />
        </ContentContainer>
        <BackgroundImage />
      </PageContainer>
    </>
  );
};

export default UserQuestions;
