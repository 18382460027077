import { Route, Routes, Navigate } from "react-router-dom";
import LandingPageResidential from "./pages/LandingPageResidential";
import LandingPageCommercial from "./pages/LandingPageCommercial";
import PgeOAuthStart from "./pages/PgeOAuthStart";
import SdgeOauthRedirect from "./pages/SdgeOauthRedirect";
import SceDataAvailability from "./pages/SceDataAvailability";
import SceOauthRedirect from "./pages/SceOauthRedirect";
import DataAvailable from "./pages/DataAvailable";
import SelectCustodian from "./pages/SelectCustodian";
import ScopeSelection from "./pages/ScopeSelection";
import SignatureSuccess from "./pages/SignatureSuccess";
import CreateAccount from "./pages/CreateAccount";
import UpdatePassword from "./pages/UpdatePassword";
import ConfirmEmail from "./pages/ConfirmEmail";
import UserDashboard from "./pages/UserDashboard";
import UserQuestions from "./pages/UserQuestions";
import LoginPage from "./pages/LoginPage";
import PaymentSuccess from "./pages/PaymentSuccess";
import TermsAndConditions from "./pages/TermsAndConditions";
import NewQuote from "./pages/NewQuote";
import ShowQuote from "./pages/ShowQuote";
import BusinessOrHousehold from "./pages/BusinessOrHousehold";
import SaveMore from "./pages/SaveMore";
import ManageAccount from "./pages/ManageAccount";
import mixpanel from "mixpanel-browser";
import { Test } from "./pages/Test";
import EmpoweringSmallBusinesses from "./pages/blog_empowering_small_businesses";
import SerenaSaved from "./pages/blog_how_serena_saved";
import NavigatingPGE from "./pages/blog_navigating_pge";
import PowerofCleanEnergy from "./pages/blog_power_of_clean_energy";
import UnlockingEnergySavings from "./pages/blog_unlocking_energy_savings";
import BattlingSkyrokcetingUtilities from "./pages/blog_battling_skyrocketing_utility_costs";
import {
  RDCLandingPage,
  RDCCustomizeQuote,
  RDCLoadDocusign,
  RDCHowItWorksPage,
  RDCGetQuotePage,
  RDCPgeOAuthRedirect,
  RDCShowQuotePage,
  RDCTermsOfService,
  RDCPrivacyPolicy,
  RDCAllBlogsPage,
  RDCSingleBlogPage,
  RDCAboutUs,
  RDCEmailBusinessQuote,
  RDCThanksForReauthorizing,
} from "./redesign/pages";
import {
  CUSTOMIZE_QUOTE_ROUTE,
  GET_QUOTE_ROUTE,
  HOW_IT_WORKS_ROUTE,
  PAYMENT_SUCCESS_ROUTE,
  PGE_OAUTH_REDIRECT_ROUTE,
  TERMS_OF_SERVICE_ROUTE,
  PRIVACY_POLICY_ROUTE,
  SHOW_QUOTE_ROUTE,
  ALL_BLOGS_ROUTE,
  ABOUT_US_ROUTE,
  EMAIL_BUSINESS_QUOTE_ROUTE,
  THANKS_FOR_REAUTHORIZING_ROUTE,
} from "./constants";

import "./App.css";
import { RDCScrollToTopProvider } from "./redesign/components";

const App = () => {
  const mixpanelToken = process.env.REACT_APP_MIXPANEL_TOKEN;

  if (!mixpanelToken) {
    console.error("No token found");
  } else {
    mixpanel.init(mixpanelToken, {
      debug: true,
      track_pageview: true,
      persistence: "localStorage",
    });
  }

  return (
    <RDCScrollToTopProvider>
      <Routes>
        {/* Redesigned Routes */}
        <Route path={"/"} element={<RDCLandingPage />} />
        <Route path={PGE_OAUTH_REDIRECT_ROUTE} element={<RDCPgeOAuthRedirect />}></Route>
        <Route path={CUSTOMIZE_QUOTE_ROUTE} element={<RDCCustomizeQuote />} />
        <Route path={PAYMENT_SUCCESS_ROUTE} element={<RDCLoadDocusign />} />
        <Route path={HOW_IT_WORKS_ROUTE} element={<RDCHowItWorksPage />} />
        <Route path={ALL_BLOGS_ROUTE} element={<RDCAllBlogsPage />} />
        <Route path={`${ALL_BLOGS_ROUTE}/:blogId`} element={<RDCSingleBlogPage />} />
        <Route path={GET_QUOTE_ROUTE} element={<RDCGetQuotePage />} />
        <Route path={TERMS_OF_SERVICE_ROUTE} element={<RDCTermsOfService />} />
        <Route path={PRIVACY_POLICY_ROUTE} element={<RDCPrivacyPolicy />} />
        <Route path={SHOW_QUOTE_ROUTE} element={<RDCShowQuotePage />} />
        <Route path={ABOUT_US_ROUTE} element={<RDCAboutUs />} />
        <Route path={EMAIL_BUSINESS_QUOTE_ROUTE} element={<RDCEmailBusinessQuote />} />
        <Route path={THANKS_FOR_REAUTHORIZING_ROUTE} element={<RDCThanksForReauthorizing />} />

        {/* Existing Routes */}
        {/* <Route path="/" element={<LandingPageResidential />} /> */}
        <Route path="/residential" element={<LandingPageResidential />} />
        <Route path="/commercial" element={<LandingPageCommercial />} />
        <Route path="/pge-oauth-start" element={<PgeOAuthStart />} />
        {/* <Route path="/how-it-works" element={<HowItWorksPage />} /> */}
        {/* <Route path="/our-team" element={<AboutUs />} /> */}
        {/* <Route path="/get-quote" element={<GetQuote />} /> */}
        {/* <Route path="/privacy-policy" element={<PrivacyPolicy />} /> */}
        {/* <Route path="/terms-of-service" element={<TermsOfService />} /> */}
        {/* <Route path="/pge-oauth-redirect" element={<PgeOAuthRedirect />}></Route> */}
        <Route path="/sdge-oauth-redirect" element={<SdgeOauthRedirect />} />
        <Route path="/sce-oauth-redirect" element={<SceOauthRedirect />} />
        <Route path="/sce-data-availability" element={<SceDataAvailability />} />
        <Route path="/data-available" element={<DataAvailable />} />
        <Route path="/scope-selection" element={<ScopeSelection />} />
        <Route path="/select-custodian" element={<SelectCustodian />} />
        <Route path="/new-quote" element={<NewQuote />} />
        <Route path="/create-account" element={<CreateAccount />} />
        <Route path="/update-password" element={<UpdatePassword />} />
        <Route path="/confirm-email" element={<ConfirmEmail />} />
        <Route path="/login-page" element={<LoginPage />} />
        <Route path="/user-dashboard" element={<UserDashboard />} />
        <Route path="/user-questions" element={<UserQuestions />} />
        <Route path="/signature-success" element={<SignatureSuccess />} />
        <Route path="/payment-success" element={<PaymentSuccess />} />
        <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
        <Route path="/show-quote" element={<ShowQuote />} />
        {/* <Route path="/load-docusign" element={<LoadDocusign />} /> */}
        {/* <Route path="/request-emailed-quote" element={<RequestEmailedQuote />} /> */}
        {/* <Route path="/thanks-for-reauthorizing" element={<ThanksForReauthorizing />} /> */}
        <Route path="/business-or-household" element={<BusinessOrHousehold />} />
        {/* <Route path="/customize-quote" element={<CustomizeQuote />} /> */}
        {/* <Route path="/all-blogs" element={<AllBlogs />} /> */}
        <Route path="/blog-empowering-small-businesses" element={<EmpoweringSmallBusinesses />} />
        <Route path="/blog-how-serena-saved" element={<SerenaSaved />} />
        <Route path="/blog-navigating-pge" element={<NavigatingPGE />} />
        <Route path="/blog-power-of-clean-energy" element={<PowerofCleanEnergy />} />
        <Route path="/blog-unlocking-energy-savings" element={<UnlockingEnergySavings />} />
        <Route path="/blog-battling-skyrocketing-utility-costs" element={<BattlingSkyrokcetingUtilities />} />
        <Route path="/testing-ecotrove-from-plasmic" element={<Test />} />
        <Route path="/ecotrove_fixed_price_utility_bill" element={<Navigate to="/" replace />} />
        <Route path="/energy_subscription_quote" element={<Navigate to="/" replace />} />
        <Route path="/manage-account" element={<ManageAccount />} />
        <Route path="/save-more" element={<SaveMore />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </RDCScrollToTopProvider>
  );
};

export default App;
