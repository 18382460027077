/**
 * Props to pass to a `RDCImageCard` component.
 */
export interface RDCImageCardProps {
  imgUrl: string;
  imgAlt: string;
  headerText: string;
  bodyText: string;
}

/**
 * Card that renders an image with subtext.
 * @param props the props to render the component with
 * @returns a React component.
 */
export const RDCImageCard: React.FC<RDCImageCardProps> = (props) => {
  const { imgUrl, imgAlt, headerText, bodyText } = props;

  return (
    <div>
      <img src={imgUrl} alt={imgAlt} />
      <div className=" px-4 lg:px-8">
        <h3 className="mt-8">{headerText}</h3>
        <p className="mt-4 text-neutral-content">{bodyText}</p>
      </div>
    </div>
  );
};
