import React, { useRef } from "react";
import LinkedInLogo from "../images/icons/linkedin-icon.png";

/**
 * Props to pass to a `RDCOurTeamCarousel` component.
 */
interface RDCOurTeamCarouselProps {
  teamMembers: {
    name: string;
    title: string;
    description: string | JSX.Element;
    linkedin: string;
    img: React.ReactNode; // Avatar image JSX
  }[];
}

/**
 * Carousel component for the "Our Team" section.
 * @param props the props to render the component with.
 * @returns a React component.
 */
export const RDCOurTeamCarousel: React.FC<RDCOurTeamCarouselProps> = ({ teamMembers }) => {
  const carouselRef = useRef<HTMLDivElement>(null);

  const handlePrev = () => {
    if (carouselRef.current) {
      const scrollWidth = carouselRef.current.offsetWidth;
      carouselRef.current.scrollBy({ left: -scrollWidth, behavior: "smooth" });
    }
  };

  const handleNext = () => {
    if (carouselRef.current) {
      const scrollWidth = carouselRef.current.offsetWidth;
      carouselRef.current.scrollBy({ left: scrollWidth, behavior: "smooth" });
    }
  };

  return (
    <div className="relative">
      {/* Carousel Container */}
      <div
        ref={carouselRef}
        className="daisy-carousel daisy-carousel-center w-full flex overflow-x-auto scroll-snap-type-x mandatory mt-8">
        {teamMembers.map((member, index) => (
          <div
            key={index}
            className="daisy-carousel-item flex flex-col items-center text-center gap-4 w-full sm:w-96 scroll-snap-align-center">
            {/* Avatar */}
            <div className="w-24 h-24 sm:w-32 sm:h-32 bg-neutral-200 rounded-full overflow-hidden">{member.img}</div>
            {/* Name and Title */}
            <h3 className="text-black text-lg mt-4">{member.name}</h3>
            <p className="text-neutral-content text-sm sm:text-base">{member.title}</p>
            {/* Description */}
            <div className="flex-grow flex flex-col justify-between items-center">
              <p className="text-neutral-content text-sm max-w-xs sm:max-w-md mb-4 sm:mb-6">{member.description}</p>
              {/* LinkedIn Link */}
              <a
                href={member.linkedin}
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center justify-center">
                <img src={LinkedInLogo} alt="LinkedIn logo" className="w-6 h-6 sm:w-8 sm:h-8" />
              </a>
            </div>
          </div>
        ))}
      </div>

      {/* Carousel Navigation */}
      <div className="flex justify-center items-center mt-8 space-x-4">
        <button className="daisy-btn daisy-btn-circle daisy-btn-ghost" onClick={handlePrev}>
          ←
        </button>
        <button className="daisy-btn daisy-btn-circle daisy-btn-ghost" onClick={handleNext}>
          →
        </button>
      </div>
    </div>
  );
};
