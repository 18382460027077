/**
 * Component that allows a user to check EcoTrove's energy concierge service details and milestones.
 * @returns a React `FunctionComponent`.
 */
export const RDCEnergyConciergeHero: React.FC = () => {
  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 py-12 items-start">
      {/* Left Column */}
      <div className="col-span-1 flex flex-col justify-start">
        <h1 className="text-black text-4xl lg:text-5xl leading-none mb-4">
          Your Energy Concierge
        </h1>
        <p className="text-neutral-content text-base md:text-md max-w-5xl">
          EcoTrove’s mission is to build a cheaper, greener and predictable energy ecosystem. We register as your energy agent to replace your utility bills with cheaper, greener, fixed-price payment plans.
        </p>
      </div>

      {/* Right Column */}
      <div className="col-span-1 flex flex-col justify-start lg:pl-40">
        <div className="text-sm text-neutral-content">
          <h2 className="text-black text-2xl leading-none mb-4">
            Milestones
          </h2>
          <ul className="list-check mt-0 space-y-3">
            <li>Launched with Pacific Gas & Electric in April 2024</li>
            <li>Launching in San Diego Gas & Electric in January 2025</li>
            <li>Launching in Southern California Edison in January 2025</li>
          </ul>
          <div className="border-t border-neutral mt-8 pt-4 max-w-lg">
            <p>Backed by leading Silicon Valley investors</p>
            <div className="mt-2 pt-4 max-w-md">
            <p>
              Saving each customer an average of 7% off their power bills and 32%
              off their carbon emissions.
            </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
