import { QUOTE_CALENDLY } from "../../constants";
export const RDCEmailBusinessQuote: React.FC = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const email = queryParams.get("email");

  return (
    <div className="tw-preflight min-h-screen flex flex-col items-center justify-center bg-base-100">
            <div className="text-center">
              <h1 className="text-xl  text-black">
              Thank you for using EcoTrove!
              </h1>
              <p className="text-sm text-gray-700 mt-4">
              As a business customer, you might be eligible for unique discounts that can save you up to $1,000/yr and allow you to pay the same, precictable, budget-friendly price every month. 
              <br />
              We will email {email} your quote within the next 24 hours. Meanwhile, you are welcome to schedule a free consultation with our energy expert.
              </p>
              <a
                  className="daisy-btn daisy-btn-ghost border border-neutral-200 px-button mt-5"
                  href={QUOTE_CALENDLY}>
                  Schedule Call
                </a>
            </div>
    </div>
  )};