import React from "react";
import { Blog } from "../services/BlogService";
import { BLOGS_ROUTE } from "../../constants";

/**
 * Props to pass to a `RDCBlogSummaryCard` component.
 */
export interface RDCBlogSummaryCardProps {
  blog: Blog;
  orientation?: "vertical" | "horizontal";
}

/**
 * Card component that displays a summary of a blog.
 * @param props the props to render the component with
 * @returns a React component.
 */
export const RDCBlogSummaryCard: React.FC<RDCBlogSummaryCardProps> = (props) => {
  const { id, title, subtitle, category, img, author, datePublishedEpochMS, minutesToRead } = props.blog;
  const orientation = props.orientation ?? "vertical";

  return (
    <div className={`grid ${orientation === "vertical" ? "grid-cols-1 items-start" : "grid-cols-2 items-center"} gap-16`}>
      {/* Main image */}
      <img className="w-full aspect-video rounded-3xl" src={img.src} alt={img.alt} />
      <div className="flex flex-col">
        {/* Blog info */}
        <div className="daisy-badge daisy-badge-secondary mb-default">{category}</div>
        <a className="daisy-link daisy-link-hover pb-4 text-3xl" href={`${BLOGS_ROUTE}/${id}`}>
          {title}
        </a>
        <p className="text-neutral-content pb-8">{subtitle}</p>
        {/* Author info */}
        <div className="flex gap-4">
          <img className="daisy-mask daisy-mask-circle size-12" src={author.img.src} alt={author.img.alt} />
          <div className="flex flex-col">
            <p>{author.name}</p>
            <div className="flex gap-default items-center">
              <p className="text-sm">{new Date(datePublishedEpochMS).toDateString()}</p>
              <p className="text-sm">&#x2022;</p>
              <p className="text-sm">{minutesToRead} min read</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
