import { initPlasmicLoader } from "@plasmicapp/loader-react";

export const PLASMIC = initPlasmicLoader({
  projects: [
    {
      id: "uiyv69DKtSP1ibmxGoRZuc",
      token: "AjWPct8n5P4H7Ulr5R0A634yxBUn9P8PMxylnkgn5339gbX9CfphTGoPxvxbvKNS7xw7QaxGIiheTgk5Rs5kA"
    }
  ],
  // Fetches the latest revisions, whether or not they were unpublished!
  // Disable for production to ensure you render only published changes.
  preview: false,
})
