import { Link } from "./types";
import { z } from "zod";
import * as tailwindColors from "tailwindcss/colors";
import AliProfile from "./redesign/images/team/AliSarilgan.png";
import OzgeProfile from "./redesign/images/team/OzgeIslegenWojdyla.png";
import ChristineProfile from "./redesign/images/team/ChristineManegan.png";
import TeganProfile from "./redesign/images/team/TeganFleishman.png";
import JessProfile from "./redesign/images/team/JessKerlin.png";

/* ---- MISC ---- */

export const UTILITIES_SERVED = ["PACIFIC GAS AND ELECTRIC"];

/* ---- ECOTROVE ---- */

export const ECOTROVE_URL = "https://ecotrove.com";
export const ECOTROVE_EMAIL_INFO = "info@ecotrove.com";

/* ---- UTILTIITES ---- */
export const PGE_URL = "https://sharemydata.pge.com/myAuthorization";
export const SCE_URL = "https://www.sce.com";
export const SDGE_URL = "https://www.sdge.com";
export const DATAGUARD_URL = "https://www.energy.gov/oe/dataguard-energy-data-privacy-program";
export const TRUSTPILOT_URL = "https://www.trustpilot.com/review/ecotrove.com";
export const SMARTENERGY_URL =
  "https://www.smart-energy.com/renewable-energy/delivering-clean-energy-at-a-fixed-price-in-the-us/";

/* ---- ROUTES ---- */

// export const REDESIGN_ROUTE = "/ecotrove-redesign-2024"; // TODO: deprecate this after redesign
export const HOW_IT_WORKS_ROUTE = "/how-it-works";
export const ABOUT_US_ROUTE = "/about-us";
export const ALL_BLOGS_ROUTE = "/blogs";
export const BLOGS_ROUTE = "/blogs";
export const PGE_OAUTH_REDIRECT_ROUTE = "/pge-oauth-redirect";
export const CUSTOMIZE_QUOTE_ROUTE = "/customize-quote";
export const PAYMENT_SUCCESS_ROUTE = "/payment-success";
export const GET_QUOTE_ROUTE = "/get-quote";
export const LOGIN_PAGE_ROUTE = "/login-page";
export const PRIVACY_POLICY_ROUTE = "/privacy-policy";
export const TERMS_OF_SERVICE_ROUTE = "/terms-of-service";
export const USER_DASHBOARD_ROUTE = "/user-dashboard";
export const SHOW_QUOTE_ROUTE = "/show-quote";
export const CREATE_ACCOUNT_ROUTE = "/create-account";
export const MANAGE_ACCOUNT_ROUTE = "/manage-account";
export const SAVE_MORE_ROUTE = "/save-more";
export const FAQ_ROUTE = "/faqs";
export const EMAIL_BUSINESS_QUOTE_ROUTE = "/email-business-quote";
export const THANKS_FOR_REAUTHORIZING_ROUTE = "/thanks-for-reauthorizing";

/* ---- JSX ---- */
/**
 * Space to add at the bottom on mobile so the next view doesn't get snapped too soon.
 */
export const MOBILE_SNAP_SPACER = <div className="flex lg:hidden h-48" />;

/* ---- REGEX ---- */

/**
 * Regex to validate an email from https://emailregex.com.
 */
export const EMAIL_REGEX =
  // eslint-disable-next-line no-control-regex
  /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/;

/**
 * Regex to validate a 5-digit ZIP code.
 */
export const ZIP_CODE_REGEX = /^\d{5}$/;

/* ---- ECHARTS ---- */

/**
 * TODO: import theme colors from Tailwind config.
 * I tried this guide without success: https://stackoverflow.com/questions/72440619/import-styles-directly-from-tailwindcss-config-file
 * I suspect our preflight plugin is messing with the imports.
 * Some example theme configs can be found here: https://github.com/apache/echarts/tree/master/theme
 */
export const ECHARTS_PRIMARY = "#380089";
export const ECHARTS_SECONDARY = "#DBD9EC";
export const ECHARTS_NEUTRAL = tailwindColors.stone[200];
export const ECHARTS_NEUTRAL_CONTENT = tailwindColors.stone[600];
export const ECHARTS_TEXT_STYLE = {
  color: tailwindColors.gray[950],
  fontFamily: "Gilroy-Medium",
  fontSize: 18,
};
export const ECHARTS_THEME = {
  textStyle: ECHARTS_TEXT_STYLE,
  color: [ECHARTS_PRIMARY, ECHARTS_SECONDARY, ECHARTS_NEUTRAL, ECHARTS_NEUTRAL_CONTENT],
  categoryAxis: { axisLabel: ECHARTS_TEXT_STYLE },
  valueAxis: { axisLabel: ECHARTS_TEXT_STYLE },
};

/* ---- LINK CONFIGS ---- */

/**
 * Links that are shown in main navigation menus.
 */
export const MAIN_MENU_LINKS: Link[] = [
  { label: "How It Works", url: HOW_IT_WORKS_ROUTE },
  { label: "About Us", url: ABOUT_US_ROUTE },
  { label: "Blog", url: ALL_BLOGS_ROUTE },
];

/**
 * Links that are shown in the user account menu.
 */
export const ACCOUNT_LINKS: Link[] = [
  { label: "Dashboard", url: HOW_IT_WORKS_ROUTE },
  { label: "Manage Account", url: MANAGE_ACCOUNT_ROUTE },
  { label: "Save More", url: SAVE_MORE_ROUTE },
  { label: "FAQs", url: FAQ_ROUTE },
];

/**
 * Links that prompt a user to take action.
 */
export const ACTION_LINKS: Link[] = [{ label: "Get Started", url: GET_QUOTE_ROUTE }];

/**
 * Link to Ali's calendly for a custom quote
 */
export const QUOTE_CALENDLY = "https://calendly.com/ali-sarilgan/ecotrove-call";

/* ---- ZOD SCHEMAS ---- */

/**
 * Zod schema for validationg a utility provider.
 */
export const UTILITY_PROVIDER_SCHEMA = z.enum(["pge"]);

/**
 * Zod schema for validationg a building type.
 */
export const SAVINGS_TYPE_SCHEMA = z.enum(["household", "business"]);

/**
 * Team members for rotating carousel
 */
export const TEAM_MEMBERS = [
  {
    name: "Ali Sarilgan",
    title: "CEO & Co-Founder",
    description:
      "Previously led corporate strategy for consumer technology companies and clean technology providers, and advised energy utilities. Graduated with MS and BS from Stanford",
    linkedin: "https://www.linkedin.com/in/alisarilgan",
    img: <img src={AliProfile} alt="Ali Sarilgan" className="w-full h-full object-cover" />,
  },
  {
    name: "Ozge Islegen-Wojdlya",
    title: "CTO and Co-Founder",
    description:
      "Former Assistant Professor at Northwestern and Stanford PhD focused on energy markets. Previously led statistical interventions and testing as Senior Data Scientist at Instacart",
    linkedin: "https://www.linkedin.com/in/ozge-islegen-wojdyla/",
    img: <img src={OzgeProfile} alt="Ozge Islegen-Wojdly" className="w-full h-full object-cover" />,
  },
  {
    name: "Christine Manegan",
    title: "Founding Engineer",
    description:
      "Former Software and Data Engineer at Snapchat, and Software Engineer at Apple. Studied Computer Science at Stanford",
    linkedin: "https://www.linkedin.com/in/christine-astoria-manegan-6a4812159/",
    img: <img src={ChristineProfile} alt="Christine Manegan" className="w-full h-full object-cover" />,
  },
  {
    name: "Tegan Fleishman",
    title: "Founding Engineer",
    description:
      "Former Software Engineer at AWS and Kindle. Studied Computer Science and Entrepreneurship at California Polytechnic State University, San Luis Obispo",
    linkedin: "https://www.linkedin.com/in/tegan-fleishman/",
    img: <img src={TeganProfile} alt="Tegan Fleishman" className="w-full h-full object-cover" />,
  },
  {
    name: "Jess Kerlin",
    title: "Founding Machine Learning Engineer",
    description: (
      <div>
        Former Senior Machine Learning Manager at{" "}
        <a className="daisy-link" href="http://data.ai/" target="_blank" rel="noopener noreferrer">
          data.ai
        </a>
        , Postdoctoral researcher at UC Davis and Phd at the University of Birmingham, U.K.
      </div>
    ),
    linkedin: "https://www.linkedin.com/in/jesskerlin/",
    img: <img src={JessProfile} alt="Jess Kerlin" className="w-full h-full object-cover" />,
  },
];
