import styled from "styled-components";
import Navbar from "../components/Navbar";
import { Helmet } from 'react-helmet';
import UserBehavior from "../components/UserBehavior";
import GlobalStyles from '../GlobalStyles'; // Import the global styles

const Container = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #e6f7ff;
  width: 100%;
`;

const NewQuoteSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 50px 50px 80px 50px;
  text-align: center;
  width: 30vw;
`;

const Title = styled.h1`
  font-size: 32px;
  font-weight: bold;
  color: #111d57;
  line-height: 1.5;
  @media (max-width: 768px) {
    font-size: 28px; // Smaller font size for mobile devices
    width: 250%;
  }
`;

// const Subtitle = styled.h2`
//   color: #666;
//   font-size: 24px;
//   line-height: 1.5;
// `;

const SceDataAvailability = () => {
  return (
    <>
    <GlobalStyles /> {/* Include global styles */}
    <Helmet>
    <UserBehavior />
   </Helmet> 

    <main>
    <Container>
      <Navbar />
      <NewQuoteSection>
        <Title>SCE data available.</Title>
      </NewQuoteSection>
    </Container>
  </main>
  </>
  );
};

export default SceDataAvailability;
