import { supabase } from "./supabaseClient";
import { constructFullApiURL } from "./constructFullApiUrl";
import mixpanel from "mixpanel-browser";
export const handleStartStripe = async (email: string, startDate: Date) => {
  try {
    mixpanel.track("Start Stripe");
    const { data: userProfileData, error: userProfileError } = await supabase
      .from("user-profiles")
      .select("monthly_subscription, subscription_type")
      .eq("email", email)
      .single();

    if (userProfileError) {
      console.log(userProfileError);
    }

    console.log("user profile data: ", userProfileData);
    const subscriptionQuote = userProfileData?.monthly_subscription;
    const subscriptionType = userProfileData?.subscription_type;
    let priceId = process.env.REACT_APP_MAX_SAVER_PRICE_ID;
    if (subscriptionType === "Green Saver") {
      priceId = process.env.REACT_APP_GREEN_SAVER_PRICE_ID;
    }

    const apiURL = constructFullApiURL(`/stripe-url`);
    const response = await fetch(apiURL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        // Include any necessary data in the request body
        subscriptionQuote: subscriptionQuote,
        email: email,
        startDate: startDate,
        priceId: priceId,
      }),
    });
    if (!response.ok) {
      throw new Error("Failed to fetch Stripe URL");
    }
    const data = await response.json();
    const stripeURL = data.stripeURL;
    // Redirect the user to the stripe URL
    window.location.href = stripeURL;
  } catch (error) {
    console.error("Error starting now:", error);
    // Handle error, e.g., display an error message to the user
  }
};
