import React from "react";

/**
 * Props to pass to an `RDCPricingCard` component.
 */
export interface RDCPricingCardProps {
  title: string;
  titleBadge?: TitleBadge;
  price: string;
  priceClarification: string;
  description: string;
  features: string[];
  ctaText: string;
  bgColor?: string;
  isHighlighted?: boolean; // Optional: Highlights card with secondary color and larger size
  onClick?: () => void; // Optional: Click handler for the card
}

export type TitleBadge = {
  text: string;
  type: "primary" | "secondary" | "neutral";
};

/**
 * Card component that displays a pricing option.
 * @param props the props to render the component with.
 * @returns a React component.
 */
export const RDCPricingCard: React.FC<RDCPricingCardProps> = (props) => {
  const {
    title,
    titleBadge,
    price,
    priceClarification,
    description,
    features,
    ctaText,
    bgColor,
    isHighlighted,
    onClick,
  } = props;

  return (
    <div
      className={`flex flex-col gap-5 p-4 rounded-xl shadow-md border 
        ${isHighlighted ? "bg-secondary border-gray-300" : "daisy-bg-base-100 border-gray-300"} 
        ${bgColor || ""} w-full md:w-72 lg:w-96`}>
      {/* Title and price */}
      <div>
        <div className="flex w-full justify-between items-center gap-1">
          <h2 className="text-xl">{title}</h2>
          {titleBadge && (
            <div className={`max-sm:daisy-badge-sm daisy-badge ${`daisy-badge-${titleBadge?.type}`}`}>
              {titleBadge.text}
            </div>
          )}
        </div>
        <p className="text-2xl daisy-text-secondary text-left tracking-wide">{price}</p>
        <p className="text-left text-sm">{priceClarification}</p>
      </div>
      {/* Description */}
      <p
        className={`text-sm ${
          isHighlighted ? "daisy-text-secondary-content" : "daisy-text-neutral-content"
        } text-left`}>
        {description}
      </p>
      {/* Features */}
      <div className="flex flex-col">
        <ul className="list-none space-y-2 pl-0 text-sm text-left text-gray-700">
          {features.map((feature, index) => (
            <li
              key={index}
              className={`${feature.startsWith("No") || feature.startsWith("You") ? "list-dash" : "list-check"}`}>
              {feature.replace(/^[-✓]\s*/, "")}
            </li>
          ))}
        </ul>
      </div>
      {/* Call-to-action button */}
      <div className="mt-auto">
        <button className={`daisy-btn rounded-full px-6 py-2 w-full daisy-btn-primary`} onClick={onClick}>
          {ctaText}
        </button>
      </div>
    </div>
  );
};
