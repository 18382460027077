import styled from "styled-components";
import { Helmet } from 'react-helmet';
import UserBehavior from "../components/UserBehavior";
import GlobalStyles from '../GlobalStyles'; // Import the global styles

const CenteredContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;

const Message = styled.div`
  font-size: 20px;
  text-align: center;
`;
const Button = styled.div`
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #284389;
  font-size: 16px;
  border-radius: 5px;
  color: white;
  cursor: pointer;
`;

const PgeOAuthStart = () => {
  const handleRedirect = () => {
    const clientId = "8fc92047bb9b446d9ed5de3de21e8600";
    const responseType = "code";
    const authUrl = new URL("https://sharemydata.pge.com/myAuthorization");
    authUrl.search = new URLSearchParams({
      client_id: clientId,
      redirect_uri: `https://ecotrove.com/pge-oauth-redirect`,
      response_type: responseType,
    }).toString();

    window.location.href = authUrl.href;
  };

  return (
    <>
    <GlobalStyles /> {/* Include global styles */}
    <Helmet>
    <UserBehavior />
   </Helmet> 

    <main>
    <CenteredContainer>
      <Message>To continue setup, click below</Message>
      <Button onClick={handleRedirect}>Go</Button>
    </CenteredContainer>
    </main>
  </>
  );
};

export default PgeOAuthStart;
