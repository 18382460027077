import { useEffect, useState } from "react";
import {
  RDCCheckAvailabilityHero,
  RDCNavbar,
  RDCAlertBanner,
  RDCImageCard,
  RDCFinishedScrollingForm,
  RDCFooter,
  RDCAutoScrollCarousel,
  RDCCustomerReviewCard,
} from "../components";
import EasySetupGraphic from "../images/graphics/EasySetupGraphic.png";
import NewPowerBillGraphic from "../images/graphics/NewPowerBillGraphic.png";
import TransparentPricingGraphic from "../images/graphics/TransparentPricingGraphic.png";
import IndustryProvidersGraphic from "../images/graphics/IndustryProvidersGraphic.png";
import PrivacyFirstGraphic from "../images/graphics/PrivacyFirstGraphic.png";
import OnOffGraphic from "../images/graphics/OnOffGraphic.png";
import CustomerReviewGraphic from "../images/graphics/CustomerReviewGraphic.png";
import { CustomerReview, CustomerReviewService } from "../services/CustomerReviewService";
import { ChevronDoubleDownIcon } from "@heroicons/react/24/solid";
import mixpanel from "mixpanel-browser";
import { Helmet } from "react-helmet";
import { MOBILE_SNAP_SPACER } from "../../constants";

const PAGE_ID = "landing-page";

const CEO_VIDEO_URL = "https://www.youtube.com/embed/c-rV5eZ1VLs";

const CUSTOMER_TESTIMONIAL_VIDEO_URLS = [
  "https://www.youtube.com/embed/aJ0VNUbOGik",
  "https://www.youtube.com/embed/_R2PnLi54Eo",
  "https://www.youtube.com/embed/YLiP0P_0MaI",
];

/**
 * Landing page that should be displayed to the user upon arrival.
 * @returns a React `FunctionComponent`.
 */
export const RDCLandingPage: React.FC = () => {
  const [errorAlert, setErrorAlert] = useState<string>("");
  const [reviews] = useState<CustomerReview[]>(CustomerReviewService.getReviews({}));

  useEffect(() => {
    mixpanel.track("landingPage");
  }, []);

  return (
    <div
      id={PAGE_ID}
      className="tw-preflight flex-col relative h-[calc(100vh-theme('spacing.navbar'))] snap-y snap-mandatory overflow-y-auto mt-navbar">
      <Helmet>
        <title>EcoTrove | Lower and Flat Power Bills</title>
        <meta
          name="description"
          content="EcoTrove is your energy concierge for cheaper, greener and more predictable power bills."
        />
        <meta
          name="keywords"
          content="fixed-price energy, energy concierge, clean energy subscription, lower energy bills, EcoTrove, green power, clean energy, flat rate energy bills, lower utility bills, flatten utility bills, renewable energy, pg&E, sce, sdg&E"
        />
        {/* Open Graph Meta Tags */}
        <meta property="og:title" content="EcoTrove - Lower and Flat Power Bills" />
        <meta
          property="og:description"
          content="EcoTrove is your energy concierge for cheaper, greener, and more predictable power bills."
        />
        <meta property="og:image" content="https://i.postimg.cc/5NsyKGLv/Screenshot-2024-12-02-at-8-34-16-AM.png" />
        <meta property="og:url" content="https://ecotrove.com/" />
        <meta property="og:type" content="website" />

        {/* Twitter Card Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="EcoTrove - Lower and Flat Power Bills" />
        <meta
          name="twitter:description"
          content="EcoTrove is your energy concierge for cheaper, greener, and more predictable power bills."
        />
        <meta name="twitter:image" content="https://i.postimg.cc/5NsyKGLv/Screenshot-2024-12-02-at-8-34-16-AM.png" />
      </Helmet>

      {/* Error banner that can be set by subcomponents */}
      {errorAlert ? (
        <RDCAlertBanner type="error" message={errorAlert} onClickClose={() => setErrorAlert("")} />
      ) : undefined}

      {/* Navbar */}
      <RDCNavbar contentId={PAGE_ID} />

      {/* View height: hero */}
      <div className="snap-start flex flex-col min-h-[calc(100%-72px)]">
        <span className="grow pt-12 lg:pt-20 px-content-mobile lg:px-content mb-default">
          <RDCCheckAvailabilityHero />
        </span>
      </div>

      {/* Customer reviews */}
      <div className="snap-start flex flex-col min-h-full bg-gradient-to-b from-secondary py-4">
        <div className="flex justify-center items-center gap-4 pb-4">
          <p id="customer-reviews" className="text-sm lg:text-base">
            See why our customers rate us "excellent" on Trustpilot
          </p>
          <button
            aria-label="scroll down"
            className="hidden lg:flex daisy-btn daisy-btn-ghost"
            onClick={() => document.getElementById("customer-reviews")?.scrollIntoView({ behavior: "smooth" })}>
            <ChevronDoubleDownIcon className="hero-icon hero-icon-sm" />
          </button>
        </div>
        <RDCAutoScrollCarousel
          carouselId="customer-review-carousel"
          items={reviews.map((review) => (
            <RDCCustomerReviewCard review={review} />
          ))}
          autoScrollMS={3000}
        />
      </div>

      {/* Save more stress less */}
      <div className="snap-start flex flex-col min-h-full p-content-mobile lg:p-content gap-content-mobile lg:gap-content">
        <h2 className="text-center">Save more, stress less.</h2>
        <div className="grid grid-col-1 lg:grid-cols-3 gap-20 lg:gap-8 justify-items-center">
          {[
            {
              imgUrl: EasySetupGraphic,
              imgAlt: "three easy steps to set up an account",
              headerText: "Easy 2 minute set-up",
              bodyText: "Connect your utility and EcoTrove accounts to allow us to make your power bills work for you.",
            },
            {
              imgUrl: NewPowerBillGraphic,
              imgAlt: "new rate will be cheaper than the old rate",
              headerText: "Lock in the cheapest rates",
              bodyText:
                "Replace your utility-issued bills with a flat-rate payment plan that is up to $500/yr cheaper.",
            },
            {
              imgUrl: TransparentPricingGraphic,
              imgAlt: "EcoTrove has a transparent pricing model",
              headerText: "Transparent pricing",
              bodyText: "EcoTrove guarantees you savings and only makes money for savings secured above and beyond.",
            },
          ].map((props, i) => (
            <div key={i} className="max-md:snap-start text-center">
              <RDCImageCard {...props} />
            </div>
          ))}
        </div>
        {MOBILE_SNAP_SPACER}
      </div>

      {/* CEO message */}
      <div className="snap-start flex flex-col items-center min-h-full p-content-mobile lg:p-content gap-content-mobile lg:gap-content text-center">
        <div className="flex flex-col items-center gap-4">
          <h2 className="leading-none">
            Message
            <br />
            from our CEO.
          </h2>
          <p className="text-neutral-content lg:w-1/2">
            We are building EcoTrove to be your energy concierge. We believe you deserve to use the power you need,
            without breaking the bank or the environment.
          </p>
        </div>
        <iframe className="rounded-3xl aspect-video w-full" src={CEO_VIDEO_URL} title="Video from CEO" />
      </div>

      {/* Why EcoTrove */}
      <div className="snap-start flex flex-col items-center bg-neutral-50 p-content-mobile lg:p-content gap-content-mobile lg:gap-content">
        <div className="text-center">
          <h2>Why EcoTrove?</h2>
          <p className="text-neutral-content mt-4">We are loved by our customers and trusted by the industry.</p>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-8 gap-y-24">
          {[
            {
              imgUrl: CustomerReviewGraphic,
              imgAlt: "customer review example",
              headerText: "Loved by customers",
              bodyText:
                "Every single EcoTrove customer is paying less for their power bills compared to what they would have paid their utility. Read their reviews to learn more.",
            },
            {
              imgUrl: PrivacyFirstGraphic,
              imgAlt: "data privacy provider logos",
              headerText: "Privacy first",
              bodyText:
                "EcoTrove has adopted robust data security and privacy measures to comply with US Department of Energy's DataGuard Energy Data Privacy Program.",
            },
            {
              imgUrl: IndustryProvidersGraphic,
              imgAlt: "utilitiy provider logos",
              headerText: "Trusted by the industry",
              bodyText:
                "EcoTrove is an approved third-party service provider for leading utilities including Pacific Gas & Electric, Southern California Edison, and San Diego Gas & Electric.",
            },
            {
              imgUrl: OnOffGraphic,
              imgAlt: "on/off switch",
              headerText: "Cancel anytime",
              bodyText:
                "Never pay a utility bill again and let EcoTrove optimize your utility bills. Cancel anytime if you're not 100% satisfied.",
            },
          ].map((props, i) => (
            <div key={i} className="max-md:snap-start text-center lg:text-start">
              <RDCImageCard key={props.headerText} {...props} />
            </div>
          ))}
          {MOBILE_SNAP_SPACER}
        </div>
      </div>

      {/* Customer testimonials */}
      <div className="snap-start p-content-mobile lg:p-content">
        <div className="flex flex-col gap-4 text-center">
          <h2>
            Enjoy affordable,
            <br />
            fixed prices every month.
          </h2>
          <p className="text-neutral-content">Just like they do.</p>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-3 lg:gap-8 justify-items-center">
          {CUSTOMER_TESTIMONIAL_VIDEO_URLS.map((url, i) => (
            <iframe
              key={i}
              className="snap-start grow rounded-3xl w-full aspect-[9/16] my-content-mobile lg:my-content"
              src={url}
              title="Customer testimonial"
            />
          ))}
          {MOBILE_SNAP_SPACER}
        </div>
      </div>

      {/* View height: finished scrolling & footer */}
      <div className="snap-start flex flex-col min-h-full">
        <RDCFinishedScrollingForm />
        <div className="grow" />
        <RDCFooter />
      </div>
    </div>
  );
};
