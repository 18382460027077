import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/solid";
import { ACTION_LINKS, LOGIN_PAGE_ROUTE, MAIN_MENU_LINKS, USER_DASHBOARD_ROUTE } from "../../constants";
import { MouseEventHandler, useEffect, useState } from "react";
import { Utils } from "../../utils";
import { RDCEcoTroveLogoLink } from "./RDCEcoTroveLogoLink";
import { supabase } from "../../supabaseClient";
import { Link } from "../../types";

const AUTHENTICATION_LINKS: Link[] = [
  { label: "Login", url: LOGIN_PAGE_ROUTE },
  { label: "My Account", url: USER_DASHBOARD_ROUTE },
];

/** Props to render a `RDCNavbar` with. */
export interface RDCNavbarProps {
  /**
   * The ID of the content that is rendered under the navbar. For pages that use snap scrolling,
   * this is needed to determine if the content has been scrolled and to style the navbar accordingly.
   */
  contentId?: string;
}

/**
 * Navigation bar component that should be rendered on every page.
 * @returns a React `FunctionComponent`.
 */
export const RDCNavbar: React.FC<RDCNavbarProps> = (props) => {
  const { contentId } = props;
  const [isSignedIn, setIsSignedIn] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [scrollY, setScrollY] = useState(0);

  // Check whether the user is logged in so that the correct auth button can be rendered
  useEffect(() => {
    const checkLoggedIn = async () => {
      const {
        data: { user },
      } = await supabase.auth.getUser();
      if (user) setIsSignedIn(true);
    };
    checkLoggedIn();
  }, []);

  // Keep track of the window's scrollY so that styling can be applied on scroll
  useEffect(() => {
    const scrollElement = contentId ? document.getElementById(contentId) ?? window : window;
    const handleScroll = () => setScrollY("scrollY" in scrollElement ? scrollElement.scrollY : scrollElement.scrollTop);
    scrollElement.addEventListener("scroll", handleScroll);
    return () => {
      scrollElement.removeEventListener("scroll", handleScroll);
    };
  }, [contentId]);

  /**
   * Handler for dropdown menu click events.
   * @param e the mouse event
   */
  const handleDropdownClick: MouseEventHandler = (e) => {
    e.preventDefault();
    setDropdownOpen((prev) => !prev);
  };

  /**
   * Helper method that gets the current authentication link that should be displayed.
   * @returns the `Link` to be displayed.
   */
  const getAuthLink = (): Link => {
    return isSignedIn ? AUTHENTICATION_LINKS[1] : AUTHENTICATION_LINKS[0];
  };

  /**
   * Helper method that creates an array of link elements based on the provided screen size.
   * @param props the props to get the menu items with
   * @returns an array of menu links.
   */
  const getLinks = (props: { isSmallScreen: boolean }): JSX.Element[] => {
    const width = props.isSmallScreen ? "w-full" : "";
    const links = Utils.getListElementsFromLinks(MAIN_MENU_LINKS, width);
    // Add a login link to smaller screens
    if (props.isSmallScreen) {
      links.push(Utils.getListElementsFromLinks([getAuthLink()], `${width} bg-secondary rounded-full`)[0]);
    }
    return links;
  };

  return (
    <header
      id="ecotrove-navbar"
      className={`fixed top-0 left-0 right-0 z-50 flex bg-base-100 ${
        scrollY ? "border-b border-neutral-200" : ""
      } px-content-mobile lg:px-content py-3`}>
      {/* Logo */}
      <span className="-ml-2">
        <RDCEcoTroveLogoLink />
      </span>
      {/* Center menu (if larger screen) */}
      <div className="grow flex justify-center">
        <ul className="daisy-menu daisy-menu-md daisy-menu-horizontal hidden lg:flex">
          {getLinks({ isSmallScreen: false })}
        </ul>
      </div>
      {/* Action menu */}
      <div className="flex-none flex">
        <a className="daisy-btn daisy-btn-primary text-md" href={ACTION_LINKS[0].url}>
          {"label" in ACTION_LINKS[0] ? ACTION_LINKS[0].label : ACTION_LINKS[0].icon}
        </a>
        <span className="hidden lg:flex ml-default">
          {Utils.getListElementsFromLinks([getAuthLink()], "daisy-btn daisy-btn-secondary")[0]}
        </span>
        {/* Dropdown menu (if smaller screen) */}
        <details className="daisy-dropdown daisy-dropdown-end" open={dropdownOpen}>
          <summary
            className="daisy-btn daisy-btn-ghost lg:hidden ml-default -mr-2"
            aria-label="open dropdown menu"
            onClick={handleDropdownClick}>
            {dropdownOpen ? <XMarkIcon className="hero-icon" /> : <Bars3Icon className="hero-icon" />}
          </summary>
          <ul className="daisy-dropdown-content daisy-menu items-start bg-base-100 rounded-box z-[1] mt-3 w-52 p-2 shadow">
            {getLinks({ isSmallScreen: true })}
          </ul>
        </details>
      </div>
    </header>
  );
};
