import EcoTroveLogo from "../images/logos/EcoTroveLogo.svg";

/**
 * Link component that links to the EcoTrove landing page.
 * @returns a React component.
 */
export const RDCEcoTroveLogoLink: React.FC = () => {
  return (
    <a
      className="flex-none flex daisy-btn daisy-btn-ghost gap-default text-lg items-center"
      href={"/"}>
      <img src={EcoTroveLogo} height={28} width={28} alt="EcoTrove Logo" />
      <p className="text-lg">EcoTrove</p>
    </a>
  );
};
