import styled from "styled-components";
import Navbar from "../components/AccountNavbar";
import FooterAccount from "../components/FooterAccount";
import { useNavigate } from 'react-router-dom';
import { supabase } from "../supabaseClient";
import React, { useEffect, useState } from 'react';
import {constructFullApiURL} from "../constructFullApiUrl";
import { Helmet } from 'react-helmet';
import GlobalStyles from '../GlobalStyles'; // Import the global styles
import background2 from '../images/background2.jpeg'; // Import the background image

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  font-family: 'Source Sans Pro', sans-serif;
  
  @media (max-width: 768px) {
    background: url(${background2}) no-repeat center center fixed;
    background-size: cover;
    & > div {
      background: rgba(255, 255, 255, 0.5);
    }
  }

  @media (min-width: 769px) {
    flex-direction: row;
  }
`;

const BoldSpan = styled.span`
  font-weight: bold;
`;

const ContentContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 2rem;
  padding-top: 5px;

  @media (min-width: 769px) {
    width: 70%;
  }
`;

const BackgroundImage = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  background: url(${background2}) no-repeat center center fixed;
  background-size: cover;

  @media (max-width: 768px) {
    display: none;
  }

  @media (min-width: 769px) {
    width: 30%;
  }
`;

const MobileContainer = styled.div`
  display: none;

  @media (max-width: 768px) {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 65vh;
    width: 100%;
    background-color: white;
  }
`;

const MobileIframeContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90%;
  height: 80%;
  background-color: white;

  iframe {
    width: 90%;
    height: 100%;
  }
`;

const DesktopIframeContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 80%;
  height: 39%;
  margin-top: -300px;

  @media (max-width: 768px) {
    display: none;
  }
`;

const Heading = styled.h1`
  text-align: center;
  color: #253D80;
  font-size: 2rem; 
  line-height: 1.2;
`;

const RoundedRectangle = styled.div`
  border: 2px solid #253D80;
  border-radius: 15px;
  padding: 1rem;
  margin: 1rem 0;
  width: 80%;
`;

const DetailsTitle = styled.h2`
  color: #253D80;
  font-size: 1.2rem;
  text-align: center;
`;

const BoldParagraph = styled.p`
  font-weight: bold;
`;

const InputContainer = styled.div`
  margin: 1rem 0;
`;

const InputBox = styled.input`
  border: 1px solid gray;
  border-radius: 8px;
  padding: 0.5rem;
  width: 75%;
`;

const Button = styled.button`
  font-size: 1rem;
  color: white;
  background-color: #80AA7B;
  border: none;
  border-radius: 15px;
  padding: 0.5rem 1rem;
  cursor: pointer;
  margin-top: 1rem;

  &:hover {
    background-color: #253D80;
  }
`;

// New styles for the popup functionality
const Overlay = styled.div<{ isVisible: boolean }>`
  display: ${({ isVisible }) => (isVisible ? 'block' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
`;

const Popup = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  z-index: 1000;
  text-align: center;
`;

const TalkToTeamButton = styled(Button)`
  background-color: #284389;
  width: 200px;
  border: 3px solid #111d57;

  &:hover {
    background-color: #111d57;
    color: white;
  }
`;

const CancelButton = styled(Button)`
  background-color: #e6f7ff;
  color: #111d57;
  width: 200px;
  border: 3px solid #111d57;

  &:hover {
    background-color: #111d57;
    color: white;
  }
`;

const generateEmbedToken = (userId: string) => {
  const jwt = require("jsonwebtoken");
  const METABASE_SECRET_KEY = process.env.REACT_APP_METABASE_SECRET_KEY as string;

  const payload = {
    resource: { dashboard: 105 },
    params: {
      "user_id": userId
    },
    exp: Math.round(Date.now() / 1000) + (10 * 60) // 10 minute expiration
  };

  const token = jwt.sign(payload, METABASE_SECRET_KEY);
  return token;
};

const ManageAccount = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [userProfile, setUserProfile] = useState<any>(null);
  const [iframeSrc, setIframeSrc] = useState<string>('');
  const [cancelledSubscription, setCancelledSubscription] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [originalName, setOriginalName] = useState<string>('');
  const [originalEmail, setOriginalEmail] = useState<string>('');
  const [isPopupVisible, setIsPopupVisible] = useState(false); // Add popup visibility state
  const METABASE_SITE_URL = "https://ecotrove.metabaseapp.com";

  // State variables to track changes
  const [updatedName, setUpdatedName] = useState<string>('');
  const [updatedEmail, setUpdatedEmail] = useState<string>('');
  const [updatedPassword, setUpdatedPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [userStartDate, setUserStartDate] = useState<string>('');
  const [monthlyQuote, setMonthlyQuote] = useState<string>('');
  const [freeMonitoring, setFreeMonitoring] = useState<boolean>(false);

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        setLoading(true);

        const { data: { user } } = await supabase.auth.getUser();
        
        if (user === null || user === undefined || !user) {
          alert("Please log in to view this screen!");
          navigate('/login-page');
        }

        const { data: userProfile, error: userProfileError } = await supabase
          .from('user-profiles')
          .select('*')
          .eq('user_id', user?.id)
          .single();

        if (userProfileError) {
          throw userProfileError;
        }
        setUserProfile(userProfile);
        if (userProfile) {
          setOriginalName(userProfile.name);
          setOriginalEmail(userProfile.email);

          if (userProfile?.start_date !== null) {
            setUserStartDate(formatDate(userProfile?.start_date))
          }
          if (userProfile?.monthly_subscription !== null) {
            setMonthlyQuote(userProfile?.monthly_subscription)
          }
          if (userProfile?.free_monitoring !== null) {
            setFreeMonitoring(userProfile?.free_monitoring)
          }  

          // Set iframe src
          const userId = userProfile.user_id;
          const metabaseToken = generateEmbedToken(userId);
          const iframeUrl = METABASE_SITE_URL + "/embed/dashboard/" + metabaseToken +
            "#bordered=true&titled=true";;
          setIframeSrc(iframeUrl);
        }
      } catch (error) {
        console.error('Error fetching user profile:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchUserProfile();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //IN ORDER TO PULL ANY INFORMATION FROM USER PROFILE, do userProfile?.column_name like userProfile?.monthly_quote or userProfile?.email

  //show loading screen until user data is pulled from supabase-this should be instant
  if (loading) {
    return (
      <div>Loading...</div>
    );
  }

  const handleLogout = async () => {
    try {
      await supabase.auth.signOut();
      localStorage.clear();
      navigate('/');
      window.scrollTo(0, 0); 
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  const handleCancelSubscription = async () => {
    try {
      const { data: cancellationData, error: cancellationError } = await supabase
      .from('cancellation-requests')
      .insert({user_id: userProfile?.user_id, email: userProfile?.email});

      setCancelledSubscription(true); //I set a boolean here but we don't do anything with it right now
      console.log(cancelledSubscription);
      console.log(cancellationData);
      if (cancellationError) {
        throw cancellationError;
      }
    } catch (error) {
      alert("Error canceling subscription, please email info@ecotrove.com");
    }
  };

  // Function to handle popup visibility
  const handleOpenPopup = () => {
    setIsPopupVisible(true);
  };

  const handleClosePopup = () => {
    setIsPopupVisible(false);
  };

  const handleOverlayClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      handleClosePopup(); // Close popup only if the user clicks directly on the overlay
    }
  };

  const handleCancelSubscriptionConfirmed = async () => {
    handleClosePopup(); // Close the popup when the user confirms cancellation
    handleCancelSubscription(); // Call the original cancel subscription functionality
    redirectToStripeAccount(userProfile?.user_id)
  };

  // Function to handle changes in input fields
  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUpdatedName(event.target.value);
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUpdatedEmail(event.target.value);
  };

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUpdatedPassword(event.target.value);
  };

  const handleConfirmPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setConfirmPassword(event.target.value);
  };

  const validateEmail = async (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;
    if (!emailRegex.test(email)) {
      return false;
    }

    const domain = email.split('@')[1];
    try {
      const lookup = await fetch(`https://dns.google/resolve?name=${domain}&type=MX`);
      const result = await lookup.json();
      return result.Answer !== undefined;
    } catch (e) {
      console.error("DNS lookup failed:", e);
      return false;
    }
  };

  // Function to save profile and detect changes
  const handleSaveProfile = async () => {
    try {
      setErrorMessage("");
      // Check which fields were updated
      const updatedFields = [];
      if (updatedName !== originalName && updatedName !== "" && updatedName !== undefined && updatedName !== null) {
        updatedFields.push('Name');
        const { data, error } = await supabase
          .from('user-profiles')
          .update({name: updatedName})
          .eq('user_id', userProfile?.user_id);
        console.log(data);
        if (error) {
          setErrorMessage("Error updating name; Please try again later");
          return;
        }
      }
      if (updatedEmail !== originalEmail && updatedEmail !== "" && updatedEmail !== undefined && updatedEmail !== null) {
        console.log(validateEmail(updatedEmail));
        if (!(await validateEmail(updatedEmail))) {
          setErrorMessage("Please enter a valid email");
          return; // Exit function early
        }
        updatedFields.push('Email');
        const { data: updateEmailData, error: updateEmailError } = await supabase.auth.updateUser({
          email: updatedEmail
        })

        if (updateEmailError) {
          console.log(updateEmailError)
          setErrorMessage("Error updating your email. Please try again later.");
          return; // Exit function early
        }
        console.log(updateEmailData)
  
        const { data, error } = await supabase
          .from('user-profiles')
          .update({ email: updatedEmail })
          .eq('user_id', userProfile?.user_id);

        const { data: serviceData, error: serviceError } = await supabase
          .from('service-accounts')
          .update({ email: updatedEmail })
          .eq('user_id', userProfile?.user_id);

        const { data: termsData, error: termsError } = await supabase
          .from('terms-and-conditions')
          .update({ email: updatedEmail })
          .eq('user_id', userProfile?.user_id);

        alert('Please check your inbox to confirm your new email');
        console.log(data);
        console.log(serviceData);
        console.log(termsData);
        if (error) {
          console.error("error updating user profiles with new email: ", error)
        }
        if (serviceError){
          console.error("error updating service accounts with new email: ", serviceError)
        }
        if (termsError) {
          console.error("error updating terms table with new email: ", termsError)
        }
      }
      if (updatedPassword !== '' && updatedPassword !== null && updatedPassword !== undefined) {
        updatedFields.push('Password');
        // Check if password and confirm password match
        if (updatedPassword !== confirmPassword) {
          setErrorMessage("Passwords do not match. Please try again.");
          return;
        }
        // Validate the password
        const passwordRegex = /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d!@#$%^&*()_+]{8,}$/;
        if (!passwordRegex.test(updatedPassword)) {
          setErrorMessage("Password must be at least 8 characters long and contain at least one uppercase letter, one digit, and one symbol.");
          return;
        }
        const { data: updatePasswordData, error: updatePasswordError } = await supabase.auth.updateUser({
          password: updatedPassword
        });
        console.log(updatePasswordData);
        if (updatePasswordError) {
          setErrorMessage("Error updating password; Please try again later");
          return;
        }
      }

      // Display message about updated fields
      if (updatedFields.length > 0) {
        alert(`Updated the following account information: ${updatedFields.join(', ')}`);
      } else {
        alert('No changes detected.');
      }

    } catch (error) {
      console.error('Error saving profile:', error);
      setErrorMessage("Error saving profile");
    }
  };

  const redirectToStripeAccount = async (userID: string) => {
    const apiURL = constructFullApiURL(`/stripe-customer-url`);
    try {
      const response = await fetch(apiURL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ userID: userID }),
      });
  
      const data = await response.json();
      if (data.url) {
        window.location.href = data.url; // Redirect to Stripe's Customer Portal
      } else {
        console.error("Error fetching customer portal session URL");
      }
    } catch (error) {
      console.error("Error opening customer portal:", error);
    }

  }

  // const redirectToPGE = (email: string, savingsType: string) => {
  //   const clientId = "8fc92047bb9b446d9ed5de3de21e8600";
  //   const responseType = "code";
  //   const authUrl = new URL("https://sharemydata.pge.com/myAuthorization");
  //   let testing = "0";
  //   if (process.env.REACT_APP_WEBSITE_URL !== "https://ecotrove.com") {
  //     testing = "1";
  //   }
  //   let skipQuote = "0";
  //   if (savingsType === "business") {
  //     skipQuote = "1";
  //   }
  //   else if (savingsType === "reauth") {
  //     skipQuote = "2";
  //   }
  //   const state = testing + skipQuote + email;
  //   authUrl.search = new URLSearchParams({
  //     client_id: clientId,
  //     redirect_uri: "https://ecotrove.com/pge-oauth-redirect",
  //     response_type: responseType,
  //     state: state,
  //   }).toString();
  //   window.location.href = authUrl.href;
  // };

  // Function to handle button clicks that may trigger a popup or external link
  const handleButtonClick = (url: string) => {
    const newWindow = window.open(url, '_blank');
    if (newWindow) {
      newWindow.opener = null; // Avoid setting opener to prevent security risks
      newWindow.focus(); // Bring the new window to the foreground
    } else {
      // Handle case where the new window couldn't be opened (probably due to popup blocker)
      alert('Failed to open pop up. Please check your browser settings.');
    }
  };

  const formatDate = (dateString: string) => {
    const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  return (
    <>
      <GlobalStyles /> {/* Include global styles */}
      <Helmet>
        <title>EcoTrove | Manage Your Account</title>
        <meta name="description" content="Manage your EcoTrove fixed-price clean energy subscription up to 10% cheaper. See how much you are saving in money and carbon emissions." />
        <meta name="keywords" content="manage account, login, fixed-price energy, clean energy subscription, lower energy bills, EcoTrove, green power" />
      </Helmet>
      <Navbar />
      <PageContainer>
        <ContentContainer>
          <Heading>Manage Your Account</Heading>
          <RoundedRectangle>
            <DetailsTitle>Change Personal Details</DetailsTitle>
            <InputContainer>
              <BoldParagraph>Name</BoldParagraph>
              <InputBox 
                placeholder={originalName}
                value={updatedName}
                onChange={handleNameChange}
              />
            </InputContainer>
            <InputContainer>
              <BoldParagraph>Email Address</BoldParagraph>
              <InputBox 
                placeholder={originalEmail}
                value={updatedEmail}
                onChange={handleEmailChange}
              />
            </InputContainer>
            <InputContainer>
              <BoldParagraph>Password</BoldParagraph>
              <InputBox 
                type="password"
                placeholder="Click to change Password"
                value={updatedPassword}
                onChange={handlePasswordChange}
              />
            </InputContainer>
            <InputContainer>
              <BoldParagraph>Confirm Password</BoldParagraph>
              <InputBox 
                type="password"
                placeholder="Confirm Password"
                value={confirmPassword}
                onChange={handleConfirmPasswordChange}
              />
              {errorMessage && <div className="error-message">{errorMessage}</div>}
            </InputContainer>
            <Button onClick={handleSaveProfile}>Save Profile</Button>
          </RoundedRectangle>
          <RoundedRectangle>
            <DetailsTitle>Support</DetailsTitle>
            <BoldParagraph>Contact EcoTrove Support with any questions or anticipated changes.</BoldParagraph>
            <p>Notify EcoTrove of any upcoming changes to your energy demand, such as purchasing an electric vehicle, adding a new person to your household, or buying new energy-intensive appliances.</p>
            <p>Contact EcoTrove Support with any questions about your subscription.</p>
            <Button onClick={() => handleButtonClick("mailto:info@ecotrove.com")}>Contact Support</Button>
          </RoundedRectangle>

          <RoundedRectangle>
          <DetailsTitle>Change Payment Information</DetailsTitle>
          {freeMonitoring ? (
            <p><BoldSpan>No payment information on file. </BoldSpan></p>
          ) : (
            <>
              <BoldParagraph>Update payment information with Stripe.</BoldParagraph>
              <p>Update your payment information with EcoTrove through Stripe, our payment platform.</p>
              <Button onClick={() => redirectToStripeAccount(userProfile?.user_id)}>Redirect to Stripe</Button>
            </>
          )}
        </RoundedRectangle>
          <RoundedRectangle>
            <DetailsTitle>Cancel Subscription</DetailsTitle>
            {freeMonitoring ? (
              <>
                 <BoldParagraph>Contact EcoTrove Support to cancel your Free Monitoring Subscription.</BoldParagraph>
               <Button onClick={() => handleButtonClick("mailto:info@ecotrove.com")}>Contact Support</Button>
               </>
          ) : (
            <>
            <BoldParagraph>Subscription Price: ${monthlyQuote}/month </BoldParagraph>
            <BoldParagraph>Subscription Start Date: {userStartDate} </BoldParagraph>
            <Button onClick={handleOpenPopup}>Cancel Subscription</Button>
            </>
          )}
          </RoundedRectangle>

          {/* Cancel Subscription Popup */}
          <Overlay isVisible={isPopupVisible} onClick={handleOverlayClick}/>
          {isPopupVisible && (
            <Popup>
              <p style={{ fontSize: '130%', fontWeight: 'bold' }}>Are you sure about cancelling?</p>
              <p style={{ fontSize: '110%' }}>
                Connect with a member of our team on how we can improve your experience and get a $50 bill discount or Visa gift card as a thank you.
                <br /><br />
              </p>
              <TalkToTeamButton onClick={() => { window.open('https://calendly.com/ali-sarilgan/ecotrove-call', '_blank'); handleClosePopup(); }}>
                Talk to EcoTrove
              </TalkToTeamButton>
              <CancelButton onClick={handleCancelSubscriptionConfirmed}>
                Continue Cancelling
              </CancelButton>
            </Popup>
          )}

          {/* <RoundedRectangle>
            <DetailsTitle>Re-authorize with PG&E</DetailsTitle>
            <BoldParagraph>Redirect to PG&E to authorize your account with us.</BoldParagraph>
            <p>EcoTrove requires indefinite authorization to your PG&E account in order to pay your bills. Click the button below to authorize.</p>
            <p>If you haven't received a notice to re-authorize, then no action is necessary.</p>
            <Button onClick={() => redirectToPGE(userProfile?.email, "reauth")} >Redirect to PG&E</Button>
          </RoundedRectangle> */}
          <Button onClick={handleLogout}>Logout</Button>
        </ContentContainer>
        <BackgroundImage>
          <DesktopIframeContainer>
            <iframe
              src={iframeSrc}
              frameBorder="0"
              width="100%"
              height="100%"
              allowTransparency
              title="Dashboard"
            ></iframe>
          </DesktopIframeContainer>
        </BackgroundImage>
        <MobileContainer>
          <MobileIframeContainer>
            <iframe
              src={iframeSrc}
              frameBorder="0"
              width="90%"
              height="100%"
              allowTransparency
              title="Dashboard"
            ></iframe>
          </MobileIframeContainer>
        </MobileContainer>
      </PageContainer>
      <FooterAccount />
    </>
  );
};

export default ManageAccount;
