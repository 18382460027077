import { useState, useEffect } from "react";

interface Step {
  id: number;
  label: string;
  completed: boolean;
}

interface RotatingChecksProps {
  steps: Step[];
}

export const RDCRotatingChecks: React.FC<RotatingChecksProps> = ({ steps }) => {
  const [localSteps, setLocalSteps] = useState(steps);

  useEffect(() => {
    const totalDuration = 1 * 60 * 1000; // 1 minute in milliseconds
    const rotateInterval = totalDuration / steps.length; // Interval for each step
    let currentIndex = 0; // Tracks the current step index

    const interval = setInterval(() => {
      currentIndex += 1;

      if (currentIndex >= steps.length) {
        clearInterval(interval); // Stop updates when all steps are completed
        return;
      }

      setLocalSteps((prevSteps) =>
        prevSteps.map((step) =>
          step.id <= steps[currentIndex].id
            ? { ...step, completed: true }
            : step
        )
      );
    }, rotateInterval);

    return () => clearInterval(interval); // Cleanup interval on unmount
  }, [steps]);

  return (
    <div className="flex justify-center">
      <ul className="space-y-4 text-left">
        {localSteps.map((step) => (
          <li
            key={step.id}
            className={`${
              step.completed ? "text-primary font-bold" : "text-gray-600"
            } flex items-center`}
          >
            <span className="mr-2">{step.completed ? "✔️" : "—"}</span>
            <span>{step.label}</span>
          </li>
        ))}
      </ul>
    </div>
  );
};