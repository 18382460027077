import { useState } from 'react';
import { supabase } from "../supabaseClient"; 
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import background1 from '../images/background1.jpeg';

interface PasswordUpdateProps {}

const UpdatePassword: React.FC<PasswordUpdateProps> = () => {
  const [password, setPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setConfirmPassword(e.target.value);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      setErrorMessage("Passwords do not match. Please try again.");
      return;
    }

    const passwordRegex = /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d!@#$%^&*()_+]{8,}$/;
    if (!passwordRegex.test(password)) {
      setErrorMessage("Password must be at least 8 characters long and contain at least one uppercase letter, one digit, and one symbol.");
      return;
    }

    setIsLoading(true);
    try {
      const { data, error } = await supabase.auth.updateUser({
        password: password
      });
      if (data) {
        navigate("/login-page");
      } else if (error) {
        throw error;
      }
    } catch (error) {
      alert("There was an error updating your password.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
      <PageContainer>
        <ContentContainer>
          <h1>Update Password</h1>
          <RoundedRectangle>
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label htmlFor="password"><strong>New Password:</strong></label>
                <input
                  type="password"
                  id="password"
                  value={password}
                  onChange={handlePasswordChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="confirmPassword"><strong>Confirm Password:</strong></label>
                <input
                  type="password"
                  id="confirmPassword"
                  value={confirmPassword}
                  onChange={handleConfirmPasswordChange}
                />
              </div>
              {errorMessage && <div className="error-message">{errorMessage}</div>}
              <button
                type="submit"
                disabled={isLoading}
              >
                {isLoading ? 'Updating...' : 'Update Password'}
              </button>
            </form>
          </RoundedRectangle>
        </ContentContainer>
      </PageContainer>
  );
};

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  font-family: 'Source Sans Pro', sans-serif;
  
  @media (max-width: 768px) {
    background: url(${background1}) no-repeat center fixed;
    background-size: cover;
    & > div {
      background: rgba(255, 255, 255, 0.5);
    }
  }

  @media (min-width: 769px) {
    flex-direction: row;
    &::after {
      content: '';
      background: url(${background1}) no-repeat center center fixed;
      background-size: cover;
      flex: 1;
    }
  }
`;

const ContentContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 2rem;
  padding-top: 70px;

  @media (min-width: 769px) {
    width: 50vw;
  }

  h1 {
    color: #253D80;
    text-align: center;
    font-size: 200%;
  }
`;

const RoundedRectangle = styled.div`
  border: 2px solid #253D80;
  border-radius: 15px;
  padding: 1rem;
  margin: 1rem 0;
  width: 90%;

  .form-group {
    margin-bottom: 0.5rem; /* Reduced space */
  }

  input {
    margin-left: 10px;
    font-size: 110%;
  }

  .error-message {
    color: red;
    margin-top: 1rem;
  }

  button {
    border-radius: 10px;
    background-color: #80AA7B;
    color: white;
    padding: 5px 10px;
    border: none;
    cursor: pointer;
    margin-top: 0.5rem; /* Reduced space */
    font-size: 110%;

    &:hover {
      background-color: #253D80;
    }
  }
`;

export default UpdatePassword;
