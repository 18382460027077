import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import mixpanel from "mixpanel-browser";
import GlobalStyles from '../GlobalStyles'; // Import the global styles

const Container = styled.section`
  display: flex;
  align-items: center;
  width: 57%;
  min-height: 50vh;
  margin-bottom: 4vh;

  @media (max-width: 710px) {
    width: 85%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 40px;
  }
`;

const HeadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 50%;

  @media (max-width: 710px) {
    width: 100%;
  }
`;

const Heading = styled.h2`
  color: #111d57;
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: left;

  @media (max-width: 710px) {
    font-size: 26px;
    text-align: center;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
`;

const QuoteButton = styled.button`
  border: none;
  border-radius: 10px;
  background-color: #284389;
  color: white;
  font-size: 18px;
  cursor: pointer;
  font-family: "Source Sans Pro";
  font-weight: bold;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  height: 70px;
  width: 280px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #111d57;
  }
`;

const BottomLandingSectionGetQuote: React.FC = () => {
  const navigate = useNavigate();

  const handleGetQuoteClick = () => {
    mixpanel.track("Get quote clicked from bottom landing section");
    navigate("/get-quote", { replace: true });
  
    // Delay scrolling to the top
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    }, 500); // 500 milliseconds delay
  };

  return (
    <Container>
      <GlobalStyles /> {/* Include global styles */}
      <HeadingContainer>
        <Heading>Get started in less than 2 minutes and live with energy peace of mind</Heading>
      </HeadingContainer>
      <ButtonContainer>
        <QuoteButton onClick={handleGetQuoteClick}>Check savings</QuoteButton>
      </ButtonContainer>
    </Container>
  );
};

export default BottomLandingSectionGetQuote;
