import { PlasmicRootProvider, PlasmicComponent } from '@plasmicapp/loader-react';
import { PLASMIC } from '../plasmic-init';

const FooterAccount = () => {
  return (
    <PlasmicRootProvider loader={PLASMIC}>
      <PlasmicComponent component="plasmic_footer_accounts" />
    </PlasmicRootProvider>
  );
};

export default FooterAccount;  