import { useEffect, PropsWithChildren } from "react";
import { useLocation } from "react-router-dom";

/**
 * Provider component that scrolls child pages to 0, 0 when the page location changes.
 * @param props the props to render the component with
 * @returns a React component.
 */
export const RDCScrollToTopProvider: React.FC<PropsWithChildren> = (props) => {
  const { children } = props;
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return <>{children}</>;
};
