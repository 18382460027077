// src/GlobalStyles.ts
import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  @font-face {
    font-family: 'Source Sans Pro';
    src: url('/path/to/SourceSansPro-Regular.woff2') format('woff2');
    font-display: swap;
  }

  body {
    font-family: 'Source Sans Pro';
    font-weight: normal;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
`;

export default GlobalStyles;
