/**
 * Interface that represents a customer review.
 */
export interface CustomerReview {
  link: string;
  name: string;
  location: string;
  savings?: string; // Add the savings field
  imgUrl?: string;
  numStars: number;
  dateEpochMS: number;
  textContent: JSX.Element;
}

/**
 * Class that vends calls for customer reviews.
 */
export class CustomerReviewService {
  /**
   * Gets an array of customer reviews.
   * @param options options to specify when retrieving reviews
   * @returns an array of customer reviews.
   */
  static getReviews = (options: { maxReviews?: number }): CustomerReview[] => {
    const { maxReviews } = options;

    // TODO: pull these from a CMS instead of statically populating
    // These were pulled directly from TrustPilot
    return [
      {
        link: "https://www.trustpilot.com/reviews/66d10400a19f2a275d774e29",
        name: "Owen",
        location: "San Francisco",
        savings: "(saving $150+)", // Default value
        numStars: 5,
        dateEpochMS: 1724958000000,
        textContent: (
          <p>
            I use Ecotrove for my business. I like not struggling with budgeting my power bills throughout the year and
            know that I will pay the exact same amount for my power bills every month.
          </p>
        ),
      },
      {
        link: "https://www.trustpilot.com/reviews/666173c0e6f0c9366daa12a0",
        name: "Torsten",
        location: "San Jose",
        savings: "(saving $400+)", // Default value
        numStars: 5,
        dateEpochMS: 1717700400000,
        textContent: (
          <p>
            Ecotrove made my energy bill experience much easier. I hated how much my PG&E bills were changing each month
            with arbitrary price increases that came out of nowhere. But with ecotrove, I now pay the exact same price
            every month, for a price that doesn't change with my energy usage. They're also significantly cheaper. Not
            really sure how they can make it work but they apparently find savings on my behalf, which is pretty nice.
          </p>
        ),
      },
      {
        link: "https://www.trustpilot.com/reviews/668f8b7ac54f8b376f713f9c",
        name: "Paul",
        location: "Fresno",
        savings: "(saving $250+)", // Default value
        numStars: 5,
        dateEpochMS: 1720724400000,
        textContent: (
          <p>
            Since using ecotrove they switched me over to a fully electric home rate plan and started getting my power
            from a community solar farm in my area. The quote they gave me was about 12% cheaper than what my pg&e bills
            would have been. I'll sing their praises to anyone who will listen.
          </p>
        ),
      },
      {
        link: "https://www.trustpilot.com/reviews/6668a4e36aa84c693d45ebc7",
        name: "Hemangini Raina",
        location: "San Francisco",
        savings: "(saving $200+)", // Default value
        numStars: 5,
        dateEpochMS: 1717268400000,
        textContent: (
          <p>
            I've been using Ecotrove for the past couple of months and I've had a great experience so far! I liked that
            I got a quote easily, could sign up for the service within minutes and am now paying a consistent amount of
            money every month for my energy bill!
          </p>
        ),
      },
      {
        link: "https://www.trustpilot.com/reviews/6667744b35600a6552a5e182",
        name: "Mehran Dinakar",
        location: "East Palo Alto",
        savings: "(saving $200+)", // Default value
        numStars: 4,
        dateEpochMS: 1718046000000,
        textContent: (
          <p>
            I started using EcoTrove last month. They already switched me to clean energy and started managing my
            utility bills for me.
            <br />
            <br />I like their service but my pg&e bills were already low enough ($60ish averages) so the $5 per month
            saving they found for me didn't change my life much
          </p>
        ),
      },
      {
        link: "https://www.trustpilot.com/reviews/66684ebc49ba349b879a08c9",
        name: "Nathan Fotedar",
        location: "Saratoga",
        savings: "(saving $350+)", // Default value
        numStars: 5,
        dateEpochMS: 1718132400000,
        textContent: (
          <p>
            I loved how easy it was to sign up and switch to clean energy - and the savings are fantastic. I've really
            appreciated the predictability of my energy bill and the money I save vs PG&E.
          </p>
        ),
      },
      {
        link: "https://www.trustpilot.com/reviews/6667ec588c13625408c1fe48",
        name: "Carly Myelez",
        location: "Mill Valley",
        savings: "(saving $200+)", // Default value
        numStars: 5,
        dateEpochMS: 1718132400000,
        textContent: (
          <p>
            Getting a quote and signing up was so easy. They replaced my utility bills with a fixed price plan that
            looks to be 8% cheaper than my utility bill average cost.
          </p>
        ),
      },
      {
        link: "https://www.trustpilot.com/users/6667e8cee9c0bfd18664541f",
        name: "Christina May",
        location: "Santa Cruz",
        savings: "(saving $450+)", // Default value
        numStars: 5,
        dateEpochMS: 1718132400000,
        textContent: (
          <p>
            I've been using ecotrove.com for the last 2 months and have been very happy with their service. I stopped
            paying or worrying about my utility bills, and just pay a fixed price to ecotrove to use all the electrcitiy
            and gas i need.
          </p>
        ),
      },
      {
        link: "https://www.trustpilot.com/reviews/666052cd3d5f717a6c1c77a7",
        name: "Hand",
        location: "Mill Valley",
        savings: "(saving $300+)", // Default value
        numStars: 5,
        dateEpochMS: 1717614000000,
        textContent: (
          <p>
            I just moved to California and Ecotrove made setting up my energy so easy. I had no clue how pg&e and the
            utility there works but they got me set up without any issues
          </p>
        ),
      },
      {
        link: "https://www.trustpilot.com/reviews/6667d665fe9287523b35b974",
        name: "Eren Uman",
        location: "Mountain View",
        savings: "(saving $250+)", // Default value
        numStars: 5,
        dateEpochMS: 1718046000000,
        textContent: (
          <p>
            I live and work in Silicon Valley. EcoTrove took uncertainty out of my life - at least when it comes to
            paying electric bills :)
          </p>
        ),
      },
      {
        link: "https://www.trustpilot.com/reviews/668e912c1245316b9a7d4adf",
        name: "Matthew",
        location: "Burlingame",
        savings: "(saving $50+)", // Default value
        numStars: 5,
        dateEpochMS: 1720638000000,
        textContent: (
          <p>
            Loved ecotrove's service. It got really hot here and I could use my AC without worrying about my bills or
            using dirty power to run the AC
          </p>
        ),
      },
      {
        link: "https://www.trustpilot.com/reviews/6668f919e8f3e8099f28cf71",
        name: "Gerardo Rendon",
        location: "San Jose",
        savings: "(saving $50+)", // Default value
        numStars: 5,
        dateEpochMS: 1718132400000,
        textContent: (
          <p>
            The experience has been really smooth. It was very easy to switch over and go clean, and paying the same
            price each month for electricity gives me peace of mind. Their staff is also super friendly.
          </p>
        ),
      },
      {
        link: "https://www.trustpilot.com/users/666747e7abfee62f3699a14c",
        name: "Ben B",
        location: "Santa Barbara",
        savings: "(saving $100+)", // Default value
        numStars: 5,
        dateEpochMS: 1718046000000,
        textContent: (
          <p>
            The set up process was remarkably straightforward. Now I'm fully using green energy and somehow saving money
            in the process!
          </p>
        ),
      },
      {
        link: "https://www.trustpilot.com/users/66604b9c6e76c79df0b6f74c",
        name: "Dan Lewitt",
        location: "Berkeley",
        savings: "(saving $250+)", // Default value
        numStars: 5,
        dateEpochMS: 1717614000000,
        textContent: (
          <p>
            It's kind of like the new versions of phone plans. I pay a fixed price for electricity every month instead
            of paying bills that wildly swing.
          </p>
        ),
      },
      {
        link: "https://www.trustpilot.com/users/668e893d831a1cc79b197f75",
        name: "Tia Balabanis",
        location: "Oakland",
        savings: "(saving $100+)", // Default value
        numStars: 4,
        dateEpochMS: 1720638000000,
        textContent: (
          <p>
            Very easy sign up process and they made it clear how much I could be saving. Excited to start trying it out.
          </p>
        ),
      },
      {
        link: "https://www.trustpilot.com/users/6660282256bff40d00bd0f9c",
        name: "Hannah",
        location: "San Mateo",
        savings: "(saving $250+)", // Default value
        numStars: 5,
        dateEpochMS: 1717614000000,
        textContent: <p>I saved more than $250 with EcoTrove so far. very happy with the service they provide</p>,
      },
    ]
      .sort((a, b) => b.dateEpochMS - a.dateEpochMS)
      .slice(0, maxReviews);
  };
}
