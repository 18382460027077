import {
  RDCNavbar,
  RDCFooter,
  RDCAlertBanner,
  RDCEnergyConciergeHero,
  RDCOurTeamCarousel,
  RDCBackedBy,
  RDCFinishedScrollingForm,
} from "../components";
import { UserGroupIcon, ShieldCheckIcon, GlobeAltIcon } from "@heroicons/react/24/outline";
import { useState } from "react";
import { TEAM_MEMBERS } from "../../constants";
import { Helmet } from "react-helmet";

const PAGE_ID = "about-us";

/**
 * About Us Page Component
 */
export const RDCAboutUs: React.FC = () => {
  const [errorAlert, setErrorAlert] = useState<string>("");

  return (
    <div
      id={PAGE_ID}
      className="tw-preflight flex-col relative h-[calc(100vh-theme('spacing.navbar'))] snap-y snap-mandatory overflow-y-auto mt-navbar">
      <Helmet>
        <title>EcoTrove | About Us</title>
        <meta
          name="description"
          content="EcoTrove is your energy concierge. We believe you deserve to use the power you need, without breaking the bank or the environment."
        />
        <meta
          name="keywords"
          content="affordable clean energy, accessible clean energy, Ali Sarilgan, Ozge Islegen-Wojdyla, clean energy subscription, lower energy bills, EcoTrove, green power, ecotrove team, Jess Kerlin, Tegan Fleishman, Christine Manegan"
        />
      </Helmet>
      {/* Navbar */}
      <RDCNavbar contentId={PAGE_ID} />

      {/* Error Banner */}
      {errorAlert ? <RDCAlertBanner type="error" message={errorAlert} onClickClose={() => setErrorAlert("")} /> : null}

      {/* Hero Section */}
      <div className="snap-start bg-white lg:pt-8 pb-16">
        <div className="px-content-mobile lg:px-content">
          <RDCEnergyConciergeHero />
        </div>
      </div>

      {/* "Our Values" Section */}
      <div className="snap-start bg-gradient-to-b from-secondary to-white py-16">
        <div className="px-content-mobile lg:px-content">
          <div className="text-left">
            <h2 className="text-black text-3xl lg:text-4xl text-left">Our Values</h2>
            <div className="max-w-5xl">
              <p className="text-neutral-content text-base md:text-md mt-4">
                Whether you hear “Eco” and think of the economy or the environment, we at EcoTrove believe your power
                bills should be lower. You deserve to warm or cool your home or business with the power you want,
                without breaking the bank or the environment.
              </p>
            </div>
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-8 mt-12 text-center lg:text-left ">
            {[
              {
                icon: UserGroupIcon,
                title: "People First",
                description:
                  "We root every decision in the experiences of those that use our services, and prioritize helping them navigate an increasingly complex energy ecosystem over all other business considerations.",
              },
              {
                icon: ShieldCheckIcon,
                title: "Integrity",
                description:
                  "We are committed to being reliable stewards of the trust placed in us by our customers and stakeholders. We earn this trust by respecting your data privacy and honoring our promise to lower your utility bills.",
              },
              {
                icon: GlobeAltIcon,
                title: "Impact Focused",
                description:
                  "We prioritize bold and impactful ideas that can limit global carbon emissions and allow people to use the power they need.",
              },
            ].map(({ icon: Icon, title, description }) => (
              <div key={title} className="snap-start flex flex-col items-center lg:items-start">
                <Icon className="w-12 h-12 text-black" />
                <h3 className="text-black text-lg mt-4">{title}</h3>
                <p className="text-neutral-content text-sm mt-2 max-w-md">{description}</p>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Our Team Section */}
      <div className="snap-start text-center mt-16 px-content-mobile lg:px-content pt-content-mobile lg:pt-content">
        <h2 className="text-black text-3xl lg:text-4xl">Our Team</h2>
      </div>
      <RDCOurTeamCarousel teamMembers={TEAM_MEMBERS} />

      {/* Hiring section */}
      <div className="snap-start flex flex-col items-center bg-neutral py-16 gap-y-4">
        <h2 className="text-3xl">We're hiring!</h2>
        <p className="text-neutral-content text-center">
          Browse open positions and reach out to
          <br />
          careers@ecotrove.com with questions.
        </p>
        <a
          className="daisy-btn daisy-btn-neutral border border-neutral-200 px-button"
          href="https://jobs.ashbyhq.com/pear/a64e2e65-fe58-4081-bec9-9ea4de290bca"
          target="_blank"
          rel="noopener noreferrer">
          Open positions
        </a>
      </div>

      <div className="snap-start text-center p-content-mobile lg:p-content mb-8">
        <RDCBackedBy />
      </div>

      {/* View height: finished scrolling & footer */}
      <div className="snap-start flex flex-col min-h-full w-full">
        <RDCFinishedScrollingForm />
        <div className="grow" />
        <RDCFooter />
      </div>
    </div>
  );
};
